import { RANDOM_BOOL, RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import {
  MAF_QUANTITY_CHARACTERISTICS_NAME,
  MaQuantityCharacteristicsField,
} from './MaQuantityCharacteristics.Field';
import { useSetupMaQuantityCharacteristicsField } from './useSetup.MaQuantityCharacteristics.Field';

vi.mock('./useSetup.MaQuantityCharacteristics.Field');

describe('🐛 MaQuantityCharacteristicsFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useSetupMaQuantityCharacteristicsField.mockReturnValue({
      disabled: RANDOM_BOOL,
      label: RANDOM_WORD,
      required: !RANDOM_BOOL,
    });

    // 🔥 Act
    const wrapper = shallow(<MaQuantityCharacteristicsField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<NumberFieldRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useSetupMaQuantityCharacteristicsField.mockReturnValue({
      disabled: RANDOM_BOOL,
      label: RANDOM_WORD,
      required: !RANDOM_BOOL,
    });

    // 🔥 Act
    const wrapper = shallow(<MaQuantityCharacteristicsField />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: RANDOM_BOOL,
      id: MAF_QUANTITY_CHARACTERISTICS_NAME,
      label: RANDOM_WORD,
      name: MAF_QUANTITY_CHARACTERISTICS_NAME,
      required: !RANDOM_BOOL,
    });
  });
});
