import {
  CUSTOMER_IMPROVEMENT_OBJECT_NAME,
  CustomerImprovementObject,
} from 'core/uiKit/preparedInputs';
import { Field } from 'react-final-form';

/**
 * Компонент CustomerImprovementObjectFF.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.disabled - Флаг активности редактирования.
 * @param {object} props.otherProps - Другие пропсы.
 * @returns {JSX.Element}
 */
export const CustomerImprovementObjectFF = ({
  disabled = false,
  ...otherProps
}) => {
  return (
    <Field name={CUSTOMER_IMPROVEMENT_OBJECT_NAME}>
      {(props) => {
        return (
          <CustomerImprovementObject
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { CUSTOMER_IMPROVEMENT_OBJECT_NAME };
