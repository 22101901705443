import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { useFormValuesCardRF } from 'core/form/reduxForm/hooks/useFormValuesCardRF';
import { MAF_TYPE_LEVEL_2_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel2';
import { useEffect, useState } from 'react';
import { vi } from 'vitest';

import { useSetupMafTypeLevel2IdField } from './useSetup.MafTypeLevel2Id.Field';

vi.mock('core/form/reduxForm/hooks/useField.RF');
vi.mock('core/form/reduxForm/hooks/useFormValuesCardRF');
vi.mock('app/api/hooks/autocomplete/useGetDictionary', () => ({
  useGetDictionary: vi.fn(),
}));
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    useEffect: vi.fn((fn) => fn()),
    useState: vi.fn(),
  };
});

/**
 * Дефолтные моки.
 *
 */
const defaultMock = () => {
  useGetDictionary.mockReturnValue({ data: undefined });
  useMode.mockReturnValue({});
  useFormValuesCardRF.mockReturnValue({});
  useFieldRF.mockReturnValue({});
  useState.mockReturnValue([]);
};

describe('🐛 UseSetupMafTypeLevel2IdFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();

    // 🔥 Act
    const res = useSetupMafTypeLevel2IdField();

    // ❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
      filter: expect.any(Function),
    });
  });

  it('🧪 filter called dict undefined', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const setHaveOptionsSpy = vi.fn();
    defaultMock();
    useState.mockReturnValue([false, setHaveOptionsSpy]);

    // 🔥 Act
    const { filter } = useSetupMafTypeLevel2IdField();
    const res = filter();

    // ❓ Assert
    expect(res).toStrictEqual([]);
    expect(setHaveOptionsSpy).toHaveBeenCalledWith(false);
  });

  it('🧪 filter called', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const setHaveOptionsSpy = vi.fn();
    defaultMock();
    useState.mockReturnValue([false, setHaveOptionsSpy]);
    useFormValuesCardRF.mockReturnValue({ maf_type_level1_id: 2 });

    // 🔥 Act
    const { filter } = useSetupMafTypeLevel2IdField();
    const res = filter([
      { id: 1, mafTypeLevel1: [2, 3, 4] },
      { id: 2, mafTypeLevel1: [4, 5, 6] },
      { id: 3, mafTypeLevel1: [2, 5, 6] },
    ]);

    // ❓ Assert
    expect(res).toStrictEqual([
      { id: 1, mafTypeLevel1: [2, 3, 4] },
      { id: 3, mafTypeLevel1: [2, 5, 6] },
    ]);
    expect(setHaveOptionsSpy).toHaveBeenCalledWith(true);
  });

  it('🧪 useEffect deps', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();
    useState.mockReturnValue([false, vi.fn()]);
    useFormValuesCardRF.mockReturnValue({ maf_type_level1_id: 2 });

    // 🔥 Act
    useSetupMafTypeLevel2IdField();

    // ❓ Assert
    expect(useEffect).toHaveBeenCalledWith(expect.any(Function), [2]);
  });

  it('🧪 onChange called', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();
    useState.mockReturnValue([false, vi.fn()]);
    useMode.mockReturnValue({ editMode: true });

    const onChangeSpy = vi.fn();
    useFieldRF.mockReturnValue({ onChange: onChangeSpy });
    useFormValuesCardRF.mockReturnValue({ maf_type_level1_id: 2 });

    // 🔥 Act
    useSetupMafTypeLevel2IdField();

    // ❓ Assert
    expect(useFieldRF).toHaveBeenCalledWith(MAF_TYPE_LEVEL_2_ID_NAME);
    expect(onChangeSpy).toHaveBeenCalledWith('');
  });
});
