import { useMapContext } from 'app/components/map/useMapContext';
import { getLengthGeometry } from 'app/pages/cardsOgh/api/helpers/getLengthGeometry';
import { postGeometryContains } from 'app/pages/cardsOgh/api/postCheckGeometryContains';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import { useIntersections } from 'app/pages/cardsOgh/hooks/useIntersection';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';

/**
 * Хук проверки contains.
 *
 * @param {object} params - Параметры хука.
 * @param {Function} params.showAlert - Функция отображения сообщения.
 * @param {Function} params.setHasIntersections - Функция устанавливающая состояния активного пересечения.
 * @param {Function} params.endPreload - Функция завершения состояния загрузки.
 * @returns {Function}
 */
export const useContains = ({
  setHasIntersections,
  showAlert,
  endPreload,
} = {}) => {
  const { drawIntersectionsGeometry } = useMapContext();
  const { checkIntersection } = useIntersections({
    endPreload,
    setHasIntersections,
    showAlert,
  });

  const [checkGeometryContains, { ...dataContains }] = useMutationAdaptor(
    postGeometryContains,
    {

      /**
       * OnError.
       */
      onError: () => {
        log.info('checkGeometryContains не прошел');
        endPreload();
      },

      /**
       * OnSuccess.
       *
       * @param {object} response - Ответ.
       * @param {object} req - Запрос.
       * @returns {void}
       */
      onSuccess: (response, req) => {
        const length = getLengthGeometry(response);
        if (10 < length) {
          showAlert?.(message.errorMore);
          endPreload();
        } else if (length) {
          endPreload();
          showAlert?.(message.errorOther);
          drawIntersectionsGeometry(response.geometry);
        } else checkIntersection(req);
      },
    },
  );

  return { checkGeometryContains, dataContains };
};
