import React from 'react';

import { ISubTypeTree } from '../Tree';
import { SubTypeItemTree } from './SubType.Item.Tree';

interface SubTypeTreeProps {

  /**
   * Флаг открытия всех элментов в группе.
   */
  openSubTypes?: boolean;

  /**
   * Подгруппы дерева элементов.
   */
  subTypes: ISubTypeTree[];
}

/**
 * Список элементов подгруппы дерева элементов.
 *
 * @param {SubTypeTreeProps} props - Props.
 * @returns Элементы подгруппы.
 */
export const SubTypeTree = ({ subTypes, openSubTypes }: SubTypeTreeProps) => {
  return subTypes?.map(({ subTypeName, id, elements }) => {
    return (
      <SubTypeItemTree
        key={id}
        {...{ elements, id, openSubTypes, subTypeName }}
      />
    );
  });
};
