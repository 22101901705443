import { ButtonMUI } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Кнопка.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
function Button(props) {
  const {
    className,
    children,
    color,
    component,
    disabled = false,
    disableFocusRipple,
    disableRipple,
    endIcon,
    fullWidth,
    href,
    id,
    size,
    startIcon,
    style,
    title,
    type,
    variant = 'contained',
    onClick,
    ...rest
  } = props;
  return (
    <ButtonMUI
      className={className}
      color={color}
      component={component}
      disabled={disabled}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      endIcon={endIcon}
      fullWidth={fullWidth}
      href={href}
      id={id}
      size={size}
      startIcon={startIcon}
      style={style}
      title={title}
      type={type}
      variant={variant}
      onClick={onClick}
      {...rest}
    >
      {children}
    </ButtonMUI>
  );
}

export default Button;
