import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент SelectFF.
 *
 * @param {object} props - Пропсы, передаваемые в компонент.
 * @param {boolean} [props.dark] - Флаг для включения темного режима для компонента.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {string} [props.label] - Метка, отображаемая рядом с компонентом.
 * @param {string} [props.name] - Имя поля формы.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const SelectFF = ({
  dark = false,
  disabled = false,
  label = 'MultiSelectFF label',
  name = 'MultiSelectFF',
  ...otherProps
}) => {
  return (
    <Field name={name}>
      {(props) => {
        return (
          <Select
            {...{ dark, disabled, label, name }}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
