import { log } from 'core/utils/log';
import { useCallback, useMemo } from 'react';

import useOnPreviousChange from './hooks/useOnPreviousChange';
import { useMapglContext } from './useMapglContext';
import { GeometryUtils } from './utils';
import { MapService } from './utils/MapService';
import {
  AllGeometries,
  GenericGeometryOptions,
  GenericRawGeometry,
  GeometryTypes,
  MapglEditorContextValue,
  RawGeometry,
} from './utils/types.d';

type TUseSetupMapglEditorContextProviderProps = {
  isEditing: boolean;
  mapService: MapService | null;
  geometryUtils: GeometryUtils | null;
  setIsEditing: (state: boolean) => void;
};

/**
 * Setup for Provider for MapglEditorComponent.
 *
 * @param props - React props.
 * @param props.isEditing - A flag indicating if editing is enabled.
 * @param props.mapService - The map service to retrieve geometries from.
 * @param props.geometryUtils - The geometry utils.
 * @param props.setIsEditing - A function to set the editing state.
 * @returns Данные Setup.
 */
export const useSetupMapglEditorContextProvider = ({
  isEditing,
  mapService,
  geometryUtils,
  setIsEditing,
}: TUseSetupMapglEditorContextProviderProps) => {
  const mapglContext = useMapglContext();

  useOnPreviousChange(
    useMemo(
      () => ({ geometryUtils, isEditing, mapService }),
      [mapService, geometryUtils, isEditing],
    ),
    (prev) => {
      if (
        (prev.mapService !== mapService ||
          prev.geometryUtils !== geometryUtils ||
          prev.isEditing !== isEditing) &&
        geometryUtils
      ) {
        if (mapService && !mapService.editService) {
          mapService?.createEditService(geometryUtils);
        }

        if (mapService && !mapService.drawService) {
          mapService?.createDrawService(geometryUtils);
        }

        if (isEditing && !mapService?.isEditMode) {
          mapService?.setIsEditMode(true);
          mapService?.enableEditing();
        }

        if (!isEditing && mapService?.isEditMode) {
          mapService?.setIsEditMode(false);
          mapService.finishEditing({ isCanceled: true });
        }
      }
    },
  );

  const startDrawingGeometry = useCallback(
    <Tt extends GeometryTypes>(
      type: Tt,
      options: GenericGeometryOptions<GeometryTypes>,
    ): GenericRawGeometry<Tt> | null => {
      // @ts-ignore
      if (!mapgl) {
        log.warn('mapgl is null');
        return null;
      }
      if (!mapService?.map) {
        log.warn('map is null');
        return null;
      }
      if (!mapService.geometryFactories) {
        log.warn('geometry factories is undefined');
        return null;
      }

      const newRawGeometry: RawGeometry | null = null;

      // @ts-ignore
      mapService.startDrawing(type, {
        ...options,
      });

      return newRawGeometry;
    },
    [mapService],
  );

  const deleteGeometry = useCallback(
    (id: string | number) => {
      mapService?.editService?.deleteGeometry(id);
    },
    [mapService],
  );

  /**
   * Cancels the current editing mode, resets geometries, destroys shape editor,
   * resets selected geometry, redraws geometries, and sets editing state to false.
   *
   */
  const cancelEditing = () => {
    mapService?.cancelEditing();
    // mapService?.finishEditing({ isCanceled: true });
    setIsEditing(false);
  };

  /**
   * Finishes the current editing mode, destroys shape editor, and redraws geometries.
   *
   * @param shouldDraw - Флаг для перерисовки геометрий.
   */
  const finishEditing = (shouldDraw = true) => {
    mapService?.finishEditing({ shouldDraw });
    setIsEditing(false);
  };

  /**
   * MapglEditorContextValue методы управления геометриями на карте.
   */
  const value = useMemo<MapglEditorContextValue>(
    // @ts-ignore
    () => ({
      ...mapglContext,
      cancelEditing,
      deleteGeometry,
      finishEditing,

      /**
       *  Get all geometries.
       *
       * @returns Object.
       */
      getAllGeometries: () =>
        ({
          circle: [],
          line: [],
          point: [],
          polygon: [],
        } as unknown as AllGeometries),

      /**
       * Get selected geometry.
       *
       * @returns Object.
       */
      getSelectedGeometry: () => null,
      startDrawingGeometry,
    }),
    // eslint-disable-next-line
    [mapglContext, startDrawingGeometry],
  );

  return { value };
};
