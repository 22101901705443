import {
  BUILDINGS_TYPE_SPEC_NAME,
  BuildingsTypeSpec,
} from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент BuildingsTypeSpec.
 *
 * @param {object} props - Пропсы компонента.
 * @param {boolean} [props.disabled] - Флаг для отключения компонента.
 * @param {object} [props.otherProps] - Дополнительные пропсы, передаваемые в компонент `BuildingsTypeSpec`.
 *
 * @returns {JSX.Element}
 */
export const BuildingsTypeSpecFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={BUILDINGS_TYPE_SPEC_NAME}>
      {(props) => {
        return (
          <BuildingsTypeSpec
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { BUILDINGS_TYPE_SPEC_NAME };
