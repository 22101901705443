import { QUANTITY_PCS_NAME } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'redux-form';

import { QuantityPCSReduxForm } from './QuantityPCSReduxForm';

/**
 * Компонент QuantityPcs.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @param {object} [props.otherProps] - Дополнительные пропсы.
 * @returns {JSX.Element}
 */
export const QuantityPcs = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      component={QuantityPCSReduxForm}
      name={QUANTITY_PCS_NAME}
    />
  );
};

export { QUANTITY_PCS_NAME };
