import { log } from 'core/utils/log';
import React, { createContext, memo, ReactNode } from 'react';

import { initialMapglContextValue } from './initialMapglContextValue';
import { useSetupMapglEditorContextProvider } from './useSetup.MapglEditorContextProvider';
import { GeometryUtils } from './utils';
import { MapService } from './utils/MapService';
import {
  AllGeometries,
  MapglContextValue,
  MapglEditorContextValue,
} from './utils/types.d';

export const MapglEditorContext = createContext<MapglEditorContextValue>({
  ...initialMapglContextValue,

  /**
   * Cancels the current editing mode, resets geometries, destroys shape editor,
   * resets selected geometry, redraws geometries, and sets editing state to false.
   *
   */
  cancelEditing: () => {
    log.warn('cancelEditing is not implemented');
  },

  /**
   * Deletes geometry.
   *
   * @param id - The ID of the geometry to delete.
   */
  deleteGeometry: (id: number | string) => {
    log.warn('deleteGeometry is not implemented ' + id);
  },

  /**
   * Finishes the current editing mode, destroys shape editor, and redraws geometries.
   *
   */
  finishEditing: () => {
    log.warn('finishEditing is not implemented');
  },

  /**
   * Deletes geometry.
   *
   * @returns Object.
   */
  getAllGeometries: () => {
    log.warn('getAllGeometries is not implemented');
    return {
      circle: [],
      line: [],
      point: [],
      polygon: [],
    } as unknown as AllGeometries;
  },

  /**
   * Gets selected geometry.
   *
   * @returns RawGeometryH.
   */
  getSelectedGeometry: () => {
    log.warn('getSelectedGeometry is not implemented');
    return null;
  },

  /**
   * On geometry change.
   */
  onGeometryChange: () => {
    log.warn('onGeometryChange is not implemented');
  },

  /**
   * Removes geometry.
   *
   */
  removeGeometry: () => {
    log.warn('removeGeometry is not implemented');
  },

  /**
   * Starts drawing geometry.
   *
   * @returns RawGeometry.
   */
  startDrawingGeometry: () => {
    log.warn('startDrawingGeometry is not implemented');
    return null;
  },
});

export type MapglEditorContextProviderProps = {
  isEditing: boolean;
  recordId: number | string;
  mapService: MapService | null;
  geometryUtils: GeometryUtils | null;
  drawGeometries: MapglContextValue['drawGeometries'];
  children: ReactNode;
  setIsEditing: (state: boolean) => void;
};

/**
 * Provider for MapglEditorComponent.
 *
 * @param props - React props.
 * @returns React functional component.
 */
export const MapglEditorContextProvider = memo(
  MapglEditorContextProviderFn,
  () => false,
);

/**
 * Provider for MapglEditorComponent.
 *
 * @param props - React props.
 * @param props.isEditing - A flag indicating if editing is enabled.
 * @param props.mapService - The map service to retrieve geometries from.
 * @param props.geometryUtils - The geometry utils.
 * @param props.children - The children of the component.
 * @param props.setIsEditing - A function to set the editing state.
 * @returns React functional component.
 */
function MapglEditorContextProviderFn({
  isEditing,
  mapService,
  geometryUtils,
  children,
  setIsEditing,
}: MapglEditorContextProviderProps) {
  const { value } = useSetupMapglEditorContextProvider({
    geometryUtils,
    isEditing,
    mapService,
    setIsEditing,
  });

  return (
    <MapglEditorContext.Provider value={value}>
      {children}
    </MapglEditorContext.Provider>
  );
}
