import { IconButton as IconButtonMUI } from 'core/uiKit/material-ui';
import { Tooltip as TooltipMUI } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Компонент иконки.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
function IconButton(props) {
  const { disabled, title, ...rest } = props;
  const ButtonComponent = <IconButtonMUI disabled={disabled} {...rest} />;

  return !disabled && title ? (
    <TooltipMUI placement="right-end" title={title} {...props}>
      {ButtonComponent}
    </TooltipMUI>
  ) : (
    ButtonComponent
  );
}

export default IconButton;
