import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React, { Children, cloneElement, useState } from 'react';

interface IAlertDialog {
  children: React.ReactElement | React.ReactElement[];
}

/**
 * Обертка над компонентом(компонентами), где будет использоваться Alert.
 *
 * @param props - Пропсы.
 * @param props.children - Дети куда обертка передает пропс showAlert.
 * @returns
 */
export const AlertDialog = ({ children }: IAlertDialog) => {
  const [alert, setAlert] = useState({ isOpen: false, message: '' });

  /**
   * Показать сообщение.
   *
   * @param message - Текст сообщения.
   */
  const showAlert = (message: string) => {
    setAlert({ isOpen: true, message });
  };

  /**
   * Закрыть диалоговое окно.
   *
   */
  const closeDialog = () => {
    setAlert({ isOpen: false, message: '' });
  };

  return (
    <>
      <Dialog
        isOpen={alert.isOpen}
        maxWidth={'sm'}
        textHeader={'Сообщение'}
        Body={
          <>
            <p>{alert.message}</p>
            <div className={'d-flex justify-content-end'}>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={closeDialog}
              >
                Закрыть
              </Button>
            </div>
          </>
        }
      />
      {Children.map(children, (child) => cloneElement(child, { showAlert }))}
    </>
  );
};
