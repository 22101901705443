import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { vi } from 'vitest';

import { useSetupEquipmentTypeIdField } from './useSetup.EquipmentTypeId.Field';

vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');

describe('🐛 EquipmentTypeIdField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);

    // 🔥 Act
    const res = useSetupEquipmentTypeIdField();

    // ❓ Assert
    expect(res).toStrictEqual({
      disabled: false, // Changed from undefined to false based on the logic
      filter: expect.any(Function),
    });
  });

  it('🧪 filter', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    const dict = [
      { fz_spr: 'spr21', id: 1 },
      { fz_spr: 'spr20', id: 2 },
      { fz_spr: 'spr20', id: 28 },
      { fz_spr: 'spr20', id: 29 },
    ];

    // 🔥 Act
    const { filter } = useSetupEquipmentTypeIdField();
    const filteredDict = filter(dict);

    // ❓ Assert
    expect(filteredDict).toEqual([{ fz_spr: 'spr20', id: 2 }]);
  });

  it.each`
    editMode     | useIsEditCurrentObject | expected
    ${true}      | ${true}                | ${false}
    ${true}      | ${false}               | ${true}
    ${true}      | ${undefined}           | ${true}
    ${false}     | ${true}                | ${true}
    ${false}     | ${false}               | ${true}
    ${false}     | ${undefined}           | ${true}
    ${undefined} | ${true}                | ${true}
    ${undefined} | ${false}               | ${true}
    ${undefined} | ${undefined}           | ${true}
  `(
    '🧪 disabled: editMode=$editMode | useIsEditCurrentObject=$useIsEditCurrentObject | expected=$expected',
    ({ editMode, useIsEditCurrentObject, expected }) => {
      expect.hasAssertions();

      // ☣️ Arrange (всякие моки)
      useMode.mockReturnValue({ editMode });
      useIsEditCurrentObjectIsExternalSystem.mockReturnValue(
        useIsEditCurrentObject,
      );

      // 🔥 Act
      const res = useSetupEquipmentTypeIdField();

      // ❓ Assert
      expect(res.disabled).toBe(expected);
    },
  );
});
