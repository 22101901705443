import Link from 'app/components/common/misc/Link';
import React, { memo } from 'react';

import { DropDownLink } from './DropDown';

const linkStyle = {
  cursor: 'pointer',
};

/**
 * Компонент NavItem.
 *
 * @param {object} props - Пропсы компонента.
 * @param {string} [props.className] - Класс для стилизации компонента.
 * @param {string} [props.id] - Идентификатор компонента.
 * @param {string} [props.link] - Ссылка для компонента, если тип не 'dropdown'.
 * @param {React.CSSProperties} [props.style] - Дополнительные стили для компонента.
 * @param {string} props.title - Заголовок/текст ссылки.
 * @param {'link' | 'dropdown'} props.type - Тип компонента, который может быть либо 'link', либо 'dropdown'.
 * @param {React.ReactNode} [props.children] - Дочерние элементы, отображаемые внутри компонента (например, элементы меню в выпадающем списке).
 * @param {Function} [props.onClick] - Обработчик клика для ссылки.
 *
 * @returns {JSX.Element} Элемент списка с ссылкой или выпадающим меню.
 */
export const NavItem = ({
  className,
  id,
  link = '#',
  style,
  title,
  type,
  children,
  onClick,
}) => (
  <li>
    {type === 'dropdown' ? (
      <DropDownLink
        className={className}
        id={id}
        linkStyle={linkStyle}
        text={title}
      >
        <ul>{children}</ul>
      </DropDownLink>
    ) : (
      <Link
        className={className}
        style={style}
        title={title}
        to={link}
        onClick={onClick}
      >
        {children}
      </Link>
    )}
  </li>
);

export default memo(NavItem);
