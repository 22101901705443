import {
  AddressFilter,
  addressParams,
} from 'core/form/filterForm/components/autocompleteAsync/AddressFilter';
import { BuildingsTypeSpecFilter } from 'core/form/filterForm/components/selects/BuildingsTypeSpec.Filter/BuildingsTypeSpecFilter';
import { BuildingTypeFilter } from 'core/form/filterForm/components/selects/BuildingTypeFilter';
import { IdFilter } from 'core/form/filterForm/components/text/ID.Filter';
import React, { useEffect } from 'react';

/**
 * Фильтр Иной объект капитального строительства.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const OtherOksFilter = ({ typeId }) => {
  useEffect(() => {
    addressParams();
  }, []);

  return (
    <>
      <IdFilter />
      <BuildingTypeFilter />
      <AddressFilter />
      <BuildingsTypeSpecFilter typeId={typeId} />
    </>
  );
};
