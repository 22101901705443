import { ImprovementObjectLazy } from 'app/pages/registry/ogh/improvementObject/ImprovementObject';
import { pathImprovementObject } from 'app/pages/registry/ogh/improvementObject/ImprovementObject/path.ImprovementObject';
import { BuildingLazy } from 'app/pages/registry/ogh/yard/Building';
import { pathBuilding } from 'app/pages/registry/ogh/yard/Building/path.Building';
import { ContainerOdhLazy } from 'app/pages/registry/ogh/yard/Container';
import { DtsLazy } from 'app/pages/registry/ogh/yard/Dts';
import { pathDts } from 'app/pages/registry/ogh/yard/Dts/path.Dts';
import { FlowersGardenLazy } from 'app/pages/registry/ogh/yard/FlowersGarden';
import { pathFlowersGarden } from 'app/pages/registry/ogh/yard/FlowersGarden/path.FlowersGarden';
import { FunctionalityLazy } from 'app/pages/registry/ogh/yard/Functionality';
import { pathFunctionality } from 'app/pages/registry/ogh/yard/Functionality/path.Functionality';
import { LawnLazy } from 'app/pages/registry/ogh/yard/Lawn';
import { pathLawn } from 'app/pages/registry/ogh/yard/Lawn/path.Lawn';
import { LittleFormLazy } from 'app/pages/registry/ogh/yard/LittleForm';
import { pathLittleForm } from 'app/pages/registry/ogh/yard/LittleForm/path.LittleForm';
import { OtherOksLazy } from 'app/pages/registry/ogh/yard/OtherOks';
import { pathOtherOks } from 'app/pages/registry/ogh/yard/OtherOks/path.OtherOks';
import { OtherTechPlaceLazy } from 'app/pages/registry/ogh/yard/OtherTechPlace';
import { pathOtherTechPlace } from 'app/pages/registry/ogh/yard/OtherTechPlace/path.OtherTechPlace';
import { PlanarStructureLazy } from 'app/pages/registry/ogh/yard/PlanarStructure';
import { pathPlanarStructure } from 'app/pages/registry/ogh/yard/PlanarStructure/path.PlanarStructure';
import { PpiLazy } from 'app/pages/registry/ogh/yard/Ppi';
import { pathPpi } from 'app/pages/registry/ogh/yard/Ppi/path.Ppi';
import { ReliefLazy } from 'app/pages/registry/ogh/yard/Relief';
import { pathRelief } from 'app/pages/registry/ogh/yard/Relief/path.Relief';
import { StoragePlaceLazy } from 'app/pages/registry/ogh/yard/StoragePlace';
import { pathStoragePlace } from 'app/pages/registry/ogh/yard/StoragePlace/path.StoragePlace';
import { TechPlaceLazy } from 'app/pages/registry/ogh/yard/TechPlace';
import { pathTechPlace } from 'app/pages/registry/ogh/yard/TechPlace/path.TechPlace';
import { TreesShrubsLazy } from 'app/pages/registry/ogh/yard/TreesShrubs';
import { pathTreesShrubs } from 'app/pages/registry/ogh/yard/TreesShrubs/path.TreesShrubs';
import { pathContainer } from 'app/pages/registry/ogh/сontainer/Container/path.Container';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { treeImprovementObject } from './ImprovementObject/tree.ImprovementObject';

/**
 * Route Иные объекты благоустройства.
 *
 * @returns {JSX.Element} - JSX.
 */
const ImprovementObjectRoute = () => {
  useEffect(() => {
    ImprovementObjectLazy.preload();
    PlanarStructureLazy.preload();
    OtherTechPlaceLazy.preload();
    BuildingLazy.preload();
    ContainerOdhLazy.preload();
    TreesShrubsLazy.preload();
    OtherOksLazy.preload();
    StoragePlaceLazy.preload();
    TechPlaceLazy.preload();
    DtsLazy.preload();
    PpiLazy.preload();
    ReliefLazy.preload();
    FunctionalityLazy.preload();
    LittleFormLazy.preload();
    LawnLazy.preload();
    FlowersGardenLazy.preload();
  });

  const { id: parentTypeId } = useGetOghType(pathImprovementObject.code);

  return (
    <Routes>
      <Route element={<ImprovementObjectLazy />} path={'/'} exact={true} />

      <Route
        element={
          <PlanarStructureLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathPlanarStructure.path}
      />
      <Route
        element={
          <OtherTechPlaceLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathOtherTechPlace.path}
      />

      <Route
        element={
          <BuildingLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathBuilding.path}
      />
      <Route
        element={
          <ContainerOdhLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathContainer.path}
      />
      <Route
        element={
          <StoragePlaceLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathStoragePlace.path}
      />
      <Route
        element={
          <TechPlaceLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathTechPlace.path}
      />
      <Route
        element={
          <DtsLazy tree={treeImprovementObject} parentTypeId={parentTypeId} />
        }
        path={pathDts.path}
      />
      <Route
        element={
          <PpiLazy tree={treeImprovementObject} parentTypeId={parentTypeId} />
        }
        path={pathPpi.path}
      />
      <Route
        element={
          <ReliefLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathRelief.path}
      />
      <Route
        element={
          <FunctionalityLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathFunctionality.path}
      />
      <Route
        element={
          <LittleFormLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathLittleForm.path}
      />

      <Route
        element={
          <LawnLazy tree={treeImprovementObject} parentTypeId={parentTypeId} />
        }
        path={pathLawn.path}
      />

      <Route
        element={
          <FlowersGardenLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathFlowersGarden.path}
      />
      <Route
        element={
          <TreesShrubsLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathTreesShrubs.path}
      />
      <Route
        element={
          <OtherOksLazy
            tree={treeImprovementObject}
            parentTypeId={parentTypeId}
          />
        }
        path={pathOtherOks.path}
      />
    </Routes>
  );
};

export default ImprovementObjectRoute;
