import { NumberFieldRF } from 'core/form/reduxForm/fields';
import React from 'react';

import { useSetupMaQuantityCharacteristicsField } from './useSetup.MaQuantityCharacteristics.Field';

export const MAF_QUANTITY_CHARACTERISTICS_NAME = 'maf_quantity_characteristics';

/**
 * Number Field __Количество мест шт__.
 *
 * @returns
 */
export const MaQuantityCharacteristicsField = () => {
  const { disabled, label, required } =
    useSetupMaQuantityCharacteristicsField();

  return (
    <NumberFieldRF
      {...{
        disabled,
        id: MAF_QUANTITY_CHARACTERISTICS_NAME,
        label,
        name: MAF_QUANTITY_CHARACTERISTICS_NAME,
        required,
      }}
    />
  );
};
