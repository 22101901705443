import { EAIS_ID_NAME } from 'core/uiKit/preparedInputs/textField/EaisId/index';
import React from 'react';
import { Field } from 'redux-form';

import { EaisIdWithReduxForm } from './EaisIdWithReduxForm';

/**
 * Компонент EaisIdRF.
 *
 * @param {object} props - Свойства компонента.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @param {object} [props.otherProps] - Дополнительные пропсы, которые будут переданы компоненту `CoatingGroupId`.
 *
 * @returns {JSX.Element}
 */
export const EaisIdRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      component={EaisIdWithReduxForm}
      name={EAIS_ID_NAME}
    />
  );
};
