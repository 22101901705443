import submitCard from 'app/components/card/ogh/submit';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import TramRailsCard, { onSubmit } from './tram_rails';

vi.mock('app/components/card/common/validation');
vi.mock('app/components/card/ogh/submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('🐛 компонент TramRailsCard', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TramRailsCard',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,

      mode: {},
    };

    // 🔥 Act
    const wrapper = shallow(<TramRailsCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });

  it('🧪 withChange CoatingGroupIdRF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const changeFieldValueSpy = vi.fn();
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TramRailsCard',
      changeFieldValue: changeFieldValueSpy,
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,

      mode: {},
    };

    // 🔥 Act
    const wrapper = shallow(<TramRailsCard {...props} />);
    const { withChange } = wrapper.find('CoatingGroupIdRF').props();
    withChange();

    // ❓ Assert
    expect(changeFieldValueSpy).toHaveBeenCalledWith(
      'coating_type_id',
      undefined,
    );
  });
});

describe('🐛 onSubmit', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 20 } };
    const onSubmitProps = onSubmit.bind({ props });

    // 🔥 Act
    onSubmitProps();

    // ❓ Assert
    expect(submitCard).toHaveBeenCalledWith(
      {
        card: {
          type_id: 20,
        },
      },
      {
        attribute: {
          file_list: [],
          is_diff_height_mark: false,
        },
        interval: {},
      },
    );
  });
});
