import { MafTypeLevel1IdField } from 'app/components/card/ogh/LittleForm/components/MafType/fields/MafTypeLevel1Id.Field';
import { MafTypeLevel2IdField } from 'app/components/card/ogh/LittleForm/components/MafType/fields/MafTypeLevel2Id.Field';
import { MafTypeLevel3Field } from 'app/components/card/ogh/LittleForm/components/MafType/fields/MafTypeLevel3.Field';
import { MaQuantityCharacteristicsField } from 'app/components/card/ogh/LittleForm/components/MafType/fields/MaQuantityCharacteristics.Field';
import { shallow } from 'enzyme';
import React from 'react';

import MafType from './MafType';

describe('🐛 MafTypeSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const props = {
      editable: true,
      isEditCurrentObjectIsExternalSystem: true,
      required: true,
    };

    // 🔥 Act
    const wrapper = shallow(<MafType {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<FieldsSplitColumns />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const props = {
      editable: true,
      isEditCurrentObjectIsExternalSystem: true,
      required: true,
    };

    // 🔥 Act
    const wrapper = shallow(<MafType {...props} />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      children: [
        <MafTypeLevel1IdField disabled={false} required={true} />,
        <MafTypeLevel2IdField />,
        <MafTypeLevel3Field />,
        <MaQuantityCharacteristicsField />,
      ],
    });
  });
});
