import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
import { TextField } from 'core/uiKit/inputs/TextField';
import React, { useMemo } from 'react';
import { KeyboardDatePickerProps } from 'types/inputs';

/**
 *  Выбор даты с клавиатуры.
 *
 * @param props - Props компонента.
 * @param props.value - Значение.
 * @param props.error - Ошибки.
 * @param props.maxDate - Максимальная дата '01.01.3000'.
 * @param props.minDate - Максимальная дата '01.01.3000'.
 * @param props.minDateMessage - Сообщение о минимальной дате.
 * @param props.maxDateMessage - Сообщение о максимальной дате.
 * @param props.required - Флаг обязательности поля.
 * @returns JSX.Element.
 */
export const KeyboardDatePicker = ({
  value,
  error,
  maxDate,
  minDate,
  minDateMessage,
  maxDateMessage,
  required,
  ...otherProps
}: KeyboardDatePickerProps) => {
  const propsData = useMemo(() => {
    return {
      error: !!error,
      helperText: error ? error : '',
    };
  }, [error]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={'ru'}
      localeText={{
        nextMonth: 'Следующий месяц',
        previousMonth: 'Предыдущий месяц',
      }}
    >
      <DesktopDatePicker
        inputFormat={'DD.MM.YYYY'}
        renderInput={(params) => (
          //@ts-ignore
          // ругается на defaultValues. Нужно типизировать TextField.
          <TextField
            {...{
              ...params,
              ...propsData,
              required,
            }}
          />
        )}
        {...otherProps}
        {...{
          maxDateMessage,
          minDateMessage,
        }}
        maxDate={parseDateToMoment(String(maxDate))}
        minDate={parseDateToMoment(String(minDate))}
        value={value || null}
        //@ts-ignore
        inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
      />
    </LocalizationProvider>
  );
};
