import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { ICE_RINK_PLANAR_STRUCTURE_NAME } from 'core/form/reduxForm/fields/prepared/checkboxes/IceRinkPlanarStructure.RF';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { useFormValuesCardRF } from 'core/form/reduxForm/hooks/useFormValuesCardRF';
import { useEffect } from 'react';

/**
 * UseSetup Field __Каток открытый с естественным льдом на существующей спортивной площадке спортивного типа__.
 *
 * @returns
 */
export const useSetupIceRinkPlanarStructureField = () => {
  const { editMode } = useMode();
  const { planar_structure_type_id = 0, planar_structure_type_ref_id = 0 } =
    useFormValuesCardRF<{
      planar_structure_type_id: number;
      planar_structure_type_ref_id: number;
    }>();
  const { onChange } = useFieldRF<boolean>(ICE_RINK_PLANAR_STRUCTURE_NAME);

  useEffect(() => {
    if (editMode) {
      onChange?.(false);
    }
    // eslint-disable-next-line
  }, [planar_structure_type_id, planar_structure_type_ref_id]);

  const isActive =
    [42].includes(planar_structure_type_id) &&
    [1].includes(planar_structure_type_ref_id);

  return { disabled: !(editMode && isActive) };
};
