import { fetchCreate } from 'app/api/ogh';
import { usePreloaderCard } from 'app/pages/cardsOgh/components/PreloadedCard/usePreloaderCard';
import { copySelectedGeometryMessage } from 'app/pages/cardsOgh/helperComponents/copySelectedGeometryMessage';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import {
  CREATE_FROM_PARENT,
  useTypeActionCard,
} from 'app/pages/cardsOgh/hooks/useTypeActionCard';
import { toast } from 'core/uiKit/components/Toast';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useNavigateRouter } from 'core/utils/router';

import { useGetTreePanelData } from '../components/RightPanelCard/components/TreeRightPanel/hooks/useGetTreePanelData';

/**
 * Хук создания нового паспорта.
 *
 * @param {object} root0 - Properties.
 * @param {Function} root0.showAlert - Показывает предупреждение.
 * @returns {{fetchCreateForm: Function}}
 */
export const useCreateForm = ({ showAlert }) => {
  const { clearEditMode } = useMode();
  const navigate = useNavigateRouter();
  const { id } = useGetObjectId();
  const { removeTree } = useGetTreePanelData();
  const { endPreload } = usePreloaderCard();

  const typeAction = useTypeActionCard();

  const [, { mutateAsync }] = useMutationAdaptor(fetchCreate, {

    /**
     * OnError.
     *
     * @param {object} err - Error.
     * @returns {void}
     */
    onError: (err) => {
      endPreload();
      if (err.message.match(/рассчитанная по геометрии/g)) {
        showAlert(copySelectedGeometryMessage(err.message));
      } else {
        showAlert(err.message ? err.message : 'Ошибка при создании паспорта');
      }
    },

    /**
     * OnSuccess.
     *
     * @param {object} value - Ответ.
     * @returns {void}
     */
    onSuccess: (value) => {
      endPreload();
      removeTree();
      clearEditMode();
      toast.successDying('Паспорт добавлен', {
        autoClose: 2000,
      });
      switch (typeAction) {
        case CREATE_FROM_PARENT: {
          navigate(`/ogh/${id}/${value.id}`);
          break;
        }
        default: {
          navigate(`/ogh/${value.id}`);
          break;
        }
      }
    },
  });

  return { fetchCreateForm: mutateAsync };
};
