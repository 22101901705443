/**
 * Функция Получение сообщений.
 *
 * @param {object} params - Параметры.
 * @param {object} params.data - Данные.
 * @returns {object}
 */
export const getMessage = ({ data }) => {
  // собираем hint
  const objectAttributeHint = data?.geometry?.map((item) => {
    const objectHint = {};
    item?.hint?.object_attribute_hint?.forEach(({ key, value }) => {
      objectHint[key] = value;
    });

    return objectHint;
  });

  const messages =
    objectAttributeHint?.map((item = {}) => ({
      id: item.id_ogh,
      text: [
        item.id_ogh,
        item.title,
        item.name,
        item.owner_name,
        item.intersection_area_percent,
        item.create_type,
        item.start_date,
        item.end_date,
      ]
        .filter(Boolean)
        .join(', '),
    })) || '';

  return messages;
};
