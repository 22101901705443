import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { Button } from 'core/uiKit/components/buttons/Button';

/**
 * Закрыть (ЭП).
 *
 * @param {object} props - Props.
 * @param {number} props.objectId - Id версии ОГХ.
 * @returns {React.ReactElement}
 */
export const ButtonCloseElectronicSignature = ({ objectId, ...props }) => {
  const { data: { close_eds: closeEds } = false } =
    useGetVisibilityButtonsOgh(objectId);

  return closeEds ? (
    <Button
      {...props}
      color={'error'}
      variant={'contained'}
      startIcon={<FontAwesomeIcon icon={faFileSignature} />}
    >
      Закрыть (ЭП)
    </Button>
  ) : null;
};
