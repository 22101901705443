import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { ButtonSigningOnRenewalOfApprovalDate } from './Button.SigningOnRenewalOfApprovalDate';

vi.mock('app/api/hooks/useGetVisibilityButtonsOgh');

/**
 * CreateMock.
 *
 * @returns {void}
 */
const createMock = () => {
  useGetVisibilityButtonsOgh.mockImplementation((objectId) => ({
    data: { reapprove_eds: objectId === 42 },
  }));
};

/**
 * WrapperComponent подготавливаем компонент для теста.
 *
 * @param {number} objectId - Id объекта.
 * @returns {*}
 */
function wrapperComponent(objectId) {
  createMock();
  return shallow(<ButtonSigningOnRenewalOfApprovalDate objectId={objectId} />);
}

describe('🐛 component ButtonSigningOnRenewalOfApprovalDate', () => {
  it('🧪 useGetVisibilityButtonsOgh был вызван с Random Number', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const objectId = Math.round(Math.random() * 10000000);

    // 🔥 Act
    wrapperComponent(objectId);

    // ❓ Assert
    expect(useGetVisibilityButtonsOgh).toHaveBeenCalledWith(objectId);
  });

  it('🧪 есть кнопка', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const button = wrapperComponent(42);

    // ❓ Assert
    expect(button.text()).toBe('<Button />');
  });

  it('🧪 есть кнопка с пропсами', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const button = wrapperComponent(42);

    // ❓ Assert
    expect(button.props()).toMatchObject({
      children: 'Обновить дату утверждения (ЭП)',
      color: 'success',
      variant: 'contained',
    });
  });

  it('🧪 нет кнопки', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const button = wrapperComponent(2);

    // ❓ Assert
    expect(button.text()).toBe('');
  });
});
