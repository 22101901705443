import { useGetVisibilityButtonsOgh } from 'app/api/hooks';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';

/**
 * Компонент SendMatchingBoundariesOghButton.
 *
 * @param {object} props - Пропсы компонента.
 * @param {string} props.objectId - Идентификатор объекта, для которого нужно получить информацию о видимости кнопки.
 * @returns {JSX.Element|null}
 */
export const SendMatchingBoundariesOghButton = ({ objectId, ...props }) => {
  const {
    data: { send_matching_boundaries: sendMatchingBoundaries = false } = {},
  } = useGetVisibilityButtonsOgh(objectId);

  return sendMatchingBoundaries ? (
    <Button
      {...props}
      color={'success'}
      variant={'contained'}
      startIcon={<Icon>ios_share</Icon>}
    >
      На согласование границ
    </Button>
  ) : null;
};

export default SendMatchingBoundariesOghButton;
