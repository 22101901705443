import { useContext } from 'react';

import { MapglContext } from './MapglContextProvider';

/**
 * Хук для получения контекста карты.
 *
 * @deprecated Используй от src/app/components/map/useMapContext.
 * @returns Object.
 */
export const useMapglContext = () => {
  const context = useContext(MapglContext);

  if (context === undefined) {
    throw new Error('useMapglContext must be used within a MapglContext');
  }

  context.getGeometry =
    context.getGeometries ||
    (() => {
      throw new Error('useMapglContext must be used within a MapglContext');
    });

  return context;
};
