import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { useGetCardModeSelector } from 'app/selectors/useSelectors';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { ApproveElectronicSignatureButton } from './ApproveElectronicSignature.Button';

vi.mock('app/api/hooks/useGetVisibilityButtonsOgh');
vi.mock('app/selectors/useSelectors');

/**
 * Create mock.
 *
 * @param {object} root0 - Root0.
 * @param {boolean} root0.createMode - CreateMode.
 * @returns {void}
 */
const createMock = ({ createMode = false } = {}) => {
  useGetVisibilityButtonsOgh.mockImplementation((objectId) => ({
    data: { approve_eds: objectId === 42 },
  }));
  useGetCardModeSelector.mockReturnValue({ createMode });
};

/**
 * Wrapper component.
 *
 * @param {number} objectId - Id версии ОГХ.
 * @param {object} obj - Object.
 * @returns {JSX}
 */
function wrapperComponent(objectId, obj) {
  createMock(obj);
  return shallow(<ApproveElectronicSignatureButton objectId={objectId} />);
}

describe('🐛 component EditOghButton', () => {
  it('🧪 useGetVisibilityButtonsOgh был вызван с 1', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    wrapperComponent(1);

    // ❓ Assert
    expect(useGetVisibilityButtonsOgh).toHaveBeenCalledWith(1);
  });

  it('🧪 useGetVisibilityButtonsOgh был вызван с 2', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    wrapperComponent(2);

    // ❓ Assert
    expect(useGetVisibilityButtonsOgh).toHaveBeenCalledWith(2);
  });

  it('🧪 есть кнопка', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const button = wrapperComponent(42);

    // ❓ Assert
    expect(button.text()).toBe('<Button />');
  });

  it('🧪 есть кнопка с пропсами', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const button = wrapperComponent(42);

    // ❓ Assert
    expect(button.props()).toMatchObject({
      children: 'Утвердить (ЭП)',
      color: 'success',
      variant: 'contained',
    });
  });

  it('🧪 нет кнопки createMode', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const button = wrapperComponent(2, {
      createMode: true,
    });

    // ❓ Assert
    expect(button.text()).toBe('');
  });

  it('🧪 42, нет кнопки createMode', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const button = wrapperComponent(42, {
      createMode: true,
    });

    // ❓ Assert
    expect(button.text()).toBe('');
  });

  it('🧪 нет кнопки createMode false', () => {
    expect.hasAssertions();
    const button = wrapperComponent(2, {
      createMode: false,
    });
    expect(button.text()).toBe('');
  });
});
