import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { Button } from 'core/uiKit/components/buttons/Button';

/**
 * Кнопка Обновить дату утверждения (ЭП).
 *
 * @param {object} props - Пропсы.
 * @param {number} props.objectId - Id объекта.
 * @returns {JSX.Element}
 */
export const ButtonSigningOnRenewalOfApprovalDate = ({
  objectId,
  ...props
}) => {
  const { data: { reapprove_eds: reApproveEds } = {} } =
    useGetVisibilityButtonsOgh(objectId);

  return reApproveEds ? (
    <Button
      {...props}
      color={'success'}
      variant={'contained'}
      startIcon={<FontAwesomeIcon icon={faFileSignature} />}
    >
      Обновить дату утверждения (ЭП)
    </Button>
  ) : null;
};
