import { Checkbox } from 'core/uiKit/inputs/CheckBox';
import React from 'react';

export const ICE_RINK_PLANAR_STRUCTURE_NAME = 'ice_rink_planar_structure';

/**
 * CheckBox __Каток открытый с естественным льдом на существующей спортивной площадке спортивного типа__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const IceRinkPlanarStructure = (
  props: React.ComponentProps<typeof Checkbox>,
) => {
  return (
    <Checkbox
      {...props}
      label={
        'Каток открытый с естественным льдом на существующей спортивной площадке спортивного типа'
      }
      name={ICE_RINK_PLANAR_STRUCTURE_NAME}
    />
  );
};
