import { createObjGeoData } from './createObjGeoData';
import { getMessage } from './getMessage';
import { useDrawDrawIntersectionsGeometry } from './useDrawDrawIntersectionsGeometry';
import { useGetGeometryIntersection } from './useGetGeometryIntersection';

/**
 * Хук проверки пересечений.
 *
 * @returns {{isLoading: boolean, objGeoData: *, data: unknown, messages: (*|string)}}
 */
export const useFetchIntersectionsGeometry = () => {
  const { geometry, drawIntersections } = useDrawDrawIntersectionsGeometry();

  // получить геометрию
  const { data, isLoading } = useGetGeometryIntersection({
    drawIntersections,
    geometry,
  });

  const messages = getMessage({ data });

  // подготавливаем для файла
  const objGeoData = data?.geometry?.map(createObjGeoData);

  return {
    data,
    isLoading,
    messages,
    objGeoData,
  };
};
