import { SUB_TYPE_ID_NAME, SubTypeIdFF } from 'core/form/finalForm/fields';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Компонент SubTypeIdFilter.
 *
 * @param {object} props - Пропсы компонента.
 * @returns {JSX.Element}
 */
export const SubTypeIdFilter = (props) => (
  <SubTypeIdFF {...props} className={filterInput} />
);

export { SUB_TYPE_ID_NAME };

export const subTypeIdParams = {
  [SUB_TYPE_ID_NAME]: NumberParam,
};
