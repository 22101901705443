import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { INTERSECTIONS } from 'app/constants/layers';
import { getAttributeForm } from 'app/pages/cardsOgh/helpers/getAttributeForm';
import { useContains } from 'app/pages/cardsOgh/hooks/useContains';
import { useState } from 'react';
import { useFormState } from 'react-final-form';

/**
 * Setup Кнопка проверки пересечения геометрии со смежными ОГХ.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.showAlert - Функция вызывающая сообщение.
 * @returns {object} - Состояние кнопки.
 */
export const useSetupCheckGeometryButton = ({ showAlert }) => {
  const [hasIntersections, setHasIntersections] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Начало запуска прелоудера.
   *
   */
  const startPreload = () => {
    setIsLoading(true);
  };

  /**
   * Заканчивает прелоудер.
   *
   */
  const endPreload = () => {
    setIsLoading(false);
  };

  const { checkGeometryContains } = useContains({
    endPreload,
    setHasIntersections,
    showAlert,
  });
  const { getGeometry, isMapLoaded, clearLayer } = useMapContext();
  const { parentId, typeId, startDate, recordId, rootId } = useGetCurrentCard();
  const form = useFormState();
  const parent = useGetParentCard();

  /**
   * Функция проверки.
   *
   * @returns {void}
   */
  const checkGeometryButton = async () => {
    if (!isMapLoaded) {
      return;
    }
    const geometry = getGeometry(recordId);

    const data = {
      attribute: getAttributeForm(typeId, form.values),
      cnt: null,
      geometry,
      id: rootId,
      journal: null,
      parentId: parentId ? Number(parentId) : null,
      startDate,
      treeParentId: parent.recordId,
      typeId: Number(typeId),
    };

    if (hasIntersections) {
      clearLayer(INTERSECTIONS);
      setHasIntersections(false);
    } else {
      startPreload();
      await checkGeometryContains(data);
    }
  };

  return { checkGeometryButton, hasIntersections, isLoading, isMapLoaded };
};
