import { OZN, YARD } from 'app/constants/oghTypes';
import { createNumberedList } from 'app/utils/createNumberedList';

/**
 * Общая площадь объекта, кв м.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const getHintTotalArea = (typeId) => {
  const squareChildren = [
    '"Газоны"',
    '"Дорожно-тропиночные сети"',
    '"Закрытые велопарковки"',
    '"Иные некапитальные объекты"',
    '"ИОКС типа Архитектурный памятник"',
    '"ИОКС типа Объект монументального искусств"',
    '"ИОКС типа Отмостка"',
    '"Места сборов отходов"',
    '"Некапитальные объекты для обеспечения производственной деятельности по содержанию и ремонту территорий (в том числе базы хранения ПГР на дворовых территориях)"',
    '"Плоскостные сооружения"',
    '"Водоемы"',
  ];

  switch (typeId) {
    case OZN: {
      return createNumberedList({
        header: 'Рассчитывается как сумма значений',
        list: [
          {
            children: [
              'Жилое «Объект социального назначения»',
              'Нежилое «Нежилое здание»',
              'Нежилое «Объект монументального искусства»',
              'Нежилое «Архитектурный памятник»',
              'Нежилое «Иной капитальный объект»',
              'Нежилое «Отмостка»',
            ],
            item: '«Общая площадь объектов капитального строительства, кв.м.»',
          },
          {
            children: [
              'НКО типа Объект административно-хозяйственного значения',
              'НКО типа Объект технического назначения',
              'НКО типа Модульный туалет',
              'НКО типа Объект торговли',
              'НКО типа Тир',
              'НКО типа Павильон',
              'НКО типа Беседка',
              'НКО типа Ротонда',
              'НКО типа Веранда прогулочная',
              'НКО типа Сцена',
              'НКО типа Иное некапитальное строение',
            ],
            item: '«Общая площадь иных некапитальных объектов, кв.м.»',
          },
          {
            children: [
              'Автопарковка',
              'Детская площадка',
              'Площадка для пожарной техники',
              'Площадка для выгула животных',
              'Площадка для отдыха',
              'Смотровая площадка',
              'Площадка иного типа',
              'Спортивная площадка',
              'Техническая площадка',
              'Экопарковка',
              'Ролледром',
              'Скейтпарк',
              'Амфитеатр',
              'Уличный вольер',
              'Велопарковка открытого типа',
              'Бункерная площадка',
              'Контейнерная площадка',
              'Площадка стационарного павильона для РСО',
            ],
            item: '«Общая площадь плоскостных сооружений, кв.м.»',
          },
          {
            children: [],
            item: 'Закрытая велопарковка для долгосрочного хранения велосипедов',
          },
          {
            children: [
              'Проезд',
              'Тротуар',
              'Технический (технологический) тротуар',
              'Пешеходная дорожка',
              'Беговая дорожка',
              'Велосипедная дорожка',
            ],
            item: '«Общая площадь дорожно-тропиночной сети, кв.м»',
          },
          {
            children: [
              'Газон обыкновенный',
              'Газон луговой, разнотравный',
              'Газон партерный',
              'Газон на откосе',
              'Незапечатанная поверхность без дернового покрытия (древесная кора (щепа))',
            ],
            item: '«Общая площадь газонов, кв.м.» (за вычетом зелёных насаждений)',
          },
          {
            children: [],
            item: '«Общая площадь деревьев/кустарников, кв.м.»',
          },
          {
            children: [
              'Однолетники',
              'Многолетники и розы',
              'Двулетники (виола)',
              'Луковичные и клубнелуковичные кроме тюльпанов, нарциссов',
              'Тюльпаны, нарциссы',
              'Инертный материал (отсыпка)',
              'Декоративные кустарники (как элемент цветника)',
              'Газоны (как элемент цветника)',
            ],
            item: '«Общая площадь цветников, кв.м.»',
          },
          {
            children: [
              'Общая площадь посадочных мест в элементах цветочного оформления',
              'Общая площадь посадочных мест в элементах озеленения',
            ],
            item: '«Общая площадь посадочных мест в элементах вертикального озеленения, кв.м»',
          },
          {
            children: ['Пандус'],
            item: '«Общая площадь элементов благоустройства для маломобильных групп населения, кв.м»',
          },
          {
            children: [
              'Лестница',
              'Подпорная стенка',
              'Габион',
              'Системы укрепления откосов',
            ],
            item: '«Общая площадь элементов организации рельефа, кв.м»',
          },
          {
            children: [
              'Лоток водосточный',
              'Шахта вентиляционной камеры',
              'Желоб ливневой',
            ],
            item: '«Общая площадь элементов СФО, кв.м»',
          },
          {
            children: [
              'Фонтан',
              'Пирс (причал)',
              'Сухой фонтан',
              'Пешеходный мост',
              'Бассейн',
              'Променад',
            ],
            item: '«Общая площадь инженерных сооружений, кв. м»',
          },
          {
            children: ['Пруд', 'Болото', 'Озеро', 'Река', 'Ручей'],
            item: '«Площадь водных сооружений, кв.м»',
          },
          {
            children: [
              'Открытый с искусственным льдом',
              'Крытый с воздухоопорной конструкцией с искусственным льдом',
              'Крытый под металлоконструкцией с искусственным льдом',
            ],
            item: '«Катки»',
          },
        ],
      });
    }
    default: {
      if ([YARD].includes(typeId)) {
        squareChildren.push('"Инженерные сооружения"');
      }

      return createNumberedList({
        header: 'Рассчитывается как сумма значений',
        list: [
          {
            children: ['"Объекты капитального строительства"'],
            item: '"Площадь застройки, кв.м."',
          },
          {
            children: squareChildren,
            item: '"Площадь, кв.м."',
          },
          {
            children: ['"Цветники"'],
            item: '"Общая площадь цветника, кв.м"',
          },
          {
            children: [
              'Элементы благоустройства для маломобильных групп населения типа Пандус',
              'Элементы организации рельефа типа Лестницы, Подпорные стены, Габион, Системы укрепления откосов',
              'Элементы системы функционального обеспечения типа Лоток водосточный (решетка), Шахта вентиляционной камеры.',
            ],
            item: 'Количество, кв.м.',
          },
        ],
      });
    }
  }
};
