import { GROUP_ID_NAME, GroupId } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * GroupIdFF.
 *
 * @param {object} props - Пропсы, передаваемые в компонент.
 * @param {boolean} [props.disabled] - Определяет, будет ли компонент заблокирован.
 * @param {...object} [props.otherProps] - Другие пропсы, которые могут быть переданы компоненту `GroupId`.
 * @returns {React.Element}
 */
export const GroupIdFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={GROUP_ID_NAME}>
      {({ input, ...props }) => {
        return (
          <GroupId disabled={disabled} {...props} {...otherProps} {...input} />
        );
      }}
    </Field>
  );
};

export { GROUP_ID_NAME };
