import { getFieldError } from 'app/utils/validate/getFieldError';
import { get } from 'lodash';

/**
 * Setup Автокомплит.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
export const useSetupAutocomplete = (props) => {
  const { meta, optionKey, optionName, value, onChange, options, errorText } =
    props;
  const error = getFieldError(meta);

  /**
   * Получить ключ.
   *
   * @param {*} value - Значения.
   * @returns {*}
   */
  const getByKey = (value) => get(value, optionKey);

  /**
   * Получить имя.
   *
   * @param {*} value - Значения.
   * @returns {string}
   */ const getByName = (value) => get(value, optionName, '');

  /**
   * Функция изменения.
   *
   * @param {Event} event - Event.
   * @param {string} newValue - Новое значение.
   * @returns {void}
   */
  const handleChange = (event, newValue) => {
    onChange(getByKey(newValue));
  };
  const isError = !!error || !!errorText;

  const optionsFiltered = (options || []).filter((item) => !item.is_archive);
  const item =
    optionsFiltered.find((item) => getByKey(item) === value) ||
    optionsFiltered[0] ||
    null;

  return {
    getByName,
    handleChange,
    isError,
    item,
    optionsFiltered,
  };
};
