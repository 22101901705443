import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React from 'react';

/**
 * GeometryWarningMessage.
 *
 * @param {object} root0 - Properties.
 * @param {boolean} root0.isOpen - Открыт ли диалог.
 * @param {Function} root0.closeDialog - Функция закрытия диалога.
 * @returns {JSX.Element}
 */
export const GeometryWarningMessage = ({ isOpen, closeDialog }) => {
  return (
    <Dialog
      isOpen={isOpen}
      maxWidth={'sm'}
      textHeader={'Не указана геометрия объекта'}
      Body={
        <>
          <p>Отобразите геометрию ОГХ перед сохранением.</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={closeDialog}
            >
              Закрыть
            </Button>
          </div>
        </>
      }
    />
  );
};
