import { Select } from 'core/uiKit/inputs/selects/Select/Select';
import React from 'react';
import { Field } from 'react-final-form';

const progressStatusList = [
  {
    id: 'in_progress',
    name: 'Работы ведутся',
  },
  {
    id: 'finished',
    name: 'Работы завершены',
  },
  {
    id: 'planned',
    name: 'Работы запланированы',
  },
];

export const PROGRESS_STATUS_NAME = 'progressStatus';

/**
 * SelectWrapper.
 *
 * @param {*} props - Props.
 * @returns {JSX.Element}
 */
export const SelectWrapper = (props) => {
  return (
    <Select
      style={{ minWidth: 250 }}
      {...props}
      options={progressStatusList}
      label={'Статус выполнения работ'}
    />
  );
};

/**
 * ProgressStatusFF.
 *
 * @param {object} props - Props.
 * @param {boolean} props.dark - Dark.
 * @param {boolean} props.disabled - Disabled.
 * @param {object} props.otherProps - Other props for the component.
 * @returns {JSX.Element}
 */
export const ProgressStatusFF = ({
  dark = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Field name={PROGRESS_STATUS_NAME}>
      {(props) => {
        return (
          <SelectWrapper
            {...{ dark, disabled }}
            {...props}
            {...otherProps}
            {...props.input}
          />
        );
      }}
    </Field>
  );
};
