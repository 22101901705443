import { TreePanelOgh } from 'app/pages/components';
import React from 'react';

import { tree as wood } from './tree';

/**
 * Боковое дерево.
 *
 * @param {object} root0 - Root.
 * @param {object} [root0.root] - Root.
 * @param {object} [root0.tree] - Tree.
 * @returns {JSX.Element}
 */
export const TreePanelYard = ({ root, tree = wood }) => {
  return <TreePanelOgh root={root} tree={tree} />;
};
