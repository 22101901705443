import { SUB_TYPE_ID_NAME, SubTypeId } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

export { SUB_TYPE_ID_NAME };

/**
 * SubTypeIdFF.
 *
 * @param {object} props - Пропсы, передаваемые в компонент.
 * @param {boolean} [props.dark] - Определяет, будет ли применен темный стиль.
 * @param {boolean} [props.disabled] - Определяет, будет ли компонент заблокирован.
 * @param {...object} [props.otherProps] - Другие пропсы, которые могут быть переданы компоненту `SubTypeId`.
 * @returns {React.Element} Компонент формы, обернутый в `Field` от `react-final-form`.
 */
export const SubTypeIdFF = ({
  dark = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Field name={SUB_TYPE_ID_NAME}>
      {(props) => {
        return (
          <SubTypeId
            {...{ dark, disabled }}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
