import { useContext } from 'react';

import { PreloadedCardContext } from './contextPrelouder';

/**
 * Fn usePreloaderCard .
 *
 * @returns - UsePreloaderCard.
 */
export const usePreloaderCard = () => {
  return useContext(PreloadedCardContext);
};
