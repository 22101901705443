import { useGetVisibilityButtonsOgh } from 'app/api/hooks';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';

/**
 * Кнопка "Согласовать" Огх.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.objectId - Id объекта.
 * @returns {JSX.Element}
 */
export const ApproveBoundariesOghButton = ({ objectId, ...props }) => {
  const { data: { approve_boundaries: approveBoundaries = false } = {} } =
    useGetVisibilityButtonsOgh(objectId);

  return approveBoundaries ? (
    <Button
      {...props}
      color={'success'}
      variant={'contained'}
      startIcon={<Icon>done</Icon>}
    >
      Утвердить границы
    </Button>
  ) : null;
};

export default ApproveBoundariesOghButton;
