import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { RANDOM_ID } from 'core/forTesting/constants';
import { fetchOghTypes } from 'core/uiKit/preparedInputs/api';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { vi } from 'vitest';

import { useMafTypeLevel1Options } from './useMafTypeLevel1Options';

vi.mock('app/api/hooks/useGetParentCard');
vi.mock('core/utils/hooks/useReactQuery');

describe('🐛 UseMafTypeLevel1OptionsSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useGetParentCard.mockReturnValue({ typeId: RANDOM_ID });
    useQueryAdaptor.mockReturnValue({
      data: [{ id: 28, name: 'name', quantity_name: 'quantity_name' }],
    });

    // 🔥 Act
    const res = useMafTypeLevel1Options();

    // ❓ Assert
    expect(res).toStrictEqual([
      { id: 28, name: 'name', quantity_name: 'quantity_name' },
    ]);
  });

  it('🧪 useQueryAdaptor called params', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useGetParentCard.mockReturnValue({ typeId: RANDOM_ID });
    useQueryAdaptor.mockReturnValue({
      data: [{ id: 28, name: 'name', quantity_name: 'quantity_name' }],
    });

    // 🔥 Act
    useMafTypeLevel1Options();

    // ❓ Assert
    expect(useQueryAdaptor).toHaveBeenCalledWith(
      [
        'optionsMafTypeLevel1AutoComplete',
        {
          parentTypeId: RANDOM_ID,
          typeId: 37,
        },
      ],
      fetchOghTypes,
      {
        enabled: true,
      },
    );
  });
});
