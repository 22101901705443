import { Button as ButtonMUI } from '@mui/material';
import { CircularProgressMUI } from 'core/uiKit/material-ui';
import { Tooltip } from 'core/uiKit/material-ui';
import React from 'react';

import { ButtonProps } from './Button.d';

/**
 * Кнопка.
 *
 * @param {ButtonProps} props - Property.
 * @returns {JSX.Element|null} - JSX.
 */
export const Button = ({
  className,
  loading,
  isLoading = loading,
  disabled,
  hidden,
  startIcon,
  toolTip = '',
  primary,
  objectId,
  ...otherProps
}: ButtonProps): JSX.Element | null => {
  const buttonProps = {
    ...(primary && { color: 'primary' }),
    ...(primary === false && { color: 'default' }),
    ...(objectId && { 'data-object-id': objectId }),
  };

  return !hidden ? (
    <Tooltip title={toolTip}>
      <span>
        <ButtonMUI
          startIcon={isLoading ? <CircularProgressMUI size={20} /> : startIcon}
          {...otherProps}
          className={className}
          disabled={disabled || isLoading}
          sx={{ whiteSpace: 'nowrap' }}
          {...buttonProps}
        />
      </span>
    </Tooltip>
  ) : null;
};
