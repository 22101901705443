import { OKRUG_NAME, OkrugFF } from 'core/form/finalForm/fields';
import React from 'react';
import { StringParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export { OKRUG_NAME };

/**
 * Фильтр округ.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const OkrugFilter = (props) => (
  <OkrugFF {...props} className={filterInput} />
);

/**
 * Параметры округа.
 *
 * @returns {object}
 */
export const okrugParams = () => ({
  [OKRUG_NAME]: StringParam,
});
