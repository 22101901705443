import { CONTAINER_TYPE_NAME, ContainerType } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

export { CONTAINER_TYPE_NAME };

/**
 * Компонент ContainerType.
 *
 * @param {object} props - Пропсы компонента.
 * @param {boolean} [props.dark] - Флаг для переключения темной темы.
 * @param {boolean} [props.disabled] - Флаг для отключения компонента.
 * @param {object} [props.otherProps] - Дополнительные пропсы, передаваемые в компонент `ContainerType`.
 *
 * @returns {JSX.Element}
 */
export const ContainerTypeFF = ({
  dark = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Field name={CONTAINER_TYPE_NAME}>
      {(props) => {
        return (
          <ContainerType
            {...{ dark, disabled }}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
