import 'fixed-data-table-2/dist/fixed-data-table.min.css';
import './Table.scss';

import { Cell, Column, Table } from 'fixed-data-table-2';
import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { setTableParameters } from '../../../actions/tableParamsActions';
import Pagination from './Pagination';
import SortingColumn from './SortingColumn';
import SummaryCell from './SummaryCell';

/**
 * Grid.
 *
 * @returns {JSX.Element}
 */
class Grid extends React.Component {

  /**
   * GetRowCount.
   *
   * @returns {number}
   */
  getRowCount = () => {
    const table = this.props.table;
    return !table ||
      !table.columns ||
      table.columns.length === 0 ||
      !table.columns[0].cells
      ? 0
      : table.columns[0].cells.length;
  };

  /**
   * Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    if (!this.props.table) {
      return <div />;
    }

    const defaultHeight = 50;

    const {
      id,
      table,
      orderBy,
      sortDirection,
      dataTransformer,
      width,
      height,
      rowDoubleClick,
      widths,
      headerHeight,
    } = this.props;
    const {
      checkboxes,
      radiobuttons,
      onSelectRow,
      selected,
      onSort,
      onLimit,
      onPagination,
      header,
      rowHeight,
      shortSummary,
    } = this.props;

    return (
      <div className="table-container">
        <Table
          footerHeight={shortSummary ? defaultHeight : 0}
          groupHeaderHeight={defaultHeight}
          headerHeight={headerHeight || defaultHeight}
          height={height || 500}
          id={id}
          rowHeight={rowHeight || defaultHeight}
          rowsCount={this.getRowCount()}
          width={width || 500}
          onRowDoubleClick={rowDoubleClick}
        >
          {radiobuttons && (
            <Column
              align="center"
              cell={
                <RadioButtonCell {...radiobuttons} columns={table.columns} />
              }
              fixed={true}
              width={50}
            />
          )}
          {checkboxes && (
            <Column
              align="center"
              cell={<CheckboxCell {...checkboxes} columns={table.columns} />}
              fixed={true}
              width={50}
            />
          )}
          {header
            ? header(this.props)
            : table.columns.map((column) => {
                const direction =
                  orderBy === column.databaseName ? sortDirection : null;
                const { key, ...columnProps } = column;
                return (
                  <Column
                    align="center"
                    cell={
                      <MyCell
                        key={key}
                        dataTransformer={dataTransformer}
                        {...columnProps}
                        columns={table.columns}
                        selected={selected}
                        onSelectRow={onSelectRow}
                      />
                    }
                    columnKey={column.key}
                    footer={
                      shortSummary && (
                        <SummaryCell
                          className="summary-cell"
                          column={column}
                          height={50}
                        />
                      )
                    }
                    header={
                      <SortingColumn
                        key={key}
                        handleOnClick={(values) => {
                          if (onSort) {
                            onSort(values);
                          } else {
                            this.props.dispatch(setTableParameters(id, values));
                            this.props.dispatch(submit(id));
                          }
                        }}
                        {...columnProps}
                        sortDirection={direction}
                      />
                    }
                    key={column.key}
                    width={widths(column.screenName)}
                  />
                );
              })}
        </Table>
        {this.props.pagination && (
          <Pagination
            id={id}
            paginationData={this.props.pagination}
            onLimit={onLimit}
            onPagination={onPagination}
          />
        )}
      </div>
    );
  }
}

/**
 * GetRowByIndex.
 *
 * @param {number} index - Index.
 * @param {object} columns - Columns.
 * @returns {object}
 */
function getRowByIndex(index, columns) {
  return columns.reduce((result, column) => {
    result[column.screenName] = column.cells[index];
    return result;
  }, {});
}

/**
 * RadioButtonCell.
 *
 * @param {object} props - Props.
 * @returns {JSX.Element}
 */
export function RadioButtonCell(props) {
  const { rowIndex, onSelect, columns } = props;
  return (
    <Cell rowIndex={rowIndex} {...props}>
      <input
        name="table-group"
        type="radio"
        onChange={(event) => {
          const row = getRowByIndex(rowIndex, columns);
          if (event.target.checked) {
            onSelect(row);
          }
        }}
      />
    </Cell>
  );
}

/**
 * CheckboxCell.
 *
 * @param {object} props - Props.
 * @returns {JSX.Element}
 */
export function CheckboxCell(props) {
  const { rowIndex, onSelect, onDeselect, columns, value } = props;
  return (
    <Cell rowIndex={rowIndex} {...props}>
      <input
        checked={value && value(getRowByIndex(rowIndex, columns))}
        type="checkbox"
        onChange={(event) => {
          const row = getRowByIndex(rowIndex, columns);
          if (event.target.checked) {
            onSelect(row);
          } else {
            onDeselect(row);
          }
        }}
      />
    </Cell>
  );
}

/**
 * MyCell.
 *
 * @param {object} props - Props.
 * @returns {JSX.Element}
 */
export function MyCell(props) {
  const { columns, onSelectRow, dataTransformer, sortable, ...cellProps } =
    props;
  const { rowIndex, cells, columnKey, selected } = props;

  const sortableAttribute = {
    ...(sortable === false && { sortable: 'false' }),
  };

  let value = cells[rowIndex] ? cells[rowIndex] : '';
  value = value.value ? value.value : value;
  return (
    <Cell
      {...sortableAttribute}
      {...cellProps}
      className={selected && rowIndex === selected.rowIndex ? 'selected' : ''}
      style={{ cursor: onSelectRow ? 'pointer' : 'default' }}
      onClick={() => {
        if (onSelectRow) {
          onSelectRow({
            row: getRowByIndex(rowIndex, columns),
            rowIndex,
          });
        }
      }}
    >
      {dataTransformer
        ? dataTransformer(columnKey, value, {
            row: getRowByIndex(rowIndex, columns),
            rowIndex,
          })
        : value}
    </Cell>
  );
}

export default connect()(Grid);
