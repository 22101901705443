import { WORK_END_DATE } from 'app/components/card/ogh/OrdersTab/components/WorkEndDate';
import { WORK_START_DATE } from 'app/components/card/ogh/OrdersTab/components/WorkStartDate';
import { Button } from 'core/uiKit/components';
import { mount, shallow } from 'enzyme';
import React from 'react';
import { Form } from 'react-final-form';
import { vi } from 'vitest';

import SearchForm from './SearchForm';

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('🐛 компонент SearchForm', () => {
  const defaultProps = {
    dark: true,
    initialValues: {
      [WORK_END_DATE]: '2023-10-01',
      [WORK_START_DATE]: '2023-09-01',
      objectId: '123',
    },
    onSubmit: vi.fn(),
  };

  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<SearchForm {...defaultProps} />).dive();

    // ❓ Assert
    expect(wrapper.exists()).toBe(true);
  });

  it('🧪 рендерится с пропсами', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = mount(<SearchForm {...defaultProps} />);

    // ❓ Assert
    expect(wrapper.find(Form).exists()).toBe(true);
    expect(wrapper.find(Form).prop('initialValues')).toEqual(
      defaultProps.initialValues,
    );
  });

  it('🧪 вызывает onSubmit при отправке формы', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = mount(<SearchForm {...defaultProps} />);
    const form = wrapper.find('form');
    form.simulate('submit', { preventDefault: vi.fn() });

    // ❓ Assert
    expect(defaultProps.onSubmit).toHaveBeenCalled();
  });

  it('🧪 сбрасывает форму при нажатии на кнопку "Сбросить"', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = mount(<SearchForm {...defaultProps} />);
    const resetButton = wrapper.find(Button).at(1);
    resetButton.simulate('click');
    const form = wrapper.find(Form);

    // ❓ Assert
    expect(form.prop('initialValues')).toEqual(defaultProps.initialValues);
  });

  it('🧪 отображает компоненты WorkStartDate, WorkEndDate и ProgressStatusFF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = mount(<SearchForm {...defaultProps} />);

    // ❓ Assert
    expect(wrapper.find('WorkStartDate').exists()).toBe(true);
    expect(wrapper.find('WorkEndDate').exists()).toBe(true);
    expect(wrapper.find('ProgressStatusFF').exists()).toBe(true);
  });
});
