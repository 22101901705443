import { vi } from 'vitest';

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();

  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => Component),
  };
});

vi.mock('react-redux', () => ({
  connect: vi.fn(
    (mapStateToProps, mapDispatchToProps) => (Component) => (props) =>
      <Component {...mapStateToProps} {...mapDispatchToProps} {...props} />,
  ),
}));

import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useNavigateRouter } from 'core/utils/router';
import { shallow } from 'enzyme';
import { useLocation, useParams } from 'react-router-dom';

import OdhCard from './view';

vi.mock('react-router-dom');
vi.mock('core/utils/router');
vi.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');

describe('🐛 ViewSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    useLocation.mockReturnValue({});
    useParams.mockReturnValue({
      childId: 'create',
      id: 'id',
      typeId: 'typeId',
    });
    useNavigateRouter.mockReturnValue({});
    useIsCurrentObjectIsExternalSystem.mockReturnValue(true);
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<OdhCard />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<MapProvider />');
  });
});
