import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { useSetupMaQuantityCharacteristicsField } from 'app/components/card/ogh/LittleForm/components/MafType/fields/MaQuantityCharacteristics.Field/useSetup.MaQuantityCharacteristics.Field';
import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { useMafTypeLevel1Options } from '../MafTypeLevel1Id.Field/useMafTypeLevel1Options';

vi.mock('../MafTypeLevel1Id.Field/useMafTypeLevel1Options');
vi.mock('app/actions/odsObjectActions/useFormValuesCardRF');

describe('🐛 UseSetupMaQuantityCharacteristicsFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useMafTypeLevel1Options.mockReturnValue([]);
    useFormValuesCardRF.mockReturnValue({
      maf_type_level1_id: RANDOM_ID,
    });

    // 🔥 Act
    const res = useSetupMaQuantityCharacteristicsField();

    // ❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
      label: 'Количество мест, шт.',
      options: [],
      required: false,
    });
  });

  it('🧪 options', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useMafTypeLevel1Options.mockReturnValue([
      {
        id: RANDOM_ID,
        quantity_name: RANDOM_WORD,
      },
      [{ id: 1 }],
    ]);
    useFormValuesCardRF.mockReturnValue({
      maf_type_level1_id: RANDOM_ID,
    });

    // 🔥 Act
    const res = useSetupMaQuantityCharacteristicsField();

    // ❓ Assert
    expect(res).toStrictEqual({
      disabled: false,
      label: RANDOM_WORD,
      options: [
        {
          id: RANDOM_ID,
          quantity_name: RANDOM_WORD,
        },
        [{ id: 1 }],
      ],
      required: true,
    });
  });
});
