import './style.scss';

import FieldLabel from 'core/components/FieldLabel';
import React from 'react';
import { Field } from 'redux-form';

import EmptyField from './EmptyField';

/**
 * Функция расчета сетки.
 *
 * @param {number} numberOfColumns - Количество колонок.
 * @returns {{marginRight: string, width: string, minWidth: string, marginLeft: string}}
 */
const defaultFieldStyle = (numberOfColumns) => ({
  marginLeft: '24px',
  marginRight: '24px',
  minWidth: '256px',
  width: `calc(${100 / numberOfColumns}% - 48px)`,
});

const fieldGroupStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '2rem', // На время
};

/**
 * Функция рендера сетки в карточке.
 *
 * @param {Array} elements - Массив инпутов .
 * @param {number} numberOfColumns - Количество колонок.
 * @param {string} title - Название секции.
 * @param {number} cardTypeId - Id типа карточки.
 * @param {object} options - Опции.
 * @returns {*} - Жопа.
 * @example
 * в любой карточке огх
 */
export default function renderElementFormGrid(
  elements,
  numberOfColumns,
  title = '',
  cardTypeId = null,
  options,
) {
  const params = { cardTypeId, elements, numberOfColumns, options, title };
  return isShowElements(params) ? renderElements(params) : renderEmptyField();
}

/**
 * Проверка показа элементов.
 *
 * @param {object} props - Props.
 * @param {object} props.elements - Элементы.
 * @param {number} props.cardTypeId - Id типа карточки.
 * @returns {boolean} - Жопа.
 * @example
 * isShowElements(params)
 */
export function isShowElements({ elements, cardTypeId }) {
  return (
    elements &&
    elements.some((item) => item && isShow(item.allowedTypes, cardTypeId))
  );
}

/**
 * Проверка показа элемента.
 *
 * @param {Array} allowedTypes - Разрешенные типы.
 * @param {number|string} currentType - Текущий тип.
 * @returns {boolean} - Жопа.
 * @example
 * isShow(item.allowedTypes, cardTypeId)
 */
export function isShow(allowedTypes, currentType) {
  return (
    allowedTypes === undefined ||
    allowedTypes.indexOf(parseInt(currentType, 10)) !== -1
  );
}

/**
 * Рендер пустого филда.
 *
 * @param {*} key - Ключ.
 * @returns {JSX.Element} - JSX.
 * @example
 * renderEmptyField();
 */
export function renderEmptyField(key) {
  return <EmptyField key={`${key}-empty`} />;
}

/**
 * Рендер филда.
 *
 * @param {object} params - Параметры.
 * @returns {JSX.Element} - JSX.
 * @example
 * renderElements(params)
 */
export function renderElements(params) {
  const { title, elements, options = {} } = params;
  return (
    <div style={fieldGroupStyle}>
      {title ? (
        <FieldLabel
          className="ods-grid-element__header"
          required={options.required}
        >
          {title}
        </FieldLabel>
      ) : null}
      {elements.map((item, index) => (
        <div key={item.id || index}>{renderItem(item, index, params)}</div>
      ))}
      <div className="ods-grid-element__clearing" />
    </div>
  );
}

/**
 * Рендер Item.
 *
 * @param {object} item - Item.
 * @param {number|string} index - Индекс.
 * @param {object} props - Props.
 * @param {number} props.cardTypeId - Id типа карточки.
 * @param {number} props.numberOfColumns - Количество колонок.
 * @returns {*} - Жопа.
 * @example
 * renderItem(item, index, params))}
 */
export function renderItem(item, index, { cardTypeId, numberOfColumns }) {
  let result;
  if (item) {
    // отслеживаем реакт комп
    if (item.$$typeof) {
      return {
        ...item,
        props: {
          ...item.props,
          style: {
            ...defaultFieldStyle(numberOfColumns),
            ...item.props.style,
          },
        },
      };
    }

    const options = {
      addition: 'addition' in item ? item.addition : {},
      defaultValue:
        'defaultValue' in item
          ? {
              defaultValue: item.defaultValue,
              value: item.defaultValue,
            }
          : {},
      style: {
        ...defaultFieldStyle(numberOfColumns),
        ...item.style,
      },
      ...item.props,
    };

    if (isShow(item.allowedTypes, cardTypeId)) {
      result = item.formValue
        ? renderField(item, options)
        : renderFixedField(item, options);
    } else {
      result = renderEmptyField(item.id);
    }
  } else {
    result = renderEmptyField(index);
  }
  return result;
}

/**
 * Рендер Field.
 *
 * @param {object} item - Item.
 * @param {object} props - Props.
 * @param {object} props.addition - Addition.
 * @param {object} props.defaultValue - Default value.
 * @param {object} props.style - Style.
 * @param {object} props.rest - Other props.
 * @returns {JSX.Element} - JSX.
 */
export function renderField(item, { addition, defaultValue, style, ...rest }) {
  return addition.noWrapper ? (
    <Field
      component={item.component}
      id={item.id}
      key={item.id}
      name={item.name}
      fullWidth
      {...addition}
      {...defaultValue}
      {...rest}
      disabled={item.disabled || !item.editable}
      label={item.label || addition.label}
    />
  ) : (
    <div style={style}>
      <Field
        component={item.component}
        id={item.id}
        key={item.id}
        name={item.name}
        fullWidth
        {...addition}
        {...defaultValue}
        {...rest}
        disabled={item.disabled || !item.editable}
        label={item.label || addition.label}
      />
    </div>
  );
}

/**
 * Рендер FixedField.
 *
 * @param {object} item - Item.
 * @param {object} props - Props.
 * @param {object} props.addition - Addition.
 * @param {*} props.defaultValue - Default value.
 * @param {string} props.style - Style.
 * @param {object} props.rest - Rest.
 * @returns {JSX.Element} - JSX.
 * @example
 * renderFixedField(item, options);
 */
export function renderFixedField(
  item,
  { addition, defaultValue, style, ...rest },
) {
  return (
    <div style={style}>
      <FixedField
        component={item.component}
        id={item.id}
        key={`${item.name}-${item.id}`}
        name={item.name}
        fullWidth
        {...addition}
        {...defaultValue}
        {...rest}
        disabled={item.disabled || !item.editable}
        label={item.label || addition.label}
      />
    </div>
  );
}

/**
 * Фиксированный Field.
 *
 * @param {object} props - Props.
 * @returns {JSX.Element} - JSX.
 * @example
 * <FixedField
 *       component={item.component}
 *       id={item.id}/>
 */
export function FixedField(props) {
  const Component = props.component;
  const { name, value, onChange = null, ...params } = props;

  const input = {
    name,
    onChange,
    value,
  };

  return <Component input={input} {...params} />;
}
