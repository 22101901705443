import { formatResponseToGeoJSON } from 'app/actions/geometryActions/fetchCheckGeometryIntersections.DTW';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { fetchCancelEdit } from 'app/api/ogh';
import { useGeometryMapCurrent } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGeometryMapCurrent';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import {
  CREATE_CARD,
  CREATE_FROM_PARENT,
  UPDATE_CARD,
  UPDATE_FROM_PARENT,
  useTypeActionCard,
} from 'app/pages/cardsOgh/hooks/useTypeActionCard';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import { useMapglEditorContext } from 'core/uiKit/components/DTW/contexts/useMapglEditorContext';
import { formatGeometryObjectToUpdate } from 'core/uiKit/components/DTW/contexts/utils';
import {
  DrawGeometryObjects,
  Layers,
  ResponseGeometryObjects,
} from 'core/uiKit/components/DTW/contexts/utils/types';
import { toast } from 'core/uiKit/components/Toast';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Общий хук для Кнопки Отмены .
 *
 * @param resetForm - Функция сброса формы.
 * @returns JSX.
 */
export const useSetupCancelButtonDTW = (resetForm: () => void) => {
  const { editMode, clearEditMode } = useMode();
  const [dialog, setDialog] = useState(false);
  const { rootId, recordId } = useGetCurrentCard();
  const navigate = useNavigate();
  const type = useTypeActionCard();

  const { mapService, cancelEditing } = useMapglEditorContext();
  const { dataMapCurrent } = useGeometryMapCurrent();

  const { actions, childId, id, oghRegistry } = useGetParamsUrl();

  /**
   * Назад в реестры.
   *
   * @returns {void}
   */
  const goBackToRegistry = (): void => {
    const urlBack = [
      pathRegistry.path,
      pathOgh.path,
      oghRegistry?.parent ?? '',
      oghRegistry?.child ?? '',
    ]
      .filter(Boolean)
      .join('/');

    navigate('/' + urlBack);
  };

  /**
   * Функция для форматирования геометрий.
   *
   * @param {object} geometry - Object.
   * @returns {any}
   */
  const formatGeometryToDraw = (
    geometry: ResponseGeometryObjects,
  ): Partial<Record<Layers, DrawGeometryObjects>> | undefined => {
    if (!mapService?.isMapLoaded) return;

    const layerType = isParent ? 'parent' : 'children';
    const data: Partial<Record<Layers, DrawGeometryObjects>> = {
      [layerType]: formatGeometryObjectToUpdate(
        geometry,
        layerType,
        mapService,
      ),
    };

    if (!actions.haveParent && geometry.child_object) {
      data.children = (geometry.child_object
        ?.map(formatGeometriesToDraw)
        .filter(Boolean) || []) as unknown as ReturnType<
        typeof formatGeometryObjectToUpdate
      >;
    }

    return data;
  };

  /**
   * Функция для форматирования геометрий.
   *
   * @param geometries - Object.
   * @returns Data.
   */
  const formatGeometriesToDraw = (
    geometries: ResponseGeometryObjects[] | ResponseGeometryObjects,
  ) => {
    return Array.isArray(geometries)
      ? geometries.map((geometry) => formatGeometryToDraw(geometry)).flat(1)
      : [formatGeometryToDraw(geometries)];
  };

  const isParent = !childId;

  const [sendDialog, { isLoading }] = useMutationAdaptor(
    () => fetchCancelEdit({ recordId, rootId }),
    {
      //

      /**
       * Функция для обработки ошибки.
       *
       * @param {object} error - Error.
       * @param {string} error.message - Message Error.
       * @returns {void}
       */
      onError(error?: { message?: string }): void {
        toast.errorDyingLong(
          error?.message
            ? error.message
            : 'Произошла ошибка при удалении паспорта',
        );
      },

      /**
       * Функция при успешной обработки.
       *
       */
      onSuccess() {
        //

        /**
         * Функция для отрисовки геометрий.
         *
         */
        const drawCurrentObject = () => {
          cancelEditing();

          const [data] =
            // @ts-ignore
            formatGeometriesToDraw(formatResponseToGeoJSON(dataMapCurrent)) ||
            [];
          if (!mapService) return;

          const currentLoadedGeometries = { ...mapService.geometriesData };

          const newLoadedGeometries = mapService.updateGeometriesData(
            data || {},
          );
          mapService.drawGeometries({
            method: 'replaceAll',
            newGeometries: {
              ...currentLoadedGeometries,
              children: isParent
                ? { point: [], polygon: [], polyline: [] }
                : newLoadedGeometries.children,
            },
          });
        };

        switch (type) {
          case UPDATE_FROM_PARENT: {
            navigate(`/ogh/${id}/${childId}`);
            cancelEditing();

            drawCurrentObject();
            break;
          }
          case CREATE_FROM_PARENT: {
            navigate(`/ogh/${id}`);
            break;
          }
          case CREATE_CARD: {
            goBackToRegistry();
            break;
          }
          case UPDATE_CARD: {
            cancelEditing();

            drawCurrentObject();
            break;
          }
          default:
            break;
        }

        setDialog(false);
        resetForm();
        clearEditMode();
      },
    },
  );

  /**
   * Function close Dialog.
   *
   */
  const closeDialog = () => {
    setDialog(false);
  };

  /**
   * Handler click button.
   *
   */
  const handleCancelButtonClick = () => {
    setDialog(true);
  };

  return {
    closeDialog,
    dialog,
    editMode,
    handleCancelButtonClick,
    isLoading,
    sendDialog,
  };
};
