import { Cell } from 'types/table';

/**
 * Колонка __Признак «Каток открытый с естественным льдом на существующей спортивной площадке спортивного типа»__.
 *
 * @type {{
 * accessor: 'ice_rink_planar_structure',
 * Header: 'Признак «Каток открытый с естественным льдом на существующей спортивной площадке спортивного типа»',
 * }}
 * @augments Cell
 */
export const ice_rink_planar_structure: Cell = {
  Header:
    'Признак «Каток открытый с естественным льдом на существующей спортивной площадке спортивного типа»',
  accessor: 'ice_rink_planar_structure',
};
