import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useNavigate } from 'react-router-dom';

/**
 * Хук дающий функцию навигации с сайд эффектом loading.
 *
 * @returns Возвращает функцию навигации.
 */
export const useNavigateWithLoading = () => {
  const navigate = useNavigate();
  const { startLoading } = useMode();

  /**
   * Функция навигации.
   *
   * @param url - Адрес по которому переходим.
   */
  const navigateWithLoading = (url: string) => {
    startLoading();
    setTimeout(() => {
      navigate(url);
    }, 0);
  };

  return { navigateWithLoading };
};
