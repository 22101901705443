import { Coordinate } from 'ol/coordinate';
import { useCallback } from 'react';

import { getGeoJSONCoordinatesFormatterToMsk77 } from '../utils/getGeoJSONCoordinatesFormatter';
import { MapService } from '../utils/MapService';
import { GeometryToDraw, GeometryTypes } from '../utils/types.d';

type ValueOf<Type> = Type[keyof Type];

/**
 * Get geometries.
 *
 * @param mapService - Map service.
 * @param recordId - Record id.
 * @returns Object.
 */
export const useGetGeometries = (
  mapService: MapService | null,
  recordId?: number | string,
) =>
  useCallback(
    (oghObjectId?: number | string) => {
      if (mapService?.isGroupSelected) return {};

      const recordIdStr = `${oghObjectId || recordId || ''}`;

      const children = mapService?.geometriesData?.children || {
        point: [],
        polygon: [],
        polyline: [],
      };

      const parent = mapService?.geometriesData?.parent || {
        point: [],
        polygon: [],
        polyline: [],
      };

      const geometry = [
        ...children.polygon,
        ...children.polyline,
        ...children.point,
        ...parent.polygon,
        ...parent.polyline,
        ...parent.point,
      ].filter((geometry) => {
        if (!geometry?.options?.userData) {
          return false;
        }
        return `${geometry.options.userData.oghObjectId ?? ''}` === recordIdStr;
      });

      if (!geometry?.length) return {};

      const geometriesByTypes = geometry.reduce((acc, _geometry) => {
        if (!acc[_geometry.type]) {
          acc[_geometry.type] = [];
        }
        acc[_geometry.type].push(_geometry);
        return acc;
      }, {} as { [key: string]: (GeometryToDraw<GeometryTypes.Point> | GeometryToDraw<GeometryTypes.Polygon> | GeometryToDraw<GeometryTypes.Polyline>)[] });

      /**
       * Определяет геотип геометрии.
       *
       * @param type - Тип геометрии.
       * @param geometries - Объект с геометриями.
       * @returns String.
       */
      const getGeoJsonType = (
        type: string,
        geometries: ValueOf<typeof geometriesByTypes>,
      ) => {
        const isMultiType = geometries.length > 1;
        let geoJsonType: string = type;

        if (isMultiType) {
          geoJsonType =
            type === 'polyline'
              ? 'MultiLineString'
              : type === 'polygon'
              ? 'MultiPolygon'
              : 'MultiPoint';
        } else {
          geoJsonType =
            type === 'polyline'
              ? 'LineString'
              : type === 'polygon'
              ? 'Polygon'
              : 'Point';
        }

        return geoJsonType;
      };

      const geometriesWithGeoJsonType = Object.entries(
        geometriesByTypes,
      ).reduce((acc, [type, geometries]) => {
        const isMultiType = geometries.length > 1;
        const geoJsonType = getGeoJsonType(type, geometries);
        const fieldType = `${type === 'polyline' ? 'line' : type}s`;

        if (!acc[fieldType]) {
          acc[fieldType] = { coordinates: [], type: geoJsonType };
        }

        const formatter = getGeoJSONCoordinatesFormatterToMsk77(
          type as
            | GeometryTypes.Polyline
            | GeometryTypes.Polygon
            | GeometryTypes.Point,
        );

        acc[fieldType] = {
          coordinates: isMultiType
            ? [
                ...geometries.map((geometry) =>
                  formatter(
                    // Берем координаты из геометрии с корректным типом, но тс не распознает их.
                    geometry.options.coordinates,
                  ),
                ),
              ]
            : formatter(
                // Берем координаты из геометрии с корректным типом, но тс не распознает их.
                geometries[0].options.coordinates,
              ),
          type: geoJsonType,
        };
        return acc;
      }, {} as { [key: string]: { coordinates: Coordinate | (Coordinate | Coordinate[] | Coordinate[][])[]; type: string } });

      return geometriesWithGeoJsonType;
      // return {
      //   [`${ type === 'polyline' ? 'line' : type } s`]: {
      //     coordinates: isMultiType
      //       ? [
      //           ...geometry.map((geometry) =>
      //             GeoJSONCoordinatesFormatter[`${ type } ToMsk77`](
      //               // Берем координаты из геометрии с корректным типом, но тс не распознает их.
      //               // @ts-expect-error
      //               geometry.options.coordinates,
      //             ),
      //           ),
      //         ]
      //       : GeoJSONCoordinatesFormatter[`${ type } ToMsk77`](
      //           // Берем координаты из геометрии с корректным типом, но тс не распознает их.
      //           // @ts-expect-error
      //           geometry[0].options.coordinates,
      //         ),
      //     type: geoJsonType,
      //   },
      // };
    },

    [recordId, mapService],
  );
