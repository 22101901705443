import {
  AutocompleteMUI,
  FormControl,
  TextFieldMUI,
} from 'core/uiKit/material-ui';
import React from 'react';

import { useSetupAutocomplete } from './useSetup.Autocomplete';

/**
 * Автокомплит.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.className - Класс.
 * @param {string} props.name - Имя.
 * @param {string} props.placeholder - Плейсхолдер.
 * @param {boolean} props.dark - Флаг переключения темной темы.
 * @param {boolean} props.disabled - Флаг активности редактирования.
 * @param {string} props.errorText - Текст ошибки.
 * @param {string} props.helperText - Вспомогательные текст.
 * @param {string} props.label - Лейбл.
 * @param {object} props.meta - Мета данные.
 * @param {Function} props.onChange - Функция изменения.
 * @param {string} props.optionKey - Any.
 * @param {string} props.optionName - Any.
 * @param {Array} props.options - Опшины.
 * @param {boolean} props.required - Флаг обязательности поля.
 * @param {string} props.value - Значение поля.
 * @param {string} props.variant - Вариант стилизации поля.
 * @returns {JSX.Element}
 */
export const Autocomplete = ({
  className,
  dark = false,
  disabled = false,
  errorText = '',
  helperText = '',
  label = 'Autocomplete label',
  name = 'autocomplete',
  meta = {},
  onChange = (_) => _,
  optionKey = 'id',
  optionName = 'name',
  options = [],
  placeholder = '',
  required = false,
  value = '',
  variant = 'standard',
  ...otherProps
}) => {
  const { getByName, handleChange, isError, item, optionsFiltered } =
    useSetupAutocomplete({
      errorText,
      meta,
      onChange,
      optionKey,
      optionName,
      options,
      value,
    });

  const darkProp = {
    ...(dark && { dark: true }),
    ...(dark === false && { dark: 'false' }),
  };

  return (
    <FormControl
      {...{
        className,
        style: { width: '100%' },
      }}
      disabled={disabled}
      error={isError}
      required={required}
    >
      <AutocompleteMUI
        {...darkProp}
        {...{ disabled, name, placeholder, style: { width: '100%' } }}
        {...otherProps}
        noOptionsText={'не найдено'}
        disabled={disabled}
        getOptionLabel={(option) => getByName(option)}
        options={optionsFiltered || []}
        renderInput={(params) => (
          <TextFieldMUI
            {...params}
            error={isError}
            variant={variant}
            label={label}
            helperText={errorText || helperText || <span>&nbsp;</span>}
          />
        )}
        value={item}
        onChange={handleChange}
      />
    </FormControl>
  );
};
