import {
  OLD_PASSWORD_NAME,
  OldPassword,
} from 'core/uiKit/preparedInputs/textField/OldPassword';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент OldPasswordFF.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Флаг, указывающий, должно ли поле быть заблокировано.
 * @param {object} [props.otherProps] - Дополнительные пропсы.
 * @returns {JSX.Element}
 */
export const OldPasswordFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={OLD_PASSWORD_NAME}>
      {({ input: { name, value, onChange }, meta }) => {
        return (
          <OldPassword
            disabled={disabled}
            {...otherProps}
            {...{ name, onChange, value }}
            {...{ meta }}
          />
        );
      }}
    </Field>
  );
};

export { OLD_PASSWORD_NAME };
