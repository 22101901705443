import { GRBS_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';
import { Field } from 'redux-form';

import { GrbsIdRFWithReduxForm } from './GrbsIdRFWithReduxForm';

/**
 * Select React Form. Учредитель/ГРБС использует словарь grbs.
 *
 * @param {object} params - Parameters.
 * @param {boolean} [params.disabled] - Disabled.
 * @param {object} params.otherProps - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const GrbsIdRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      name={GRBS_ID_NAME}
      component={GrbsIdRFWithReduxForm}
    />
  );
};
