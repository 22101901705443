import { BodyContext, UserContext } from 'app/pages/providers';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import React, { useContext } from 'react';

/**
 * Кнопка открывает диалоговое окно создания Огх содержит в себе логику показа согласно доступам.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.typeCode - Код.
 * @returns {JSX.Element}
 */
export const AddButtonOgh = ({ typeCode }) => {
  const { toggleShowDialogCreate } = useContext(BodyContext);
  const { hasPermission } = useContext(UserContext);

  const thereIsAccessToAdd = hasPermission(`create_ogh_${typeCode}`);

  return thereIsAccessToAdd ? (
    <>
      <Button
        color="success"
        startIcon={<Icon>library_add</Icon>}
        variant={'contained'}
        onClick={() => toggleShowDialogCreate(true)}
      >
        Добавить
      </Button>
    </>
  ) : null;
};
