import { useMapContext } from 'app/components/map/useMapContext';
import { getLengthGeometry } from 'app/pages/cardsOgh/api/helpers/getLengthGeometry';
import { postIntersection } from 'app/pages/cardsOgh/api/postCheckIntersections';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import { createIntersectionsMessage } from 'app/utils/card/checkGeometry/helpers/createIntersectionsMessage';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук на получение функции на проверку пересечений.
 *
 * @param {object} params - Параметры хука.
 * @param {Function} params.showAlert - Функция отображения сообщения.
 * @param {Function} params.setHasIntersections - Функция устанавливающая состояния активного пересечения.
 * @param {Function} params.endPreload - Функция завершения состояния загрузки.
 * @returns {object}
 */
export const useIntersections = ({
  showAlert,
  setHasIntersections,
  endPreload,
} = {}) => {
  const { drawIntersectionsGeometry } = useMapContext();
  const [checkIntersection, data] = useMutationAdaptor(postIntersection, {

    /**
     * OnError.
     *
     * @param {object} root0 - Error.
     * @param {string} root0.message - Message Error.
     */
    onError: ({ message = 'Неизвестная ошибка' }) => {
      endPreload();
      showAlert(message);
    },

    /**
     * OnSuccess.
     *
     * @param {object} response - Ответ.
     * @returns {void}
     */
    onSuccess: (response) => {
      const length = getLengthGeometry(response);
      if (length) {
        showAlert(
          createIntersectionsMessage(
            response.geometry,
            message.errorIntersections,
          ),
        );
        drawIntersectionsGeometry(response.geometry);
        setHasIntersections(response.intersected);
      } else {
        showAlert(message.success);
      }
      endPreload();
    },
  });

  return { checkIntersection, ...data };
};
