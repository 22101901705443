import { formatResponseToGeoJSON } from 'app/actions/geometryActions/fetchCheckGeometryIntersections.DTW';
import { useMapglEditorContext } from 'core/uiKit/components/DTW/contexts/useMapglEditorContext';
import { formatGeometriesToUpdate } from 'core/uiKit/components/DTW/contexts/utils';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

import { useNavigateWithLoading } from './useNavigateWithLoading';

/**
 * Хук получения функции процесса сохранения карточки DTW.
 *
 * @param {object} props - Пропсы.
 * @returns {Function} - Функция процесса сохранения карточки DTW.
 */
export const useFetchActionWithGeometryDTW = (props) => {
  const { finishEditing, mapService, updateLoadedGeometries, drawGeometries } =
    useMapglEditorContext();
  const { navigateWithLoading } = useNavigateWithLoading();
  const { childId, parentId } = useGetParamsUrl();

  /**
   * Функция процесса сохранения карточки DTW.
   *
   * @param {number} id - Id паспорта.
   * @returns {undefined}
   */
  const fetchActionWithGeometryDTW = (id) => {
    if (!id) return;

    if (childId) {
      navigateWithLoading(`/ogh/${parentId}/${id}`);

      props.fetchTreeSelectedElement({ id });
      props.fetchObjectsGeometry({ ids: [id] }).then((json) => {
        finishEditing(false);

        const layer = 'children';
        const geoJson = formatResponseToGeoJSON(json)[0];
        const formatted = formatGeometriesToUpdate(geoJson, layer, mapService);
        const updated = updateLoadedGeometries({
          [layer]: formatted,
        });
        drawGeometries(updated);

        props.viewCard();
      });
      return;
    }

    props.fetchRootCard(id).then((isSuccess) => {
      if (isSuccess) {
        finishEditing();
      }
      navigateWithLoading(`/ogh/${id}`);
    });
  };

  return {
    fetchActionWithGeometryDTW,
  };
};
