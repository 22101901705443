import './SortingColumn.scss';

import React from 'react';
import { Link } from 'react-router-dom';

const sortingClass = {
  asc: 'order-down',
  desc: 'order-up',
  null: 'order-none',
};
import { Cell } from 'fixed-data-table-2';

/**
 * SortingColumn.
 *
 * @param {object} props - Пропсы компонента.
 * @param {Function} props.handleOnClick - Функция, вызываемая при клике на заголовок для сортировки. Принимает объект с полями `orderBy` (имя колонки) и `sortDirection` (направление сортировки).
 * @param {string} props.screenName - Название колонки, которое отображается в заголовке.
 * @param {string} props.databaseName - Имя колонки в базе данных, используется для сортировки.
 * @param {string} props.sortDirection - Направление сортировки для колонки, может быть `'asc'` или `'desc'`.
 * @param {boolean} [props.sortable] - Флаг, указывающий, можно ли сортировать по этой колонке.
 * @param {Function} [props.cells] - Массив данных для ячеек в колонке. Обычно передается из родительского компонента.
 * @param {object} [props.cellProps] - Дополнительные пропсы для компонента `Cell`.
 *
 * @returns {JSX.Element}
 */
export default function SortingColumn(props) {
  const {
    handleOnClick,
    screenName,
    databaseName,
    sortDirection,
    sortable,
    cells,
    ...cellProps
  } = props;

  const cellAttributes = {
    className: `${sortingClass[sortDirection]} sorting-column`,
    ...{ cells, ...cellProps },
    ...(sortable !== undefined && { sortable: sortable.toString() }),
  };

  return (
    <Cell {...cellAttributes}>
      <Link
        to={'#'}
        onClick={() => {
          handleOnClick({
            orderBy: databaseName,
            sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
          });
        }}
      >
        <span>{screenName}</span>
      </Link>
    </Cell>
  );
}
