import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

import { mapCoatingType } from './mapCoatingType';

export const COATING_TYPE_ID_NAME = 'coating_type_id';

/**
 * Вид покрытия на путях (уточнение).
 *
 * 'type = Select`.
 * `name = coating_type_id`
 * `dict = oghStatuses`.
 *
 * @param {object} props - Properties.
 * @param {string} [props.label] - Label.
 * @param {boolean} [props.dark] - Dark.
 * @param {boolean} [props.disabled] - Disabled.
 * @param {string} [props.errorText] - Error text.
 * @param {Function} [props.filter] - Filter.
 * @param {string} [props.helperText] - Helper text.
 * @param {Function} [props.onChange] - On change.
 * @param {Array} [props.value] - Value.
 * @param {boolean} [props.required] - Required.
 * @param {object} [props.otherProps] - Other properties.
 * @returns {JSX.Element}
 */
export const CoatingTypeId = ({
  label = 'Вид покрытия (уточнение)',
  dark = false,
  disabled = false,
  errorText = '',
  filter = (_) => _,
  helperText = '',
  onChange = (_) => _,
  required = false,
  value = [],
  ...otherProps
}) => {
  const dict = 'coatingType';
  const { data = [] } = useGetDictionary(dict);

  const options = mapCoatingType(data) || [];

  return (
    <Select
      {...{
        dark,
        disabled,
        errorText,
        filter,
        helperText,
        onChange,
        required,
        value,
      }}
      {...otherProps}
      {...{ label, options }}
      name={COATING_TYPE_ID_NAME}
    />
  );
};
