import { formatResponseToGeoJSON } from 'app/actions/geometryActions/fetchCheckGeometryIntersections.DTW';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useGeometryMapCurrent } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGeometryMapCurrent';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useMapglContext } from 'core/uiKit/components/DTW/contexts/useMapglContext';
import { formatGeometryObjectToUpdate } from 'core/uiKit/components/DTW/contexts/utils';
import {
  DrawGeometryObjects,
  Layers,
  ResponseGeometryObjects,
} from 'core/uiKit/components/DTW/contexts/utils/types.d';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

import { useGroup } from '../hooks/useGroup';

interface DrawGeometryParams {
  //

  /**
   * Type Id element.
   */
  typeId: number;
}

/**
 * Хук отрисовки геометрии родитееля и подгруппы элементов.
 *
 * @returns - Функция отрисовки.
 */
export const useDrawGeometryDTW = () => {
  const { mapService } = useMapglContext();

  const parent = useGetParentCard();
  const { dataMapCurrent = [], currentSuccess } = useGeometryMapCurrent();
  const { actions } = useGetParamsUrl();
  const { getGroupGeometry, isLoadingGroupGeometry } = useGroup();
  const currentCard = useGetCurrentCard();
  const { editMode } = useMode();

  /**
   * Подгрузить геометрию группы.
   *
   * @param {DrawGeometryParams} params - Параметры.
   * @returns {void}
   */
  const drawGeometry = async ({
    typeId,
  }: DrawGeometryParams): Promise<void> => {
    if (currentSuccess && !actions.haveParent) {
      //

      /**
       * Функция для форматирования геометрий.
       *
       * @param geometry - Object.
       * @returns Data.
       */
      const formatGeometryToDraw = (geometry: ResponseGeometryObjects) => {
        if (!mapService?.isMapLoaded) return;

        const data: Partial<Record<Layers, DrawGeometryObjects | undefined>> = {
          children: [],
          parent: formatGeometryObjectToUpdate(geometry, 'parent', mapService),
        };

        if (geometry.child_object) {
          data.children = (geometry.child_object
            ?.map(formatGeometriesToDraw)
            .filter(Boolean) || []) as unknown as DrawGeometryObjects;
        }

        return data;
      };

      /**
       * Функция для форматирования геометрий.
       *
       * @param geometries - Object.
       * @returns Data.
       */
      const formatGeometriesToDraw = (
        geometries: ResponseGeometryObjects[] | ResponseGeometryObjects,
      ) => {
        return Array.isArray(geometries)
          ? geometries.map((geometry) => formatGeometryToDraw(geometry)).flat(1)
          : [formatGeometryToDraw(geometries)];
      };
      if (!mapService) return;

      const [data] =
        // @ts-ignore
        formatGeometriesToDraw(formatResponseToGeoJSON(dataMapCurrent)) || [];

      if (data) {
        mapService.updateGeometriesData({
          children: data.children,
          parent: data.parent,
        });
        mapService.drawGeometries({ method: 'replaceAll' });
      }
    }

    if (!editMode && mapService?.isGroupSelected) {
      const id = parent.isSuccessParent
        ? parent.recordId
        : currentCard.recordId;
      await getGroupGeometry({
        id,
        path_type_id: typeId,
        root_id: id,
        type_id: typeId,
      });
    }
  };
  return { drawGeometry, isLoadingGroupGeometry };
};
