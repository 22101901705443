import {
  CONFIRM_PASSWORD_NAME,
  ConfirmPassword,
} from 'core/uiKit/preparedInputs/textField/ConfirmPassword';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент ConfirmPasswordFF.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Флаг, указывающий, должно ли поле быть заблокировано.
 * @param {object} [props.otherProps] - Дополнительные пропсы.
 * @returns {JSX.Element}
 */
export const ConfirmPasswordFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={CONFIRM_PASSWORD_NAME}>
      {({ input: { name, value, onChange }, meta }) => {
        return (
          <ConfirmPassword
            disabled={disabled}
            {...otherProps}
            {...{ name, onChange, value }}
            {...{ meta }}
          />
        );
      }}
    </Field>
  );
};

export { CONFIRM_PASSWORD_NAME };
