import {
  Password,
  PASSWORD_NAME,
} from 'core/uiKit/preparedInputs/textField/Password';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент Password.
 *
 * @param {object} props - Пропсы компонента.
 * @param {boolean} [props.disabled] - Флаг, указывающий, должно ли поле быть заблокировано.
 * @param {object} [props.otherProps] - Дополнительные пропсы, передаваемые в компонент `Password`.
 *
 * @returns {JSX.Element}
 */
export const PasswordFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={PASSWORD_NAME}>
      {({ input: { name, value, onChange }, meta }) => {
        return (
          <Password
            {...{ disabled, ...otherProps }}
            {...{ name, onChange, value }}
            {...{ meta }}
          />
        );
      }}
    </Field>
  );
};
