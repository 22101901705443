import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';

/**
 * Name inputs. Учредитель/ГРБС.
 *
 * @constant
 * @type {string}
 * @default 'grbsId'
 */
export const GRBS_ID_NAME = 'grbs_id';

/**
 * Select Учредитель/ГРБС использует словарь grbs.
 * `name` - grbsId.
 *
 * @param {object} props - Properties.
 * @param {string} [props.label] - Лейбл.
 * @param {object} props.otherProps - Properties.
 * @returns {JSX.Element} - JSX.
 * @example --
 */
export const GrbsId = ({ label = 'ГРБС', ...otherProps }) => {
  const dict = 'grbs';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...otherProps}
      {...{
        label,
        name: GRBS_ID_NAME,
        options: data,
      }}
    />
  );
};
