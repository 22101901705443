import { IceRinkPlanarStructureRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IceRinkPlanarStructure.RF';
import React from 'react';

import { useSetupIceRinkPlanarStructureField } from './useSetup.IceRinkPlanarStructureField';

/**
 * Checkbox Field RF __Каток открытый с естественным льдом на существующей спортивной площадке спортивного типа__.
 *
 * @returns
 */
export const IceRinkPlanarStructureField = () => {
  const { disabled } = useSetupIceRinkPlanarStructureField();

  return <IceRinkPlanarStructureRF disabled={disabled} />;
};
