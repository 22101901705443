import { INTENSITY_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/IntensityNum/index';
import React from 'react';
import { Field } from 'redux-form';

import { IntensityNumWithReduxForm } from './IntensityNumWithReduxForm';

/**
 * Поле __Интенсивность движения RF__.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Disabled.
 * @returns {JSX.Element}
 */
export const IntensityNumRF = ({ disabled = false, ...props }) => {
  return (
    <Field
      disabled={disabled}
      {...props}
      component={IntensityNumWithReduxForm}
      name={INTENSITY_NUM_NAME}
    />
  );
};

export { INTENSITY_NUM_NAME };
