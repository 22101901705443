import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';

import { RED_BOOK_PLANT_ID_NAME } from './RED_BOOK_PLANT_ID_NAME';
import { usePreparationRedBookPlantId } from './usePreparation.RedBookPlantId';

/**
 * ## Вид растения.
 *
 * @param {*} args - The arguments.
 * @param {*} args.input - The input.
 * @param {*} args.props - Properties.
 * @returns {JSX.Element}
 */
export const RedBookPlantId = ({ input = {}, ...props }) => {
  //TODO: вынести в useSetup и логика инпутов должна быть у формы (убрать инпут)
  usePreparationRedBookPlantId();
  const dict = 'redBookPlant';

  const { data = [] } = useGetDictionary(dict);

  const options = data
    .map((item) => ({
      id: item.id,
      name: item.attribute?.name,
    }))
    .filter((item) => Boolean(item.id));

  return (
    <Select
      {...props}
      {...input}
      options={options}
      label={'Вид растения'}
      name={RED_BOOK_PLANT_ID_NAME}
    />
  );
};
