import { useMapContext } from 'app/components/map/useMapContext';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import { createIntersectionsMessage } from 'app/utils/card/checkGeometry/helpers/createIntersectionsMessage';

import { usePreloaderCard } from '../../components/PreloadedCard/usePreloaderCard';

/**
 * Метод обработки ошибки сохранения карточки.
 *
 * @param params - Параметры.
 * @param params.showAlert - Показывает предупреждение.
 * @returns Функция обработки ошибки.
 */
export const useOnErrorSaveCard = ({
  showAlert,
}: {
  showAlert: (message: string | JSX.Element) => void;
}) => {
  const { drawIntersectionsGeometry } = useMapContext();
  const { endPreload } = usePreloaderCard();

  /**
   * Заглушка.
   */
  // const setHasIntersections = () => { };

  /**
   * Метод обработки ошибки.
   *
   * @param params - Параметры.
   * @param params.errorKey - Ключ ошибки.
   * @param params.res - Ответ.
   * @param params.res.geometry - Геометрия.
   */
  return ({
    errorKey,
    res,
  }: {
    errorKey: string;
    res: { geometry: unknown };
  }) => {
    endPreload();
    switch (errorKey) {
      case 'errorMore':
      case 'errorOther': {
        showAlert(message[errorKey]);
        break;
      }
      case 'errorIntersectionsMore':
      case 'errorIntersections': {
        // @ts-ignore
        showAlert(createIntersectionsMessage(res.geometry, message[errorKey]));
        break;
      }
      default:
        break;
    }
    // setHasIntersections(res.intersected);
    // @ts-ignore
    drawIntersectionsGeometry(res.geometry);
  };
};
