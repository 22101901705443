import { Address, ADDRESS_NAME } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент AddressFF.
 *
 * @param {object} props - Свойства компонента.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @param {object} [props.otherProps] - Дополнительные пропсы.
 *
 * @returns {React.Element} Отрендеренный компонент `Address` внутри `Field` из `react-final-form`.
 */
export const AddressFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={ADDRESS_NAME}>
      {(props) => {
        return (
          <Address
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { ADDRESS_NAME };
