import { Cell } from 'core/uiKit/components/tables/Table';

/**
 * Колонка __Количество машиномест__.
 *
 * @type {{
 * accessor: 'parking_places_quantity',
 * Header: 'Количество машиномест',
 * }}
 * @augments Cell
 */
export const parking_places_quantity: Cell = {
  Header: 'Количество машиномест',
  accessor: 'parking_places_quantity',
};
