import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { elementFormGrid } from 'app/components/card/common/grid';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { useGetCardCalcAttributeSelector } from 'app/selectors/useSelectors/useGetCardCalcAttribute.Selector';
import getHint from 'app/utils/getHint';
import { RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { BasicParameters } from './BasicParameters';

vi.mock('app/selectors/useSelectors/useEditorCard.Selector');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/selectors/useSelectors/useGetCardCalcAttribute.Selector');
vi.mock('app/components/card/common/grid');
vi.mock('app/utils/getHint');

/**
 * Default Mock.
 *
 * @returns {void}
 */
const defaultMock = () => {
  useMode.mockReturnValue({ editMode: true });
  useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
  useEditorCardSelector.mockReturnValue({ is_orphan_object: true });
  useGetCardCalcAttributeSelector.mockReturnValue({});
  elementFormGrid.mockImplementation((_) => _);
};
describe('🐛 spec BasicParameters', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<BasicParameters propertyTab={''} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<TabPanel />');
  });

  it('🧪 если есть округления то они все 20', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();

    let digits = [];
    elementFormGrid.mockImplementation((val) => {
      digits = val.map((item) => item?.addition?.digits).filter(Boolean);
    });

    // 🔥 Act
    shallow(<BasicParameters propertyTab={''} />);

    // ❓ Assert
    expect(digits.every((item) => item === 20)).toBeTruthy();
  });

  it.each`
    editMode | isEditCurrentObjectIsExternalSystem | isOrphanObject | expDisabled | expRequired
    ${true}  | ${true}                             | ${true}        | ${false}    | ${true}
    ${false} | ${true}                             | ${true}        | ${true}     | ${true}
    ${true}  | ${false}                            | ${true}        | ${true}     | ${true}
  `(
    '🧪 Протяженность лотков, п.м editMode=$editMode isEditCurrentObjectIsExternalSystem=$isEditCurrentObjectIsExternalSystem isOrphanObject=$isOrphanObject expDisabled=$expDisabled expRequired=$expRequired',
    ({
      editMode,
      isEditCurrentObjectIsExternalSystem,
      isOrphanObject,
      expDisabled,
      expRequired,
    }) => {
      expect.hasAssertions();

      // ☣️ Arrange (всякие моки)
      defaultMock();
      useIsEditCurrentObjectIsExternalSystem.mockReturnValue(
        isEditCurrentObjectIsExternalSystem,
      );
      useEditorCardSelector.mockReturnValue({
        is_orphan_object: isOrphanObject,
      });
      useMode.mockReturnValue({ editMode });

      // 🔥 Act
      const wrapper = shallow(<BasicParameters propertyTab={''} />);
      const guttersLength = wrapper.find('#gutters_length').props();

      // ❓ Assert
      expect(guttersLength).toStrictEqual({
        digits: 20,
        disabled: expDisabled,
        formValue: true,
        helpTitle: 'Заполняется пользователем вручную',
        id: 'gutters_length',
        label: 'Протяженность лотков, п.м',
        name: 'gutters_length',
        required: expRequired,
      });
    },
  );

  it('🧪 Протяженность лотков п.м is_orphan_object=false', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useEditorCardSelector.mockReturnValue({
      is_orphan_object: false,
    });
    useMode.mockReturnValue({ editMode: true });
    getHint.mockReturnValue(RANDOM_WORD);

    // 🔥 Act
    const wrapper = shallow(<BasicParameters propertyTab={''} />);
    const guttersLength = wrapper.find('#gutters_length').props();

    // ❓ Assert
    expect(guttersLength).toStrictEqual({
      digits: 20,
      disabled: true,
      formValue: true,
      helpTitle: RANDOM_WORD,
      id: 'gutters_length',
      label: 'Протяженность лотков, п.м',
      name: 'gutters_length',
      required: false,
    });
  });

  it.each`
    isEditCurrentObjectIsExternalSystem | editMode | expDisabled
    ${true}                             | ${true}  | ${false}
    ${false}                            | ${true}  | ${true}
    ${true}                             | ${false} | ${true}
  `(
    '🧪 LayoutLengthRF  isEditCurrentObjectIsExternalSystem=$isEditCurrentObjectIsExternalSystem editMode=$editMode expDisabled=$expDisabled',
    ({ isEditCurrentObjectIsExternalSystem, editMode, expDisabled }) => {
      expect.hasAssertions();

      // ☣️ Arrange (всякие моки)
      defaultMock();

      useIsEditCurrentObjectIsExternalSystem.mockReturnValue(
        isEditCurrentObjectIsExternalSystem,
      );
      useEditorCardSelector.mockReturnValue({
        is_orphan_object: false,
      });
      useMode.mockReturnValue({ editMode });
      getHint.mockReturnValue(RANDOM_WORD);

      // 🔥 Act
      const wrapper = shallow(<BasicParameters propertyTab={''} />);
      const guttersLength = wrapper.find('LayoutLengthRF').props();

      // ❓ Assert
      expect(guttersLength).toStrictEqual({
        disabled: expDisabled,
        helpTitle: RANDOM_WORD,
        required: true,
      });
    },
  );

  it('🧪 LayoutLengthRF render', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();

    // 🔥 Act
    const wrapper = shallow(<BasicParameters propertyTab={''} />);

    // ❓ Assert
    expect(wrapper.find('LayoutLengthRF').exists()).toBeTruthy();
  });
});
