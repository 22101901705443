import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { ODH_AXIS_NAME, OdhAxisField } from './OdhAxis.Field';

vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');

describe('🐛 OdhAxisField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    // 🔥 Act

    const wrapper = shallow(<OdhAxisField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<TextFieldRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    // 🔥 Act

    const wrapper = shallow(<OdhAxisField />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      label: 'Ось',
      maxLength: 2,
      name: ODH_AXIS_NAME,
      tooltip: ODH_AXIS_TOOLTIP,
    });
  });
});
