export const ice_rink_type = [
  {
    code: '1',
    id: 1,
    name: '1',
  },
  {
    code: '2',
    id: 2,
    name: '2',
  },
  {
    code: '3',
    id: 3,
    name: '3',
  },
];
