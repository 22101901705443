import { vi } from 'vitest';

import Create from './create';

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();

  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => Component),
  };
});

vi.mock('react-redux', () => ({
  connect: vi.fn(
    (mapStateToProps, mapDispatchToProps) => (Component) => (props) =>
      <Component {...mapStateToProps} {...mapDispatchToProps} {...props} />,
  ),
}));

import { shallow } from 'enzyme';

describe('🐛 CreateSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const wrapper = shallow(<Create />);

    // 🔥 Act

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      children: expect.any(Object),
      value: {
        callMapFunction: expect.any(Function),
        clearDiff: expect.any(Function),
        clearLayer: expect.any(Function),
        drawChildrenGeometry: expect.any(Function),
        drawCurrentObjectsGeometry: expect.any(Function),
        drawDiffCopyObjectGeometry: expect.any(Function),
        drawDiffOriginalObjectGeometry: expect.any(Function),
        drawDistrictBoundaries: expect.any(Function),
        drawIntersectionsGeometry: expect.any(Function),
        drawNeighboursGeometry: expect.any(Function),
        drawOrdersGeometry: expect.any(Function),
        drawParentGeometry: expect.any(Function),
        drawReonGeometry: expect.any(Function),
        getGeometry: expect.any(Function),
        getRawGeometry: expect.any(Function),
        isMapLoaded: false,
        zoomToDiff: expect.any(Function),
      },
    });
  });
});
