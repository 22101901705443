import { useSetupPromptEditMode } from 'app/routes/components/PromptEditMode/useSetup.PromptEditMode';
import { Prompt } from 'core/uiKit/components/Prompt';
import React from 'react';

/**
 * Диалоговое окно Подтверждения перейти на другой урл при режиме редактирования.
 *
 * @returns Prompt.
 */
export const PromptEditMode = () => {
  const { when, send } = useSetupPromptEditMode();

  return (
    <Prompt
      when={when}
      message="Все несохраненные изменения будут потеряны."
      send={send}
    />
  );
};
