import * as env from 'config/env';
import { log } from 'core/utils/log';
import React from 'react';

/**
 * SystemVersion.
 *
 * @param {object} props - Пропсы компонента.
 * @param {string} [props.backendBuildDate] - Дата сборки backend.
 * @param {string} [props.backendVersion] - Версия backend.
 * @param {string} [props.dbExecutionDate] - Дата сборки БД.
 * @param {string} [props.dbVersion] - Версия БД.
 * @returns {JSX.Element} - Возвращает JSX-элемент.
 */
function SystemVersion({
  backendBuildDate = '...',
  backendVersion = '...',
  dbExecutionDate = '...',
  dbVersion = '...',
}) {
  const frontendVersion = env.FRONTEND_VERSION;
  const frontendVersionWithBuild = env.FRONTEND_VERSION_WITH_BUILD;
  const frontendBuildDate = env.FRONTEND_BUILD_DATE;
  const frontendEnvironment = env.FRONTEND_ENVIRONMENT;

  const sysInfo = {
    API: {
      версия: backendVersion?.split('-')[0] || 'нет',
      'версия сборки': backendVersion?.split('-')[1] || 'нет',
      'дата сборки': backendBuildDate,
      контур: backendVersion?.split('-')[2] || 'нет',
    },
    BD: {
      версия: dbVersion?.split('-')[0] || 'нет',
      'версия сборки': dbVersion?.split('-')[1] || 'нет',
      'дата сборки': dbExecutionDate,
      контур: dbVersion?.split('-')[2] || 'нет',
    },
    UI: {
      версия: frontendVersion,
      'версия сборки': frontendVersionWithBuild?.split('-')[1] || 'нет',
      'дата сборки': frontendBuildDate,
      контур: frontendEnvironment,
    },
  };

  /**
   *  Window.systemInfo.
   *
   * @returns {void}
   */
  window.systemInfo = () => {
    log.table(sysInfo);
  };

  return (
    <div>
      <div className="helper-caption">
        Версия UI:&#xa0;
        <span>
          {frontendVersion}
          {/*от {frontendBuildDate}*/}
        </span>
      </div>
      <div className="helper-caption">
        Версия API:&#xa0;
        <span>
          {backendVersion?.split('-')[0] || 'нету'}
          {/*от {backendBuildDate}*/}
        </span>
      </div>
      <div className="helper-caption">
        Версия DB:&#xa0;
        <span>
          {dbVersion}
          {/*от {dbExecutionDate}*/}
        </span>
      </div>
    </div>
  );
}

export default SystemVersion;
