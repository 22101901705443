import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import React from 'react';
import { NumberPreparedProps } from 'types/inputs';

import { useMafTypeLevel1Options } from './useMafTypeLevel1Options';

export const MAF_TYPE_LEVEL_1_ID_NAME = 'maf_type_level1_id';

/**
 * Select RF (используется только для маф, другой запрос на справочник) __Вид МАФ__.
 *
 * @param props - Пропсы.
 * @returns Вид МАФ.
 */
export const MafTypeLevel1IdField = (props: NumberPreparedProps) => {
  const options = useMafTypeLevel1Options();
  return (
    <SelectRF
      {...props}
      options={options}
      label={'Вид МАФ'}
      name={MAF_TYPE_LEVEL_1_ID_NAME}
      withAll={false}
    />
  );
};
