import { KeyboardDateTimePicker } from 'core/uiKit/inputs/datePickers/KeyboardDateTimePicker';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент KeyboardDateTimePickerFF.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {string} [props.name] - Имя поля, которое будет использоваться в форме.
 * @param {string} [props.label] - Метка для компонента.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const KeyboardDateTimePickerFF = ({
  disabled = false,
  name = 'KeyboardDateTimePickerFF',
  label = 'label for KeyboardDateTimePickerFF',
  ...otherProps
}) => {
  return (
    <Field name={name}>
      {(props) => {
        return (
          <KeyboardDateTimePicker
            {...{ disabled, label, name }}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
