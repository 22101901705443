import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { WithMapContext } from 'app/components/map/withMap';
import { useActionsMap } from 'app/pages/cardsOgh/components/Map/useActionsMap';
import { useGetTreePanelData } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/hooks/useGetTreePanelData';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useNavigateRouter } from 'core/utils/router';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NumberParam, useQueryParams } from 'use-query-params';

import { OghCard } from './card/IndexCard';

/**
 * Компонент Создания карточки ОГХ (Старый).
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CreateHOC = (props) => {
  useActionsMap();
  const paramsUrl = useGetParamsUrl();
  const location = useLocation();
  const navigate = useNavigateRouter();

  const { startEditing = () => {} } = useContext(WithMapContext);
  startEditing();

  const { isSuccessParent, ...parentCard } = useGetParentCard();
  const { removeTree } = useGetTreePanelData();
  const [query] = useQueryParams({
    otherImprovementObjectType: NumberParam,
  });

  const { createCard, fetchPreviewCard } = props;
  useEffect(() => {
    const { typeId, startDate } = paramsUrl;

    setTimeout(() => {
      if (paramsUrl?.actions?.haveParent) {
        createCard(typeId, startDate, {
          ...query,
          parentTypeId: parentCard.typeId,
        });
        fetchPreviewCard(typeId, startDate);
        return;
      }
      createCard(typeId, startDate, query);
      fetchPreviewCard(typeId, startDate);
    }, 0);

    // eslint-disable-next-line
  }, [query, paramsUrl, createCard, fetchPreviewCard, isSuccessParent]);

  /**
   * @deprecated
   * @see используй navigate
   */
  const history = { push: navigate };
  const parentInfo = {
    ...parentCard,
    isChild: paramsUrl?.actions?.haveParent,
  };

  return (
    <OghCard
      {...props}
      {...{
        history,
        location,
        navigate,
        params: { id: paramsUrl.typeId },
        paramsUrl,
        parentInfo,
        typeId: paramsUrl.typeId,
        updateTree: removeTree,
      }}
    />
  );
};
