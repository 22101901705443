import { fetchIntersectionsGeometry } from 'app/api/requests/geometry';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { useGetCurrentCard } from '../useGetCurrentCard';

/**
 * Хук получения данных пересечений.
 *
 * @param {object} root0 - Properties.
 * @param {Function} root0.drawIntersections - Наричовать гометрию.
 * @param {*} root0.geometry - Геометрия.
 * @returns {*}
 */
export const useGetGeometryIntersection = ({ drawIntersections, geometry }) => {
  const currentCard = useGetCurrentCard();

  const request = {
    attribute: {
      createTypeId: currentCard?.attribute?.create_type_id,
      isDiffHeightMark: currentCard?.attribute?.is_diff_height_mark ? 1 : 0,
    },
    geometry,
    id: currentCard.root_id,
    parentId: currentCard.parent_id,
    startDate: currentCard?.startDate,
    typeId: currentCard.type_id,
  };

  // получить геометрию
  return useQueryAdaptor(
    ['FetchIntersectionsGeometry', request],
    fetchIntersectionsGeometry,
    {
      //

      /**
       * OnSuccess.
       *
       * @param {object} response - Ответ.
       */
      onSuccess: (response) => {
        if (response?.intersected && response?.geometry) {
          // отрисовать геометрию если есть
          drawIntersections(response?.geometry);
        }
      },
    },
  );
};
