import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { RejectExternalObjectOghButton } from './RejectExternalObjectOgh.Button';

vi.mock('app/api/hooks/useGetVisibilityButtonsOgh');

describe('🐛 test RejectExternalObjectOghButton', () => {
  describe('🐛 test props', () => {
    it('🧪 это кнопка', () => {
      expect.hasAssertions();

      // ☣️ Arrange (всякие моки)
      useGetVisibilityButtonsOgh.mockReturnValue({
        data: { action_external_object: true },
      });

      // 🔥 Act
      const root = shallow(<RejectExternalObjectOghButton objectId={42} />);

      // ❓ Assert
      expect(root.text()).toBe('<Button />');
    });

    it('🧪 пропсы когда есть кнопка', () => {
      expect.hasAssertions();
      // ☣️ Arrange (всякие моки)
      useGetVisibilityButtonsOgh.mockReturnValue({
        data: { action_external_object: true },
      });

      // 🔥 Act
      const root = shallow(<RejectExternalObjectOghButton objectId={42} />);

      // ❓ Assert
      expect(root.props()).toMatchObject({
        children: 'Отклонить',
        color: 'warning',

        variant: 'contained',
      });
    });

    it('🧪 пропсы когда нет кнопка', () => {
      expect.hasAssertions();

      // ☣️ Arrange (всякие моки)
      useGetVisibilityButtonsOgh.mockReturnValue({
        data: { action_external_object: false },
      });

      // 🔥 Act
      const root = shallow(<RejectExternalObjectOghButton objectId={42} />);

      // ❓ Assert
      expect(root.props()).toStrictEqual({});
    });
  });

  describe('useGetVisibilityButtonsOgh', () => {
    it('🧪 useGetVisibilityButtonsOgh', () => {
      expect.hasAssertions();

      // ☣️ Arrange (всякие моки)
      useGetVisibilityButtonsOgh.mockReturnValue({
        data: { action_external_object: false },
      });
      shallow(<RejectExternalObjectOghButton objectId={42} />);

      // 🔥 Act

      // ❓ Assert
      expect(useGetVisibilityButtonsOgh).toHaveBeenCalledWith(42);
    });
  });
});
