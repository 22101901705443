import { useMapContext } from 'app/components/map/useMapContext';
import { createIntersectionsMessage } from 'app/utils/card/checkGeometry/helpers/createIntersectionsMessage';
import messages from 'app/utils/card/checkGeometry/messages';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { useReduxCheckGeometryButton } from '../useRedux.CheckGeometry.Button';

/**
 * Получение геометрий пересечения.
 *
 * @param parags - Параметры.
 * @param parags.showAlert - Показ сообщения.
 * @returns Мутация.
 */
export const useDrawIntersectionsGeometriesDTW = ({
  showAlert,
}: {
  showAlert: (message: JSX.Element) => void;
}) => {
  const { fetchCheckGeometryIntersections } = useReduxCheckGeometryButton();

  const { drawIntersections } = useMapContext();

  return useMutationAdaptor(fetchCheckGeometryIntersections, {
    //

    /**
     * A.
     *
     * @param data - A.
     */
    onSuccess: (data) => {
      showAlert(
        createIntersectionsMessage(data.geometry, messages.errorIntersections),
      );
      drawIntersections(data.geometry);
    },
  });
};
