import 'core/form/filterForm/components/inputsFilterStyle.scss';
import 'core/form/filterForm/filterStyle.scss';

import { fetchAddress, fetchOghTypes } from 'app/api/typeahead';
import {
  BUILDING,
  LITTLE_FORM,
  OTHER_TECH_PLACE,
  TECH_PLACE,
} from 'app/constants/oghTypes';
import {
  isImprovementObject,
  isImprovementTerritoryOrOdh,
} from 'app/utils/checkOghTypeId';
import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
import haveName from 'app/utils/typeHaveName';
import cn from 'classnames';
import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';
import { AutocompleteAsyncRF } from 'core/form/reduxForm/fields/default/selects/AutocompleteAsyncRF';
import Select, { DarkSelect } from 'core/newComponents/Select';
import { Button } from 'core/uiKit/components/buttons/Button';
import { KeyboardDatePicker } from 'core/uiKit/inputs/datePickers/KeyboardDatePicker';
import { TextField } from 'core/uiKit/inputs/TextField';
import { Customer } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/Customer';
import { OwnerId } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OwnerId';
import { OghStatuses } from 'core/uiKit/preparedInputs/selects/multiSelect/OghStatuses';
import isIntegerNumber from 'core/utils/isIntegerNumber';
import {
  filter,
  find,
  get,
  isEmpty,
  isFunction,
  isNumber,
  values,
} from 'lodash';
import React from 'react';

import { fieldName } from './constants/fieldNames';
import level from './constants/ooptMeaning';

const ALL_VALUES_OPTION = { id: '', name: 'Все' };
const BOOLEAN_OPTIONS = [
  { id: true, name: 'Да' },
  { id: false, name: 'Нет' },
];

const DATE_ERROR_TEXT =
  'Дата по не может быть раньше даты с. Укажите корректное значение.';

const boolFields = ['isSeparateGarbageCollection', 'parentName'];

/**
 * Компонент SearchForm.
 *
 * @class
 * @param {*} options - Хопа.
 * @returns {JSX.ElementClass} - Жопа.
 */
class SearchForm extends React.Component {
  //

  /**
   * Конструктор компонента React вызывается до того, как компонент будет примонтирован. В начале конструктора необходимо вызывать super(props). Если это не сделать, this.props не будет определён. Это может привести к багам.
   *
   * @example
   * -----
   * @param {object} props - Props.
   */
  constructor(props) {
    super(props);

    const defaultValues = this.getDefaultValue({
      withoutCustom: false,
    });

    this.state = {
      ...defaultValues,
      dateToErrorText: '',
      typeId: get(props, 'custom.typeId.default'),
    };
  }

  /**
   * Метод вызывается при удалении компонента из DOM.
   *
   * @returns {void} - Nothing.
   * @example
   * -----
   */
  componentWillUnmount() {
    const { onRef } = this.props;

    if (onRef) {
      onRef(undefined);
    }
  }

  /**
   * Метод.
   *
   * @param {object} options - Опции.
   * @returns {object} - Объект.
   */
  getDefaultValue = (options = {}) => {
    const { parentTypeId } = this.props;

    const defaultValues = {
      [fieldName.baseNumOrName]: '',
      [fieldName.idOrName]: '',
      [fieldName.customer]: '',
      [fieldName.dateFrom]: parseDateToMoment('01.01.1900'),
      [fieldName.dateTo]: parseDateToMoment('01.01.3000'),
      [fieldName.status]: [],
      [fieldName.department]: '',
      [fieldName.plantationType]: '',
      [fieldName.subTypeId]: -1,
      [fieldName.buildingTypeId]: isImprovementObject(parentTypeId) ? 91 : '',
      [fieldName.isSeparateGarbageCollection]: '',
      [fieldName.parentName]: '',
      [fieldName.plantType]: '',
      [fieldName.lifeFormType]: '',
      [fieldName.sectionNum]: '',
      [fieldName.lawnType]: '',
      [fieldName.containerType]: '',
      [fieldName.otherImprovementObjectType]: '',
      [fieldName.reagentBaseType]: '',
      [fieldName.okrug]: '',
      [fieldName.district]: '',
      [fieldName.reagentKind]: '',
      [fieldName.buildingsTypeSpecId]: '',
      [fieldName.ooptMeaning]: '',
      [fieldName.ooptProfile]: '',
      [fieldName.reagent]: '',
      [fieldName.ooptCategory]: '',
      [fieldName.explicationType]: '',
      [fieldName.ooptStatus]: '',
      [fieldName.cadNumber]: '',
    };

    if (!options.withoutCustom) {
      const custom = this.props.custom || {};

      Object.keys(custom).forEach((key) => {
        if ('defValue' in custom[key]) {
          defaultValues[key] = custom[key].defValue;
        }
      });
    }

    return defaultValues;
  };

  /**
   * GetValues.
   *
   * @returns {object} - Object.
   */
  getValues = () => {
    const { custom } = this.props;

    return this.props.components.reduce((result, item) => {
      let key = item;
      let value = this.state[item];

      if (value) {
        if (custom && custom[item] && custom[item].name) {
          key = custom[item].name;
        } else if (item === fieldName.idOrName) {
          key = isIntegerNumber(value) ? 'id' : 'name';
        }
        if (item === fieldName.dateFrom || item === fieldName.dateTo) {
          value = new Intl.DateTimeFormat('ru').format(value);
        }
        if (
          item === fieldName.customer ||
          item === fieldName.owner ||
          item === fieldName.address
        ) {
          value = value.id;
        }
        if (item === fieldName.subTypeId) {
          value = value.id || this.getDefaultValue().subTypeId;
        }
        if (key) {
          result[key] = value;
        }
      }

      if (value === false && boolFields.includes(item)) {
        result[key] = value;
      }

      return result;
    }, {});
  };

  /**
   * Метод.
   *
   * @returns {*} - XZ.
   * @example -----
   */
  getSelectComponent() {
    return this.isDarkTheme() ? DarkSelect : Select;
  }

  /**
   * Метод.
   *
   * @returns {{}} XZ.
   * @example -----
   */
  getCustom() {
    const { custom } = this.props;
    return custom || {};
  }

  /**
   * Метод.
   *
   * @returns {{}} - XZ.
   * @example -----
   */
  isDarkTheme() {
    const { dark } = this.props;
    return dark;
  }

  /**
   * Метод.
   *
   * @returns {void} - Nothing.
   * @example -----
   */
  startSearch = () => {
    const { onSearch } = this.props;

    if (onSearch) {
      onSearch();
    } else {
      this.submit();
    }
  };

  /**
   * Метод.
   *
   * @param {*} event - Хопа.
   * @returns {void} - Nothing.
   * @example -----
   */
  handleSubmit = (event) => {
    event.preventDefault();
  };

  /**
   * Метод.
   *
   * @returns {void} - Nothing.
   * @example -----
   */
  updateLifeFormTypes() {
    let result;

    if (this.state.plantationType) {
      const currentPlantationType = this.props.plantationTypes.find(
        (item) => item.id === this.state.plantationType,
      );
      const lifeFormTypeIds =
        currentPlantationType && currentPlantationType.life_form_type_ids;
      if (lifeFormTypeIds && lifeFormTypeIds.length > 0) {
        result = this.props.lifeFormTypes.filter((item) =>
          currentPlantationType.life_form_type_ids.includes(item.id),
        );
      } else {
        result = this.props.lifeFormTypes;
      }
    } else {
      result = this.props.lifeFormTypes;
    }
    this.setState({ sortedlifeFormTypes: result });
  }

  /**
   * Метод.
   *
   * @returns {void} - Nothing.
   * @example -----
   */
  updatePlantTypes() {
    let result;
    if (this.state.lifeFormType) {
      result = this.props.plantType.filter(
        (item) =>
          item &&
          item.life_form_type_ids &&
          item.life_form_type_ids.some((id) => id === this.state.lifeFormType),
      );
    } else {
      result = this.props.plantType;
    }

    this.setState({ sortedPlantTypes: result });
  }

  /**
   * Метод.
   *
   * @returns {void} - Nothing.
   * @example -----
   */
  updatePlantationTypes() {
    let result;
    if (this.state.lifeFormType) {
      const filtered = this.props.plantationTypes.filter((item) =>
        item.life_form_type_ids.includes(this.state.lifeFormType),
      );
      if (filtered.length) {
        result = filtered;
      } else {
        result = this.props.plantationTypes;
      }
    } else {
      result = this.props.plantationTypes;
    }
    this.setState({
      sortedPlantationTypes: result,
    });
  }

  /**
   * Метод.
   *
   * @returns {void} - Nothing.
   * @example -----
   */
  submit = () => {
    const { onSubmit } = this.props;
    const { dateFrom, dateTo } = this.state;

    const isValidDate = dateFrom && dateTo && dateFrom <= dateTo;
    if (isValidDate) {
      onSubmit(this.getValues());
    } else {
      this.setState({
        dateToErrorText: DATE_ERROR_TEXT,
      });
    }
  };

  /**
   * Метод.
   *
   * @returns {void} - Nothing.
   * @example -----
   */
  reset = () => {
    const newState = {};
    this.props.components.forEach((component) => {
      newState[component] = undefined;
    });

    this.setState({ dateToErrorText: '' });

    const ownerFromVersions = fieldName.owner;
    const defaultValues = this.getDefaultValue({
      withoutCustom: !(ownerFromVersions in newState),
    });

    this.setState({
      ...newState,
      ...defaultValues,
    });
  };

  /**
   * Метод.
   *
   * @returns {{}} - XZ.
   * @example -----
   */
  validate = () => {
    const values = this.state;
    const errors = {};
    const dateFrom = new Date(values.dateFrom).toDateString();
    const dateTo = new Date(values.dateTo).toDateString();

    if (new Date(dateFrom) > new Date(dateTo)) {
      errors.dateTo =
        'Дата по не может быть раньше даты с. Укажите корректное значение.';
    }

    return errors;
  };

  /**
   * Метод.
   *
   * @param {object} props - Хопа.
   * @param {*} props.field - Хопа.
   * @param {*} props.label - Хопа.
   * @param {*} props.minDate - Хопа.
   * @param {*} props.maxDate - Хопа.
   * @param {*} props.custom - Хопа.
   * @param {*} props.afterOnChange - Хопа.
   * @returns {JSX.Element} - JSX.
   * @example -----
   */
  renderDatePickerField = ({
    field,
    label,
    minDate,
    maxDate,
    custom = {},
    afterOnChange,
  }) => {
    const errors = this.validate();

    return (
      <div
        key={field}
        style={{
          width: 270,
        }}
      >
        <KeyboardDatePicker
          id={field}
          label={label}
          maxDate={maxDate}
          minDate={minDate}
          name={field}
          value={this.state[field]}
          meta={{ error: errors[field] }}
          onChange={(value) => {
            this.setState(
              {
                [field]: value?.toDate(),
              },
              () => {
                if (isFunction(afterOnChange)) {
                  afterOnChange();
                }
              },
            );
          }}
          {...custom[field]}
        />
      </div>
    );
  };

  /**
   * RenderDateFrom.
   *
   * @returns {JSX.Element}
   */
  renderDateFrom = () => {
    const { custom } = this.props;
    const { dateTo } = this.state;

    return this.renderDatePickerField({
      //

      /**
       * AfterOnChange.
       *
       * @returns {void}
       */
      afterOnChange: () => {
        this.setState({
          dateToErrorText: '',
        });
      },
      custom,
      field: fieldName.dateFrom,
      label: 'Дата с',
      maxDate: dateTo,
    });
  };

  /**
   * RenderDateTo..
   *
   * @returns {JSX.Element}
   */
  renderDateTo = () => {
    const { custom } = this.props;
    const { dateFrom } = this.state;

    return this.renderDatePickerField({
      //

      /**
       * AfterOnChange.
       *
       * @returns {void}
       */
      afterOnChange: () => {
        this.setState({
          dateToErrorText: '',
        });
      },
      custom,
      field: fieldName.dateTo,
      label: 'Дата по',
      minDate: dateFrom,
    });
  };

  /**
   * RenderTextField.
   *
   * @param {*} root0 - Хопа.
   * @param {*} root0.field - Хопа.
   * @param {*} root0.label- - Хопа.
   * @param {*} root0.afterOnChange - Хопа.
   * @param {*} root0.custom - Хопа.
   * @returns {JSX.Element}
   */
  renderTextField = ({ field, label, afterOnChange, custom = {} }) => {
    // make input component properties
    const inputProps = {
      id: field,
      label,
      name: field,

      /**
       * OnChange.
       *
       * @param {*} root0 - Хопа.
       * @param {*} root0.target - Хопа.
       * @param {*} root0.target.value - Хопа.
       * @returns {void}
       */
      onChange: ({ target: { value } }) => {
        this.setState(
          {
            [field]: value,
          },
          () => {
            if (isFunction(afterOnChange)) {
              afterOnChange();
            }
          },
        );
      },
      value: this.state[field],
    };

    return (
      <TextField {...inputProps} {...custom[field]} className={filterInput} />
    );
  };

  /**
   * RenderIdOrNameField..
   *
   * @returns {JSX.Element}
   */
  renderIdOrNameField = () => {
    const { typeId } = this.props;

    return this.renderTextField({
      field: fieldName.idOrName,
      ...(haveName(typeId)
        ? {
            hintText: isImprovementTerritoryOrOdh(typeId)
              ? 'ID или наименование'
              : 'ID',
            label: isImprovementTerritoryOrOdh(typeId)
              ? 'Наименование/ID'
              : 'ID',
          }
        : {
            hintText: 'ID',
            label: 'ID',
          }),
    });
  };

  /**
   * RenderSectionNumField..
   *
   * @returns {JSX.Element}
   */
  renderSectionNumField = () =>
    this.renderTextField({
      field: fieldName.sectionNum,
      label: 'Номер участка',
    });

  /**
   * RenderBaseNumOrNameField..
   *
   * @returns {JSX.Element}
   */
  renderBaseNumOrNameField = () =>
    this.renderTextField({
      field: fieldName.baseNumOrName,
      label: '№/наименование базы',
    });

  // вынесено

  /**
   * RenderCadNumberField.
   *
   * @returns {JSX.Element}
   */
  renderCadNumberField = () =>
    this.renderTextField({
      alwaysShowMask: false,
      field: fieldName.cadNumber,
      label: 'Кадастровый номер ООП',
      mask: 'ООПТ.9.99',
    });

  /**
   * RenderSelectField.
   *
   * @param {*} root0 - Хопа.
   * @param {*} root0.field - Хопа.
   * @param {*} root0.label - Хопа.
   * @param {*} root0.dict - Хопа.
   * @param {*} root0.style - Хопа.
   * @param {*} root0.afterOnChange - Хопа.
   * @param {*} root0.multiple - Хопа.
   * @param {*} root0.disabled - Хопа.
   * @param {*} root0.custom - Хопа.
   * @returns {JSX.Element} - Хопа.
   */
  renderSelectField = ({
    field,
    label,
    dict,
    style,
    afterOnChange,
    multiple,
    disabled,
    custom = {},
  }) => {
    const SelectComponent = this.getSelectComponent();

    return (
      <div
        key={field}
        style={{
          width: 270,
          ...style,
        }}
      >
        <SelectComponent
          disabled={disabled}
          id={field}
          label={label}
          multiple={multiple}
          name={field}
          options={[ALL_VALUES_OPTION, ...dict]}
          value={this.state[field]}
          onChange={(value) => {
            this.setState(
              {
                [field]: isNumber(value) || !isEmpty(value) ? value : [''],
              },
              () => {
                if (isFunction(afterOnChange)) {
                  afterOnChange();
                }
              },
            );
          }}
          {...custom[field]}
        />
      </div>
    );
  };

  /**
   * RenderBooleanSelectField.
   *
   * @param {*} root0 - Хопа.
   * @param {*} root0.field - Хопа.
   * @param {*} root0.label - Хопа.
   * @param {*} root0.style - Хопа.
   * @returns {JSX.Element}
   */
  renderBooleanSelectField = ({ field, label, style }) => {
    return this.renderSelectField({
      dict: BOOLEAN_OPTIONS,
      field,
      label,
      style,
    });
  };

  // вынесено

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderOoptMeaningField = () =>
    this.renderSelectField({
      dict: this.props.ooptMeanings,
      field: 'ooptMeaning',
      label: 'Значение ООПТ',
    });

  // вынесено

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderOoptProfileField = () =>
    this.renderSelectField({
      dict: this.props.ooptProfiles,
      field: 'ooptProfile',
      label: 'Профиль ООПТ',
    });

  // вынесено

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */ renderOoptStatusField = () =>
    this.renderSelectField({
      dict: this.props.ooptStatuses,
      field: 'ooptStatus',
      label: 'Статус ООПТ',
    });

  // вынесено

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */ renderExplicationTypeField = () =>
    this.renderSelectField({
      dict: this.props.explicationTypes,
      field: 'explicationType',
      label: 'Вид экспликации земли',
    });

  // вынесен

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderIsSeparateGarbageCollectionField = () =>
    this.renderBooleanSelectField({
      field: 'isSeparateGarbageCollection',
      label: 'Раздельный сбор отходов',
      style: {
        width: 200,
      },
    });

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */ renderBuildingTypeField = () => {
    const { buildingsType, typeId, parentTypeId } = this.props;
    const isShow = !(
      parentTypeId && [TECH_PLACE, OTHER_TECH_PLACE].includes(typeId)
    );

    return (
      isShow &&
      this.renderSelectField({
        dict: buildingsType,
        disabled: isImprovementObject(parentTypeId),
        field: 'buildingTypeId',
        label: 'Назначение',
      })
    );
  };

  // вынесен

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */ renderContainerTypeField = () =>
    this.renderSelectField({
      dict: this.props.containerTypes,
      field: 'containerType',
      label: 'Тип МНО',
    });

  // вынесен

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderOtherImprovementObjectTypeField = () =>
    this.renderSelectField({
      dict: values(this.props.otherImprovementObjectType),
      field: 'otherImprovementObjectType',
      label: 'Тип ИОБ',
    });

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderParentNameField = () =>
    this.renderBooleanSelectField({
      field: 'parentName',
      label: 'Входит в',
    });

  // вынесено

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */ renderReagentBaseTypeField = () =>
    this.renderSelectField({
      dict: this.props.reagentBaseTypes,
      field: 'reagentBaseType',
      label: 'Тип',
    });

  // вынесено

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */ renderReagentField = () =>
    this.renderSelectField({
      dict: this.props.reagents,
      field: 'reagent',
      label: 'Наименование ПГР',
    });

  // вынесено

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderReagentKindField = () =>
    this.renderSelectField({
      dict: this.props.reagentKinds,
      field: 'reagentKind',
      label: 'Вид ПГР',
    });

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */ renderBuildingsTypeSpecField = () => {
    const { typeId, buildingsTypeSpec, custom } = this.props;

    const filteredDict = buildingsTypeSpec.filter(
      (item) => item.ogh_object_type_id === typeId,
    );

    // вынесен
    return this.renderSelectField({
      custom,
      dict: filteredDict,
      field: 'buildingsTypeSpecId',
      label: 'Уточнение типа',
    });
  };

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderStatusField = () => {
    const status = this.state[fieldName.status];

    /**
     * Method.
     *
     * @param {*} value - Хопа.
     * @returns {void} - Nothing.
     * @example -----
     */
    const change = (value) => {
      if (value !== status) {
        this.setState({
          [fieldName.status]: value,
        });
      }
    };

    return (
      <OghStatuses className={filterInput} value={status} onChange={change} />
    );
  };

  // вынесено

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderDistrictField = () => {
    const { districts } = this.props;
    const { okrug } = this.state;

    return this.renderSelectField({
      dict: [
        ...((districts &&
          districts.filter((district) => {
            return (
              !okrug ||
              okrug === '' ||
              (district.okrug || []).indexOf(okrug) !== -1
            );
          })) ||
          []),
      ],
      field: fieldName.district,
      label: 'Район',
    });
  };

  // вынесено

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */ renderDepartmentField = () =>
    this.renderSelectField({
      dict: this.props.departmentTypes || [],
      field: fieldName.department,
      label: 'Отраслевой ОИВ',
      style: {
        width: 350,
      },
    });

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */ renderPlantationTypeField = () => {
    const actualPlantationTypes =
      this.state.sortedPlantationTypes || this.props.plantationTypes;

    return this.renderSelectField({
      //

      /**
       * RenderField.
       *
       * @returns {void}
       */
      afterOnChange: () => {
        this.updatePlantTypes();
        this.updateLifeFormTypes();
        this.updatePlantationTypes();
      },
      dict: actualPlantationTypes || [],
      field: fieldName.plantationType,
      label: 'Тип насаждения',
      style: {
        width: 170,
      },
    });
  };

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderLifeFormTypeField = () => {
    const actualLifeFormTypes =
      this.state.sortedlifeFormTypes || this.props.lifeFormTypes;

    return this.renderSelectField({
      //

      /**
       * RenderField.
       *
       * @returns {void}
       */
      afterOnChange: () => {
        this.updatePlantTypes();
        this.updateLifeFormTypes();
        this.updatePlantationTypes();
      },
      dict: actualLifeFormTypes || [],
      field: fieldName.lifeFormType,
      label: 'Жизненная форма',
      style: {
        width: 170,
      },
    });
  };

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */ renderOoptCategoryField = () => {
    const { ooptCategories } = this.props;
    const { ooptMeaning } = this.state;

    let filteredOoptCategories = ooptCategories;

    if (ooptMeaning === level.FEDERAL || ooptMeaning === level.REGIONAL) {
      // FIXME Необходимо скорректировать поле code в справочнике oopt_meaning и удалить ooptMeaning.js
      filteredOoptCategories = filter(ooptCategories, (it) => {
        return find(it.oopt_meaning_list, {
          oopt_meaning_id: ooptMeaning,
        });
      });
    }

    // вынесено
    return this.renderSelectField({
      dict: filteredOoptCategories,
      field: 'ooptCategory',
      label: 'Категория ООПТ',
    });
  };

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderPlantTypeField = () => {
    const actualPlantTypes =
      this.state.sortedPlantTypes || this.props.plantType;

    return this.renderSelectField({
      //

      /**
       * RenderField.
       *
       * @returns {void}
       */
      afterOnChange: () => {
        this.updatePlantTypes();
        this.updateLifeFormTypes();
        this.updatePlantationTypes();
      },
      dict: actualPlantTypes || [],
      field: fieldName.plantType,
      label: 'Вид растения',
    });
  };

  // вынесено

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderOkrugField = () =>
    this.renderSelectField({
      //

      /**
       * RenderField.
       *
       * @returns {void}
       */
      afterOnChange: () => {
        const { okrug, district } = this.state;

        this.setState({
          district: okrug === '' ? district : '',
        });
      },
      dict: this.props.okrugs || [],
      field: fieldName.okrug,
      label: 'Округ',
    });

  // вынесен

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderGRBSField = () => {
    return this.renderSelectField({
      // так сделано по тому что grbsShortName на сервере ожидает имя
      dict:
        this.props.grbs.map(({ name }, index) => ({
          id: name,
          key: index,
          name,
        })) || [],
      field: fieldName.grbsShortName,
      label: 'ГРБС',
    });
  };

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderLawnTypeField = () =>
    this.renderSelectField({
      dict: this.props.lawnTypes || [],
      field: fieldName.lawnType,
      label: 'Тип газона',
    });

  /**
   * RenderField.
   *
   * @param {*} root0 - Хопа.
   * @param {*} root0.additionalParams - Хопа.
   * @param {*} root0.inputMinLength - Хопа.
   * @param {*} root0.optionKeyForMap - Хопа.
   * @param {*} root0.field - Хопа.
   * @param {*} root0.label - Хопа.
   * @param {*} root0.fetchFunction - Хопа.
   * @param {*} root0.multiple - Хопа.
   * @param {*} root0.placeholder - Хопа.
   * @param {*} root0.onClearOptions - Хопа.
   * @param {*} root0.custom - Хопа.
   * @returns {JSX.Element}
   */
  renderSelectAjaxField = ({
    additionalParams,
    inputMinLength,
    optionKeyForMap,
    field,
    label,
    fetchFunction,
    multiple,
    placeholder,
    onClearOptions,
    custom = {},
  }) => {
    return (
      <div
        key={field}
        style={{
          width: 270,
          // ...style,
        }}
      >
        <AutocompleteAsyncRF
          additionalParams={additionalParams}
          fetchFunction={fetchFunction}
          id={field}
          inputMinLength={inputMinLength}
          label={label}
          multiple={multiple}
          name={field}
          optionKeyForMap={optionKeyForMap}
          placeholder={placeholder}
          value={this.state[field]}
          onChange={(value) => {
            this.setState({
              [field]: value,
            });
          }}
          onClearOptions={onClearOptions}
          {...custom[field]}
        />
      </div>
    );
  };

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderSubTypeIdField = () => {
    const { typeId, parentTypeId, otherImprovementObjectTypeId } = this.props;

    const subTypeIdData = {
      otherImprovementObjectTypeId,
      parentTypeId,
      typeId: typeId,
    };

    // вынесено
    let label = 'Тип';

    if ([BUILDING].includes(typeId)) {
      label = 'Назначение';
    }
    if ([LITTLE_FORM].includes(typeId)) {
      label = 'Вид МАФ';
    }

    return this.renderSelectAjaxField({
      additionalParams: subTypeIdData,
      fetchFunction: fetchOghTypes,
      field: fieldName.subTypeId,
      inputMinLength: 0,
      label,

      /**
       * RenderField.
       *
       * @returns {void}
       */
      onClearOptions: () => {
        this.setState({
          subTypeId: null,
        });
      },
      placeholder: 'Все',
    });
  };

  // вынесен

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderCustomerField = () => {
    const customer = this.state[fieldName.customer];

    /**
     * Method.
     *
     * @param {*} value - Хопа.
     * @returns {void} - Nothing.
     * @example ----
     */
    const change = (value) => {
      if (value !== customer) {
        this.setState({
          [fieldName.customer]: value,
        });
      }
    };

    return (
      <Customer className={filterInput} value={customer} onChange={change} />
    );
  };

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderOwnerField = () => {
    const owner = this.state[fieldName.owner];

    /**
     * Method.
     *
     * @param {*} value - Хопа.
     * @returns {void} - Nothing.
     * @example ----
     */
    const change = (value) => {
      if (value !== owner) {
        this.setState({
          [fieldName.owner]: value,
        });
      }
    };
    return <OwnerId className={filterInput} value={owner} onChange={change} />;
  };

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  renderAddressField = () => {
    return this.renderSelectAjaxField({
      fetchFunction: fetchAddress,
      field: fieldName.address,
      label: 'Адрес',
    });
  };

  /**
   * RenderField.
   *
   * @returns {JSX.Element}
   */
  buttonGroup = () => {
    return (
      <div className="filter__btn-submit-group">
        <Button
          variant={'contained'}
          color={'primary'}
          type="submit"
          onClick={this.startSearch}
          {...this.getCustom().submitButton}
        >
          Поиск
        </Button>
        <Button
          className="ms-3"
          color={'primary'}
          id={'clear-form'}
          variant={'outlined'}
          onClick={this.reset}
          {...this.getCustom().resetButton}
        >
          Сбросить
        </Button>
      </div>
    );
  };

  /**
   * Метод.
   *
   * @returns {object} - XZ.
   * @example -----
   */
  renderFormElements() {
    return {
      address: this.renderAddressField,
      baseNumOrName: this.renderBaseNumOrNameField,
      buildingTypeId: this.renderBuildingTypeField,
      buildingsTypeSpecId: this.renderBuildingsTypeSpecField,
      cadNumber: this.renderCadNumberField,
      containerType: this.renderContainerTypeField,
      customer: this.renderCustomerField,
      dateFrom: this.renderDateFrom,
      dateTo: this.renderDateTo,
      department: this.renderDepartmentField,
      district: this.renderDistrictField,
      explicationType: this.renderExplicationTypeField,
      grbsShortName: this.renderGRBSField,
      idOrName: this.renderIdOrNameField,
      isSeparateGarbageCollection: this.renderIsSeparateGarbageCollectionField,
      lawnType: this.renderLawnTypeField,
      lifeFormType: this.renderLifeFormTypeField,
      okrug: this.renderOkrugField,
      ooptCategory: this.renderOoptCategoryField,
      ooptMeaning: this.renderOoptMeaningField,
      ooptProfile: this.renderOoptProfileField,
      ooptStatus: this.renderOoptStatusField,
      otherImprovementObjectType: this.renderOtherImprovementObjectTypeField,
      owner: this.renderOwnerField,
      parentName: this.renderParentNameField,
      plantType: this.renderPlantTypeField,
      plantationType: this.renderPlantationTypeField,
      reagent: this.renderReagentField,
      reagentBaseType: this.renderReagentBaseTypeField,
      reagentKind: this.renderReagentKindField,
      resetButton: null, // this.renderResetButton,
      sectionNum: this.renderSectionNumField,
      status: this.renderStatusField,
      subTypeId: this.renderSubTypeIdField,
      submitButton: this.buttonGroup,
    };
  }

  /**
   * Метод отрисовки React.
   *
   * @returns {JSX.Element} - JSX.
   * @example
   * ----
   */
  render() {
    const { components } = this.props;

    const custom = this.props.custom || {};

    const formElements = this.renderFormElements();

    return (
      <form
        className={cn(
          'ods-head-form  display-flex gap-3 filter__form',
          this.props.className,
        )}
        onSubmit={this.handleSubmit}
        {...custom.itself}
      >
        {components.map((name, index) =>
          isFunction(formElements[name]) ? (
            <React.Fragment key={name || index}>
              {formElements[name]()}
            </React.Fragment>
          ) : (
            <React.Fragment key={name || index}>
              {formElements[name]}
            </React.Fragment>
          ),
        )}
      </form>
    );
  }
}

export default SearchForm;
