// 27  плоскостные сооруженияimport { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute } from 'app/components/card/common/getParseAttribute';
import {
  elementFormGrid,
  renderAbutmentList,
} from 'app/components/card/common/grid/index';
import { parseDate } from 'app/components/card/common/parse';
import { objectTabs } from 'app/components/card/common/tabs';
import {
  validateCleanArea,
  validatePlanarStructureTypeRef,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { CoatingGroupIdField } from 'app/components/card/ogh/components/fields/selectFields/CoatingGroupId.FIeld';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { IceRinkPlanarStructureField } from 'app/components/card/ogh/PlanarStructureCard/fields/IceRinkPlanarStructure.Field';
import { numericFields } from 'app/components/card/ogh/PlanarStructureCard/numerlicFields';
import { validate } from 'app/components/card/ogh/PlanarStructureCard/validate';
import {
  AUTO_CLEANING_TYPE,
  MANUAL_CLEANING_TYPE,
} from 'app/constants/cleaningTypes';
import { PLANAR_STRUCTURE } from 'app/constants/oghTypes';
import getCoatingTypes from 'app/selectors/card/getCoatingTypes';
import hasCoverageLock from 'app/selectors/card/hasCoverageLock';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { transformValues } from 'app/utils/form/transformation';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { NoCalcRF } from 'core/form/reduxForm/fields/prepared/checkboxes/NoCalc.RF';
import { CleaningTypeRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CleaningType.RF';
import { validateWithTypeOfCoating } from 'core/form/reduxForm/fields/prepared/selects/simple/CleaningType.RF/validateWithTypeOfCoating';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCard } from 'core/uiKit/components/TabsCard';
import { TabsCardItem } from 'core/uiKit/components/TabsCard/TabsCardItem';
import { ICE_RINK_PLANAR_STRUCTURE_NAME } from 'core/uiKit/preparedInputs/checkboxes/IceRinkPlanarStructure';
import { CLEANING_TYPE_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType';
import { PLANAR_STRUCTURE_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/PlanarStructureTypeId';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import { GroupIdRFPlanarStructureCard } from './PlanarStructureCard/fields/GroupId.RF.PlanarStructureCard';
import { PlanarStructureTypeIdField } from './PlanarStructureCard/fields/PlanarStructureTypeId.Field';
import { PlanarStructureTypeRefIdField } from './PlanarStructureCard/fields/PlanarStructureTypeRefId.Field';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['planar_structure'];
const Versions = VersionsTemplate(types);

const propTypes = {
  abutmentTypes: PropTypes.array,
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  coatingTypes: PropTypes.array,
  coverageLock: PropTypes.bool,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Классовый компонет PlanarStructureCard.
 *
 * @returns {JSX.ElementClass} - Жопа.
 */
class PlanarStructureCard extends React.Component {

  /**
   * Конструктор.
   *
   * @param {object} props - Пропсы.
   * @example -----
   */
  constructor(props) {
    super(props);
    this.state = {
      typeIds: [],
    };
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { props } = this;
    const {
      card,
      changeFieldValue,
      editMode,
      formValues,
      isCurrentObjectIsExternalSystem,
      isEditCurrentObjectIsExternalSystem,
      mode,
      required,
    } = props;
    const { type_id: typeId } = card;

    const tabsEnd = [
      {
        label: 'Исходные данные',
        value: 'characteristics',
      },
      {
        label: 'Элемент сопряжения',
        show: !editMode,
        value: 'abutments',
      },
      {
        label: 'Документы',
        value: 'documents',
      },
    ];

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(typeId)}
            <div id="props">
              <FieldsSplitColumns>
                <PlanarStructureTypeIdField />
                <PlanarStructureTypeRefIdField />
              </FieldsSplitColumns>

              <FieldsSplitColumns>
                <ParentInfo />
                <IceRinkPlanarStructureField />
                <IsDiffHeightMarkField />
                <NoCalcRF disabled={!editMode} />
                <GroupIdRFPlanarStructureCard editMode={editMode} />
              </FieldsSplitColumns>

              <TabsCard tabs={tabsEnd}>
                <TabsCardItem tabValue={'characteristics'}>
                  <FieldsSplitColumns>
                    <CoatingGroupIdField
                      required={required}
                      withChange={() => {
                        changeFieldValue('coating_type_id', null);
                      }}
                    />
                    <SelectRF
                      label={'Вид покрытия (уточнение)'}
                      name={'coating_type_id'}
                      options={this.props.coatingTypes}
                      disabled={
                        !(
                          editMode &&
                          get(formValues, 'coating_group_id') &&
                          isEditCurrentObjectIsExternalSystem
                        )
                      }
                      required={true}
                    />

                    <CleaningTypeRF disabled={!editMode} required />
                    <NumberFieldRF
                      name={'total_area'}
                      label={'Площадь, кв.м'}
                      digits={2}
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                    />

                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                    <TextFieldRF
                      label={'Характеристика'}
                      name={'property'}
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                    />
                  </FieldsSplitColumns>
                </TabsCardItem>
                <TabsCardItem tabValue={'abutments'}>
                  {elementFormGrid(
                    [
                      {
                        addition: { abutmentTypes: this.props.abutmentTypes },
                        component: renderAbutmentList,
                        editable: false,
                        formValue: true,
                        id: 'abutment_type_list',
                        name: 'abutment_type_list',
                        style: { overflow: 'auto' },
                      },
                    ],
                    1,
                    '',
                    typeId,
                  )}
                </TabsCardItem>
                <TabsCardItem tabValue={'documents'}>
                  <OtherDocumentsRF
                    disabled={!editMode && isCurrentObjectIsExternalSystem}
                  />
                </TabsCardItem>
              </TabsCard>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={props.createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

const getAbutmentTypes = getDict('abutmentTypes');

const planarStructureDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
export const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const result = {
    abutmentTypes: getAbutmentTypes(state),
    coatingTypes: getCoatingTypes(state, props),
    coverageLock: hasCoverageLock(state),
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    initialValues: {
      [CLEANING_TYPE_NAME]: parse('cleaning_type'),
      [ICE_RINK_PLANAR_STRUCTURE_NAME]: parse('ice_rink_planar_structure'),
      [PLANAR_STRUCTURE_TYPE_ID_NAME]: parse(
        'planar_structure_type_id.planar_structure_type',
      ),
      abutment_type_list: {
        showDialog: false,
        table: parse('abutment_type_list'),
      },
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      endDate: parseDate(props.card.end_date),
      group_id: props?.card?.group_id || [],
      is_diff_height_mark: parse('is_diff_height_mark'),
      no_calc: parse('no_calc'),
      parent_name: props.card.parent_info,
      property: parse('property'),
      startDate: parseDate(props.card.start_date),
      total_area: parse('total_area'),
      totalAreaGeo: parse('total_area_geo'),
    },

    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),

    validate,
  };

  return planarStructureDocumentsReducer(result, state, props);
};

PlanarStructureCard.propTypes = propTypes;

const dictFields = [
  'planar_structure_type_id',
  'coating_type_id',
  'coating_group_id',
  'planar_structure_type_ref_id',
];

const mandatoryFields = {
  [PLANAR_STRUCTURE]: [
    'planar_structure_type_id',
    'coating_type_id',
    'coating_group_id',
    'total_area',
    CLEANING_TYPE_NAME,
  ],
};

/**
 * Функция сабмита.
 *
 * @returns {void} - Жопа.
 * @param {object} formValues - Значения из формы.
 * @example ----
 */
export function onSubmit(formValues) {
  const data = { ...formValues };

  delete data.parent_name;

  const formValuesWithAttribute = {
    ...data,
    ...createDocumentsAttribute(data),
  };
  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[this.props.card.type_id],
    (values) => {
      if (values.total_area && values.total_area > 0) {
        if (values[CLEANING_TYPE_NAME] === MANUAL_CLEANING_TYPE) {
          values.manual_clean_area = values.total_area;
        }
        if (values[CLEANING_TYPE_NAME] === AUTO_CLEANING_TYPE) {
          values.auto_clean_area = values.total_area;
        }
        delete values.cleaning_type;
      }
      return {
        ...validatePlanarStructureTypeRef(values),
        ...validateCleanArea(values),
      };
    },
    validateWithTypeOfCoating,
  );

  // if (get(formValuesWithAttribute, 'abutment_type_list.table[0]')) {
  //   formValuesWithAttribute.abutment_type_list =
  //     formValuesWithAttribute.abutment_type_list.table;
  // } else {
  //   delete formValuesWithAttribute.abutment_type_list;
  // }

  if (formValuesWithAttribute?.abutment_type_list) {
    delete formValuesWithAttribute.abutment_type_list;
  }

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
function mapDispatchToProps(dispatch) {
  return {

    /**
     * Метод изменения поля.
     *
     * @param {string} field - Поле.
     * @param {*} value - Значение.
     */
    changeFieldValue: (field, value) => {
      dispatch(change('editorCard', field, value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(PlanarStructureCard));
