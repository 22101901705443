import { CUSTOMER_OZN_NAME, CustomerOzn } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент CustomerOznFF.
 *
 * @param {object} props - Пропсы компонента.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @param {object} [props.otherProps] - Дополнительные пропсы.
 *
 * ```
 * Заказчик   ozn   customer
 * place=ozn
 * ```
 *
 * @returns {JSX.Element}
 */
export const CustomerOznFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={CUSTOMER_OZN_NAME}>
      {(props) => {
        return (
          <CustomerOzn
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { CUSTOMER_OZN_NAME };
