import { TextField } from 'core/uiKit/inputs';
import React from 'react';
import { Field } from 'redux-form';

/**
 * TextFieldWithReduxForm.
 *
 * @param {object} props - Пропсы компонента.
 * @param {object} props.input - Инпут.
 * @returns {JSX.Element}
 */
const TextFieldWithReduxForm = ({ input, ...props }) => {
  return <TextField {...props} {...input} />;
};

/**
 * TextFieldWithRF.
 *
 * @param {object} props - Props.
 * @param {boolean} props.disabled - Флаг для отключения компонента.
 * @param {boolean} props.dark - Флаг для темной темы.
 * @param {string} props.label - Лейбл для текстового поля.
 * @param {string} props.name - Имя поля в Redux Form.
 * @param {object} props.otherProps - Дополнительные пропсы для компонента `TextField` от Redux Form.
 * @returns {JSX.Element}
 */
export const TextFieldRF = ({
  name = 'TextFieldRF',
  disabled = false,
  dark = false,
  label = 'TextFieldRF label',
  ...otherProps
}) => {
  return (
    <Field
      {...{ dark, disabled, label }}
      {...otherProps}
      name={name}
      component={TextFieldWithReduxForm}
    />
  );
};
