import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  GRBS_ID_NAME,
  GrbsId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';

/**
 * Компонент GrbsIdFF.
 *
 * @param {object} props - Пропсы, передаваемые в компонент.
 * @param {boolean} [props.dark] - Флаг для включения темного режима для компонента.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const GrbsIdFF = ({ dark = false, disabled = false, ...props }) => {
  return (
    <FieldFF
      {...{ dark, disabled }}
      {...props}
      name={GRBS_ID_NAME}
      component={GrbsId}
    />
  );
};
