import {
  OTHER_IMPROVEMENT_OBJECT_TYPE_NAME,
  OtherImprovementObjectType,
} from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент OtherImprovementObjectTypeFF.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.disabled - Флаг активности редактирования.
 * @param {object} props.otherProps - Другие пропсы.
 * @returns {JSX.Element}
 */
export const OtherImprovementObjectTypeFF = ({
  disabled = false,
  ...otherProps
}) => {
  return (
    <Field name={OTHER_IMPROVEMENT_OBJECT_TYPE_NAME}>
      {(props) => {
        return (
          <OtherImprovementObjectType
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { OTHER_IMPROVEMENT_OBJECT_TYPE_NAME };
