import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { IceRinkPlanarStructureField } from './IceRinkPlanarStructure.Field';
import { useSetupIceRinkPlanarStructureField } from './useSetup.IceRinkPlanarStructureField';

vi.mock('./useSetup.IceRinkPlanarStructureField');

describe('🐛 IceRinkPlanarStructureFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useSetupIceRinkPlanarStructureField.mockReturnValue({});

    // 🔥 Act
    const wrapper = shallow(<IceRinkPlanarStructureField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<IceRinkPlanarStructureRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useSetupIceRinkPlanarStructureField.mockReturnValue({
      disabled: RANDOM_BOOL,
    });

    // 🔥 Act
    const wrapper = shallow(<IceRinkPlanarStructureField />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: RANDOM_BOOL,
    });
  });
});
