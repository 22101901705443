// 13 Проезжая часть import CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { DistanceField } from 'app/components/card/ogh/components/fields/numberFields/Distance.Field';
import { WidthBeginField } from 'app/components/card/ogh/components/fields/numberFields/WidthBegin.Field';
import { WidthEndField } from 'app/components/card/ogh/components/fields/numberFields/WidthEnd.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import {
  AUTO_CLEANING_TYPE,
  cleaningTypesWithNoCleaning,
  MANUAL_CLEANING_TYPE,
} from 'app/constants/cleaningTypes';
import getCleaningType from 'app/selectors/card/getCleaningType';
import getCoatingTypes from 'app/selectors/card/getCoatingTypes';
import getDefaultFieldValues from 'app/selectors/card/getDefaultFieldValues';
import getNoCleanArea from 'app/selectors/card/getNoCleanArea';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['carriageway'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

/**
 * Классовый компонет CarriagewayCard.
 */
class CarriagewayCard extends React.Component {

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      card,
      changeFieldValue,
      coatingTypes,
      createMode,
      editMode,
      formValues,
      isEditCurrentObjectIsExternalSystem,
      mode,
      required,
    } = this.props;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <SelectRF
                  label={'Тип'}
                  name={'flat_element_type_id'}
                  options={card.flat_element_type}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                  required={isMandatory(
                    card.type_id,
                    'flat_element_type_id',
                    required,
                  )}
                />
                <IsDiffHeightMarkField />
                <ParentInfo />
              </FieldsSplitColumns>

              <Tabs tabs={tabs} />

              <div id="characteristics">
                <GroupContainer title={'Местоположение'}>
                  <FieldsSplitColumns>
                    <OdhAxisField />
                    <OdhSideIdField />
                    <BordBeginField />
                    <BordEndField />
                  </FieldsSplitColumns>
                </GroupContainer>

                <GroupContainer title={'Характеристики'}>
                  <FieldsSplitColumns>
                    <SelectRF
                      label={'Вид покрытия'}
                      name={'coating_group_id'}
                      options={card.coating_group}
                      disabled={
                        !(editMode && isEditCurrentObjectIsExternalSystem)
                      }
                      required={isMandatory(
                        card.type_id,
                        'coating_group_id',
                        required,
                      )}
                      withChange={() => {
                        changeFieldValue('coating_type_id', null);
                      }}
                    />
                    <SelectRF
                      label={'Вид покрытия (уточнение)'}
                      name={'coating_type_id'}
                      options={coatingTypes}
                      disabled={
                        !(
                          editMode &&
                          get(formValues, 'coating_group_id') &&
                          isEditCurrentObjectIsExternalSystem
                        )
                      }
                      required={isMandatory(
                        card.type_id,
                        'coating_type_id',
                        required,
                      )}
                    />
                    <DistanceField decimalScale={2} />
                    <AreaField />
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                    <WidthBeginField />
                    <WidthEndField />
                  </FieldsSplitColumns>
                </GroupContainer>

                <GroupContainer title={'Уборочные данные'}>
                  <SelectRF
                    label={'Способ уборки'}
                    name={'cleaning_type'}
                    options={cleaningTypesWithNoCleaning}
                    disabled={
                      !(editMode && isEditCurrentObjectIsExternalSystem)
                    }
                    required={isMandatory(
                      card.type_id,
                      'cleaning_type',
                      required,
                    )}
                  />
                </GroupContainer>
                <DescriptionField />
              </div>

              <div hidden={true} id={'docs'}>
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

const carriagewayDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const defaultFieldValues = getDefaultFieldValues(state, props);

  const result = {
    coatingTypes: getCoatingTypes(state, props),

    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),

    initialValues: {
      area: parse('area'),
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      cleaning_type: getCleaningType(state, props),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      distance: parse('distance'),
      endDate: parseDate(props.card.end_date),
      flat_element_type_id:
        defaultFieldValues.flat_element_type_id ||
        parse('flat_element_type_id.flat_element_type'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      reagent_area: parse('reagent_area'),
      snow_area: parse('snow_area'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
      width_begin: parse('width_begin'),
      width_end: parse('width_end'),
    },
    noCleanArea: getNoCleanArea(state, props),
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };

  return carriagewayDocumentsReducer(result, state, props);
};

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'distance',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'width_begin',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'width_end',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'auto_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'manual_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'no_clean_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'snow_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'rotor_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'reagent_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];

const dictFields = [
  'odh_side_id',
  'flat_element_type_id',
  'coating_group_id',
  'coating_type_id',
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => validateNumeric(values, numericFields);

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  formValuesWithAttribute.is_diff_height_mark = Boolean(
    formValuesWithAttribute.is_diff_height_mark,
  );

  formValuesWithAttribute.manual_clean_area = 0;
  formValuesWithAttribute.auto_clean_area = 0;
  formValuesWithAttribute.no_clean_area = 0;

  if (formValuesWithAttribute.cleaning_type === MANUAL_CLEANING_TYPE) {
    formValuesWithAttribute.manual_clean_area = formValuesWithAttribute.area;
  } else if (formValuesWithAttribute.cleaning_type === AUTO_CLEANING_TYPE) {
    formValuesWithAttribute.auto_clean_area = formValuesWithAttribute.area;
  } else {
    formValuesWithAttribute.no_clean_area = formValuesWithAttribute.area;
  }
  delete formValuesWithAttribute.cleaning_type;

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   * Изменения поля.
   *
   * @param {string} field - Имя поля.
   * @param {*} value - Значение для изменения.
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(CarriagewayCard));
