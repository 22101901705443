import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
import { TextField } from 'core/uiKit/inputs/TextField';
import React, { useMemo } from 'react';

/**
 * Выбор даты.
 *
 * @param {object} props - Props.
 * @param {object} props.value - Значение типа Moment.
 * @param {Function} [props.onBlur] - Функция на блюр элемента (просто вытягиваем что бы не передавать в комп а то глюки).
 * @param {string} [props.error] - Ошибки.
 * @param {string} [props.maxDate] - Максимальная дата '01.01.3000'.
 * @param {string} [props.minDate] - Максимальная дата '01.01.3000'.
 * @param {string} [props.minDateMessage] - Сообщение о минимальной дате.
 * @param {string} [props.maxDateMessage] - Сообщение о максимальной дате.
 * @param {boolean} [props.required] - Флаг обязательности поля.
 * @param {string} [props.label] - Лейбл.
 * @param {string} [props.name] - Имя инпута.
 * @param {object} [props.otherProps] - Дополнительные пропсы.
 * @returns {JSX.Element} - JSX.
 * @example -----
 */
export const DatePicker = ({
  value,
  label = 'KeyboardDatePicker',
  name = 'KeyboardDatePicker',
  error,
  maxDate = '01.01.3001',
  minDate,
  minDateMessage = 'Дата По не может быть раньше Даты С. Укажите корректное значение.',
  maxDateMessage = 'Дата С не может быть позже даты По. Укажите корректное значение.',
  required,
  ...otherProps
}) => {
  const propsData = useMemo(() => {
    return {
      error: !!error,
      helperText: error ? error : '',
    };
  }, [error]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={'ru'}
      localeText={{
        nextMonth: 'Следующий месяц',
        previousMonth: 'Предыдущий месяц',
      }}
    >
      <DesktopDatePicker
        inputFormat={'DD.MM.YYYY'}
        renderInput={(params) => (
          <TextField
            {...{
              ...params,
              ...propsData,
              inputProps: {
                ...params.inputProps,
                readOnly: true,
              },
              required,
            }}
          />
        )}
        {...{ label, name }}
        {...otherProps}
        {...{
          maxDateMessage,
          minDateMessage,
        }}
        maxDate={parseDateToMoment(maxDate)}
        minDate={parseDateToMoment(minDate)}
        value={value || null}
        onChange={(value) => otherProps.onChange(parseDateToMoment(value))}
      />
    </LocalizationProvider>
  );
};
