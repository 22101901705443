import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { useFormValuesCardRF } from 'core/form/reduxForm/hooks/useFormValuesCardRF';
import { useEffect } from 'react';
import { vi } from 'vitest';

import { useSetupIceRinkPlanarStructureField } from './useSetup.IceRinkPlanarStructureField';

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('core/form/reduxForm/hooks/useFormValuesCardRF');
vi.mock('core/form/reduxForm/hooks/useField.RF');
vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal(); // Явное приведение к объекту;
  return {
    ...mod,
    useEffect: vi.fn((fn) => {
      fn();
    }),
  };
});

/**
 * Дефолтные моки.
 *
 */
const defaultMock = () => {
  useMode.mockReturnValue({});
  useFormValuesCardRF.mockReturnValue({});
  useFieldRF.mockReturnValue({});
};

describe('🐛 UseSetupIceRinkPlanarStructureField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();

    // 🔥 Act
    const res = useSetupIceRinkPlanarStructureField();

    // ❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
    });
  });

  it('🧪 useFieldRF onChange called params', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();
    const onChangeSpy = vi.fn();
    useMode.mockReturnValue({ editMode: true });
    useFormValuesCardRF.mockReturnValue({
      planar_structure_type_id: '',
      planar_structure_type_ref_id: '',
    });
    useFieldRF.mockReturnValue({ onChange: onChangeSpy });

    // 🔥 Act
    useSetupIceRinkPlanarStructureField();

    // ❓ Assert
    expect(onChangeSpy).toHaveBeenCalledWith(false);
  });

  it('🧪 useEffect deps', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();
    const onChangeSpy = vi.fn();
    useMode.mockReturnValue({ editMode: true });
    useFormValuesCardRF.mockReturnValue({
      planar_structure_type_id: 1,
      planar_structure_type_ref_id: 2,
    });
    useFieldRF.mockReturnValue({ onChange: onChangeSpy });

    // 🔥 Act
    useSetupIceRinkPlanarStructureField();

    // ❓ Assert
    expect(useEffect).toHaveBeenCalledWith(expect.any(Function), [1, 2]);
  });

  it.each`
    planarStructureTypeId | planarStructureTypeRefId | expDisabled
    ${42}                 | ${1}                     | ${false}
    ${42}                 | ${2}                     | ${true}
  `(
    '🧪 returns disabled planarStructureTypeId=$planarStructureTypeId, expDisabled=$expDisabled',
    ({ planarStructureTypeId, expDisabled, planarStructureTypeRefId }) => {
      expect.hasAssertions();

      // ☣️ Arrange (всякие моки)
      defaultMock();
      useMode.mockReturnValue({ editMode: true });
      useFormValuesCardRF.mockReturnValue({
        planar_structure_type_id: planarStructureTypeId,
        planar_structure_type_ref_id: planarStructureTypeRefId,
      });

      // 🔥 Act
      const res = useSetupIceRinkPlanarStructureField();

      // ❓ Assert
      expect(res).toStrictEqual({
        disabled: expDisabled,
      });
    },
  );
});
