import React from 'react';

export const PreloadedCardContext = React.createContext({

  /**
   * Fn endPreload.
   *
   * @returns {void}
   */
  endPreload: () => {},

  /**
   * Fn startPreload.
   *
   * @returns {void}
   */
  startPreload: () => {},
});
