interface MafTypeLevel2 {
  maf_type_level2: number;
}

interface MafTypeLevel1 {
  maf_type_level1: number;
}

interface MafType {
  maf_type_level2_id: MafTypeLevel2;
  maf_type_level1_id: MafTypeLevel1;
}

/**
 * Функция подготовки значений mafTypeList для дальнейшей фильтрации.
 *
 * @param mafTypeList - Список полей.
 * @returns Подготовленный mafTypeList.
 */
export const preparationMafTypeList = (mafTypeList: MafType[]) => {
  return (mafTypeList || []).reduce(
    (
      obj: { mafTypeLevel1List: number[]; mafTypeLevel2List: number[] },
      mafType,
    ) => {
      if (mafType?.maf_type_level2_id?.maf_type_level2) {
        obj.mafTypeLevel2List.push(mafType.maf_type_level2_id.maf_type_level2);
      }

      if (mafType?.maf_type_level1_id?.maf_type_level1) {
        obj.mafTypeLevel1List.push(mafType.maf_type_level1_id.maf_type_level1);
      }

      return obj;
    },
    {
      mafTypeLevel1List: [],
      mafTypeLevel2List: [],
    },
  );
};
