import { RANDOM_ID } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import StoragePlaceCard, {
  formatAddressOption,
  onSubmit,
} from './storage_place';

vi.mock('app/components/card/common/validation');
vi.mock('./submit');
vi.mock('dateformat', () => ({
  DateFormat: vi.fn(),
}));

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', () => ({
  ...vi.importActual('react-redux'),
  connect: vi.fn(() => (ba) => {
    return ba;
  }),
}));

describe('StoragePlaceSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { type_id: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };
    // 🔥 Act
    const wrapper = shallow(<StoragePlaceCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardAbstract />');
  });
  it('fields', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { type_id: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };
    // 🔥 Act
    const wrapper = shallow(<StoragePlaceCard {...props} />);

    // ❓ Assert
    expect(wrapper.find('BikeParkTypeIdField').exists()).toBeTruthy();
    expect(wrapper.find('ParentInfo').exists()).toBeTruthy();
    expect(wrapper.find('IsDiffHeightMarkField').exists()).toBeTruthy();
    expect(
      wrapper.find('CharacteristicsTabStoragePlace').exists(),
    ).toBeTruthy();
  });
  it('🧪 есть таба CharacteristicsTabStoragePlace', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { type_id: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };
    // 🔥 Act
    const wrapper = shallow(<StoragePlaceCard {...props} />);

    // ❓ Assert
    expect(wrapper.find('CharacteristicsTabStoragePlace').length).toBe(1);
  });

  it('🧪 onSubmit', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 31 } };
    const onSubmitProps = onSubmit.bind({ props });
    // 🔥 Act

    onSubmitProps();
    // ❓ Assert
    expect(1).toBe(1);
  });

  it('🧪 formatAddressOption', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    const res = formatAddressOption({
      address_id: RANDOM_ID,
      address_name: 'address_name',
      district_name: 'district_name',
      okrug_name: 'okrug_name',
      street_name: 'street_name',
    });
    // ❓ Assert

    expect(res).toStrictEqual({
      id: RANDOM_ID,
      name: 'okrug_name / district_name / street_name / address_name',
    });
  });
});
