import {
  COATING_GROUP_ID_NAME,
  CoatingGroupId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import React from 'react';
import { Field } from 'redux-form';

/**
 * CoatingGroupIdRFWithReduxForm.
 *
 * @param {object} props - Пропсы, передаваемые в компонент.
 * @param {object} props.input - Свойства поля формы, предоставляемые `redux-form`.
 * @param {object} [props.otherProps] - Дополнительные пропсы, которые могут быть переданы в компонент `CoatingGroupId`.
 *
 * @returns {JSX.Element}
 */
export const CoatingGroupIdRFWithReduxForm = ({ input, ...props }) => {
  return <CoatingGroupId {...input} {...props} />;
};

/**
 * Компонент CoatingGroupIdRF.
 *
 * @param {object} props - Свойства компонента.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @param {object} [props.otherProps] - Дополнительные пропсы, которые будут переданы компоненту `CoatingGroupId`.
 *
 * @returns {JSX.Element}
 */
export const CoatingGroupIdRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      name={COATING_GROUP_ID_NAME}
      component={CoatingGroupIdRFWithReduxForm}
    />
  );
};
