import './SearchForm.scss';

import * as ordersActions from 'app/actions/orders';
import { ProgressStatusFF } from 'app/components/card/ogh/OrdersTab/components/ProgressStatusFF';
import WorkEndDate, {
  WORK_END_DATE,
} from 'app/components/card/ogh/OrdersTab/components/WorkEndDate';
import WorkStartDate, {
  WORK_START_DATE,
} from 'app/components/card/ogh/OrdersTab/components/WorkStartDate';
import dateFormatService from 'core/services/dateFormatService';
import { Button } from 'core/uiKit/components';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

const formStyles = {
  background: 'white',
};

/**
 * Компонент SearchForm.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
const SearchForm = (props) => {
  const { dark = true, onSubmit, initialValues } = props;

  const initialValuesMemo = useMemo(() => initialValues, [initialValues]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValuesMemo}
      dark={dark}
      render={({ handleSubmit, form }) => {
        return (
          <form
            onSubmit={handleSubmit}
            style={formStyles}
            className={
              'ods-head-form pb-3 ods-header-search-form order-tab__search-form'
            }
          >
            <div className={'order-tab__search-form__container'}>
              <WorkStartDate />
              <WorkEndDate />
              <ProgressStatusFF />
            </div>

            <div className={'order-tab__search-form__container'}>
              <Button variant={'contained'} color={'primary'} type="submit">
                Поиск
              </Button>
              <Button
                color={'primary'}
                id={'clear-form'}
                variant={'outlined'}
                onClick={form.reset}
              >
                Сбросить
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Стейт.
 * @returns {object}
 */
const mapStateToProps = (state) => {
  const { card, tree } = state;
  const cardSelect = tree?.object || card?.object;
  return {
    initialValues: {
      [WORK_END_DATE]: dateFormatService.parseDate(cardSelect.end_date),
      [WORK_START_DATE]: dateFormatService.parseDate(cardSelect.start_date),
      objectId: cardSelect.record_id,
    },
  };
};

/**
 * Функция mapDispatchToProps.
 *
 * @param {object} dispatch - Dispatch.
 * @returns {object}
 */
export function mapDispatchToProps(dispatch) {
  return {

    /**
     * Функция onSubmit.
     *
     * @param {object} values - Значение.
     * @returns {void}
     */
    onSubmit: function (values) {
      const parseDate = {
        [WORK_END_DATE]: dateFormatService.formatDate(values[WORK_END_DATE]),
        [WORK_START_DATE]: dateFormatService.formatDate(
          values[WORK_START_DATE],
        ),
      };
      dispatch(ordersActions.onSubmit({ ...values, ...parseDate }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
