import {
  RED_BOOK_PLANT_ID_NAME,
  RedBookPlantId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/RedBookPlantId';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент RedBookPlantId.
 *
 * @param {object} props - Пропсы компонента.
 * @param {string} [props.name] - Имя.
 * @param {boolean} [props.disabled] - Флаг для отключения поля (если `true`, поле становится недоступным для редактирования).
 * @param {object} [props.otherProps] - Дополнительные пропсы, передаваемые в `RedBookPlantId`.
 * @returns {JSX.Element}
 */
export const RedBookPlantIdFF = ({
  disabled = false,
  name = RED_BOOK_PLANT_ID_NAME,
  ...otherProps
}) => {
  return (
    <Field {...{ disabled, name }} component={RedBookPlantId} {...otherProps} />
  );
};

export { RED_BOOK_PLANT_ID_NAME };
