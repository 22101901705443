import { BUILDINGS_TYPE_NAME, BuildingType } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * BuildingTypeFF.
 *
 * @param {object} props - Пропсы, передаваемые в компонент.
 * @param {boolean} [props.disabled] - Определяет, будет ли компонент заблокирован.
 * @param {...object} [props.otherProps] - Другие пропсы, которые могут быть переданы компоненту `BuildingType`.
 * @returns {React.Element}
 */
export const BuildingTypeFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={BUILDINGS_TYPE_NAME}>
      {(props) => {
        return (
          <BuildingType
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { BUILDINGS_TYPE_NAME };
