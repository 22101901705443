import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { getCheckGeometryIntersectionAttributes } from 'app/utils/card/checkGeometry';
import { useMapglContext } from 'core/uiKit/components/DTW/contexts/useMapglContext';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

import { useDrawIntersectionsGeometriesDTW } from './api/useDrawIntersectionsGeometries.DTW';
import { useReduxCheckGeometryButton } from './useRedux.CheckGeometry.Button';

/**
 * Action checkGeometryByButton.
 *
 * @returns {void} - Nothing.
 */
export const useCheckGeometryByButtonDTW = () => {
  const context = useMapglContext();

  const parent = useGetParentCard();
  const { actions } = useGetParamsUrl();

  const parentInfo = actions?.haveParent ? parent : null;

  const {
    currentFormValues,
    hasIntersections,
    resetIntersectionsGeometry,
    showAlert,
    treeJournal,
    currentCard,
    card,
  } = useReduxCheckGeometryButton();

  const [sendDrawIntersections] = useDrawIntersectionsGeometriesDTW({
    showAlert,
  });

  return () => {
    if (!currentCard.record_id) return () => {};

    const { getGeometries, drawIntersections } = context;
    const geometry = getGeometries(currentCard.record_id);

    const data = {
      attribute: getCheckGeometryIntersectionAttributes(
        currentCard.type_id,
        currentFormValues,
        card.attribute,
      ),
      cnt: treeJournal !== null ? treeJournal.cnt_action : 0,
      geometry: {
        lines: JSON.stringify(geometry.lines),
        points: JSON.stringify(geometry.points),
        polygons: JSON.stringify(geometry.polygons),
      },
      id: currentCard.root_id,
      journal:
        treeJournal !== null ? JSON.stringify(treeJournal.actions) : null,
      parentId: currentCard.parent_id,
      startDate: currentCard.start_date,
      treeParentId: parentInfo ? parentInfo.recordId : null,
      typeId: currentCard.type_id,
    };

    if (hasIntersections) {
      drawIntersections([]);
      resetIntersectionsGeometry();
    } else {
      sendDrawIntersections(data);
    }
  };
};
