import { NumberField } from 'core/uiKit/inputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент WaterTypeIdFF.
 *
 * @param {object} props - Свойства компонента.
 * @param {string} [props.name] - Имя поля.
 * @param {object} [props.otherProps] - Дополнительные пропсы, которые будут переданы компоненту `CoatingGroupId`.
 *
 * @returns {JSX.Element}
 */
export const NumberFieldFF = ({ name, ...otherProps }) => {
  return (
    <Field name={name} type="text" defaultValue={otherProps.defaultValue}>
      {({ input: { value, onChange }, meta }) => {
        return (
          <NumberField
            {...otherProps}
            {...{ name, onChange, value }}
            {...{ meta }}
          />
        );
      }}
    </Field>
  );
};
