import { AlertDialog } from 'core/uiKit/components/dialogs/Alert.Dialog';
import React from 'react';

import { CheckGeometryButtonWithAlertDialog } from './CheckGeometry.Button.WithAlertDialog';

/**
 * Кнопка проверки пересечения геометрии со смежными ОГХ.
 *
 * @returns {JSX.Element}
 */
export const CheckGeometryButton = () => {
  return (
    <AlertDialog>
      <CheckGeometryButtonWithAlertDialog />
    </AlertDialog>
  );
};
