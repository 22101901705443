import './textField.scss';

import cn from 'classnames';
import { Icon, TextFieldMUI, Tooltip } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Компонент HelpTooltip.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.label - Any.
 * @param {*} props.helpingElement - Any.
 * @returns {JSX.Element}
 */
export const HelpTooltip = ({ label, helpingElement }) => {
  return helpingElement ? (
    <>
      {label}
      <Tooltip title={helpingElement}>
        <Icon className={'ml-1'} style={{ fontSize: 14 }}>
          help_outline
        </Icon>
      </Tooltip>
    </>
  ) : (
    label
  );
};

/**
 * Компонент TextField.
 *
 * @deprecated Используйте TextField из uiKit.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export default function TextField(props) {
  const {
    autoFocus,
    className,
    defaultValue,
    dark,
    disabled,
    error,
    FormHelperTextProps,
    fullWidth,
    helperText,
    id,
    InputLabelProps,
    InputProps,
    inputProps,
    inputRef,
    label,
    multiline,
    name,
    placeholder,
    required,
    rows,
    rowsMax,
    title,
    type,
    value,
    variant,
    onBlur,
    onChange,
    onClick,
    helpTitle,
    ...rest
  } = props;
  return (
    <TextFieldMUI
      autoFocus={autoFocus}
      className={cn(className, { TextField_Dark: dark })}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      FormHelperTextProps={FormHelperTextProps}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      inputProps={inputProps}
      inputRef={inputRef}
      label={<HelpTooltip helpingElement={helpTitle} label={label} />}
      multiline={multiline}
      name={name}
      placeholder={placeholder}
      required={!!required}
      rows={rows}
      rowsMax={rowsMax}
      title={title}
      type={type}
      value={value}
      variant={variant}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      data-testid={id}
      {...rest}
    />
  );
}
