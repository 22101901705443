import { TextField } from 'core/uiKit/inputs/TextField';

/**
 * Инпут для ввода телефона по маске.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const PhoneField = (props) => {
  return (
    <TextField
      {...props}
      mask={[
        /[1-9]/,
        ' ',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
      ]}
    />
  );
};
