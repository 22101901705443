import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';

import { useMafTypeLevel1Options } from '../MafTypeLevel1Id.Field/useMafTypeLevel1Options';

/**
 * Setup Number Field __Количество мест шт__.
 *
 * @returns
 */
export const useSetupMaQuantityCharacteristicsField = () => {
  const options = useMafTypeLevel1Options();
  const { maf_type_level1_id = 0 } = useFormValuesCardRF<{
    maf_type_level1_id: number;
  }>();

  const option = options.find((item) => item.id === maf_type_level1_id);

  const label = option?.quantity_name || 'Количество мест, шт.';
  const disabled = !option?.quantity_name;
  const required = Boolean(option?.quantity_name);

  return {
    disabled,
    label,
    options,
    required,
  };
};
