import {
  CLEANING_TYPE_NAME,
  CleaningType,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType';
import React from 'react';
import { Field } from 'redux-form';

/**
 * CleaningTypeRFWithReduxForm.
 *
 * @param {object} props - Пропсы, передаваемые в компонент.
 * @param {object} props.input - Свойства поля формы, предоставляемые `redux-form`.
 * @param {object} [props.otherProps] - Дополнительные пропсы, которые могут быть переданы в компонент `CleaningType`.
 *
 * @returns {JSX.Element}
 */
export const CleaningTypeRFWithReduxForm = ({ input, ...props }) => {
  return <CleaningType {...input} {...props} />;
};

/**
 * Компонент CleaningTypeRF.
 *
 * @param {object} props - Свойства компонента.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @param {object} [props.otherProps] - Дополнительные пропсы, которые будут переданы компоненту `CleaningType`.
 *
 * @returns {JSX.Element}
 */
export const CleaningTypeRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      name={CLEANING_TYPE_NAME}
      component={CleaningTypeRFWithReduxForm}
    />
  );
};
