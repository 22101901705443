import { useFormValuesCardRF as useFormValuesCardRFD } from 'core/form/reduxForm/hooks/useFormValuesCardRF';

/**
 * Хук получения текущих значения формы паспорта.
 *
 * @deprecated
 * @see core/form/reduxForm/hooks/useFormValuesCardRF
 * @returns Возвращает значение формы RF.
 */
export const useFormValuesCardRF = useFormValuesCardRFD;
