import { SECTION_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/SectionNum';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { Field } from 'react-final-form';

import { SectionNumWithFf } from './SectionNumWithFF';

/**
 * ## Номер участка.
 *
 * @param {*} props - Props.
 * @param {boolean} props.disabled - Disabled.
 * @param {object} props.otherProps - Other props for the component.
 * @returns {JSX.Element} - Rendered Section Number Field component.
 */
export const SectionNumFF = ({ disabled = false, ...otherProps }) => {
  usePreparation(SECTION_NUM_NAME, (value) => {
    if (value) {
      return Number(value);
    }
  });
  return (
    <Field
      disabled={disabled}
      name={SECTION_NUM_NAME}
      component={SectionNumWithFf}
      {...otherProps}
    />
  );
};
