import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { shallow } from 'enzyme';
import React from 'react';
import { Mock, vi } from 'vitest';

import { EngineeringStructuresTab } from './EngineeringStructures.Tab';

vi.mock('app/pages/cardsOgh/hooks/useEditMode');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  (useEditMode as Mock).mockReturnValue({});
};
describe('🐛 spec VerticalLandscaping.Tab', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<EngineeringStructuresTab propertyTab={'a'} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<TabBody />');
  });

  it('🧪 редактирование разрешено для всех', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();

    (useEditMode as Mock).mockReturnValue({ editMode: true });

    // 🔥 Act
    const wrapper = shallow(<EngineeringStructuresTab propertyTab={'a'} />);
    const fields = wrapper.find('NumberFieldRF');

    // ❓ Assert
    expect(
      fields.everyWhere((field) => {
        return field.prop('disabled') === false;
      }),
    ).toBeTruthy();
  });

  it('🧪 редактирование запрещено для всех', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<EngineeringStructuresTab propertyTab={'a'} />);
    const fields = wrapper.find('NumberFieldRF');

    // ❓ Assert
    expect(
      fields.everyWhere((field) => {
        return field.prop('disabled') === true;
      }),
    ).toBeTruthy();
  });

  it('🧪 fields', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<EngineeringStructuresTab propertyTab={'a'} />);

    // Мосты и путепроводы, шт.
    const bridge_qty = wrapper.findWhere(
      (item) => item.prop('name') === 'bridge_qty',
    );

    // ❓ Assert
    expect(bridge_qty.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Мосты и путепроводы, шт.',
      name: 'bridge_qty',
      required: true,
    });

    const bridge_psc = wrapper.findWhere(
      (item) => item.prop('name') === 'bridge_psc',
    );

    // ❓ Assert
    expect(bridge_psc.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Мосты и путепроводы, п.м',
      name: 'bridge_psc',
      required: true,
    });

    const bridge_sqr = wrapper.findWhere(
      (item) => item.prop('name') === 'bridge_sqr',
    );

    // ❓ Assert
    expect(bridge_sqr.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Мосты и путепроводы, кв.м',
      name: 'bridge_sqr',
      required: true,
    });

    const tube_qty = wrapper.findWhere(
      (item) => item.prop('name') === 'tube_qty',
    );

    // ❓ Assert
    expect(tube_qty.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Трубы, шт.',
      name: 'tube_qty',
      required: true,
    });

    const tube_psc = wrapper.findWhere(
      (item) => item.prop('name') === 'tube_psc',
    );

    // ❓ Assert
    expect(tube_psc.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Трубы, длина по лотку, п.м',
      name: 'tube_psc',
      required: true,
    });

    const snow_psc = wrapper.findWhere(
      (item) => item.prop('name') === 'snow_psc',
    );

    // ❓ Assert
    expect(snow_psc.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Снегозащитные посадки, п.м',
      name: 'snow_psc',
      required: true,
    });

    const decor_psc = wrapper.findWhere(
      (item) => item.prop('name') === 'decor_psc',
    );

    // ❓ Assert
    expect(decor_psc.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Декоративные посадки, п.м',
      name: 'decor_psc',
      required: true,
    });

    const pedestrian_qty = wrapper.findWhere(
      (item) => item.prop('name') === 'pedestrian_qty',
    );

    // ❓ Assert
    expect(pedestrian_qty.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Надземные пешеходные переходы, шт.',
      name: 'pedestrian_qty',
      required: true,
    });

    const pedestrian_psc = wrapper.findWhere(
      (item) => item.prop('name') === 'pedestrian_psc',
    );

    // ❓ Assert
    expect(pedestrian_psc.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Надземные пешеходные переходы, п.м',
      name: 'pedestrian_psc',
      required: true,
    });

    const field = wrapper.findWhere(
      (item) => item.prop('name') === 'pedestrian_psc',
    );

    // ❓ Assert
    expect(field.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Надземные пешеходные переходы, п.м',
      name: 'pedestrian_psc',
      required: true,
    });

    const pedestrian_sqr = wrapper.findWhere(
      (item) => item.prop('name') === 'pedestrian_sqr',
    );

    // ❓ Assert
    expect(pedestrian_sqr.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Надземные пешеходные переходы, кв.м',
      name: 'pedestrian_sqr',
      required: true,
    });

    const light_psc = wrapper.findWhere(
      (item) => item.prop('name') === 'light_psc',
    );

    // ❓ Assert
    expect(light_psc.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Линии наружного освещения, протяженность, п.м',
      name: 'light_psc',
      required: true,
    });

    const light_qty = wrapper.findWhere(
      (item) => item.prop('name') === 'light_qty',
    );

    // ❓ Assert
    expect(light_qty.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Линии наружного освещения, опоры, шт.',
      name: 'light_qty',
      required: true,
    });

    const lamp_qty = wrapper.findWhere(
      (item) => item.prop('name') === 'lamp_qty',
    );

    // ❓ Assert
    expect(lamp_qty.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Линии наружного освещения, плафоны/ светильники, шт.',
      name: 'lamp_qty',
      required: true,
    });

    const tlight_qty = wrapper.findWhere(
      (item) => item.prop('name') === 'tlight_qty',
    );

    // ❓ Assert
    expect(tlight_qty.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Светофорные объекты, шт.',
      name: 'tlight_qty',
      required: true,
    });

    const transform_qty = wrapper.findWhere(
      (item) => item.prop('name') === 'transform_qty',
    );

    // ❓ Assert
    expect(transform_qty.props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      label: 'Трансформаторная подстанция, шт.',
      name: 'transform_qty',
      required: true,
    });
  });
});
