import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useCheckCard } from './useCheckCard';

/**
 * Setup окна Подтверждения перейти на другой урл при режиме редактирования.
 *
 * @returns
 */
export const useSetupPromptEditMode = () => {
  const { editMode, loading, clearEditMode } = useMode();
  const { pathname } = useLocation();
  const isCard = useCheckCard();

  const clearEditModeMemo = useMemo(() => {
    if (/create/gi.test(pathname)) {
      return () => {};
    }
    return clearEditMode;
  }, [pathname, clearEditMode]);

  return {
    send: clearEditModeMemo,
    when: editMode && !loading && isCard,
  };
};
