import { useGetOghType } from 'core/utils/hooks/useGetOghType';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { pathContainer } from '../сontainer/Container/path.Container';
import { AbutmentLazy } from './Abutment';
import { pathAbutment } from './Abutment/path.Abutment';
import { BuildingLazy } from './Building';
import { pathBuilding } from './Building/path.Building';
import { ContainerOdhLazy } from './Container';
import { DtsLazy } from './Dts';
import { pathDts } from './Dts/path.Dts';
import { EngineerBuildingLazy } from './EngineerBuilding';
import { pathEngineerBuilding } from './EngineerBuilding/path.EngineerBuilding';
import { FlowersGardenLazy } from './FlowersGarden';
import { pathFlowersGarden } from './FlowersGarden/path.FlowersGarden';
import { FunctionalityLazy } from './Functionality';
import { pathFunctionality } from './Functionality/path.Functionality';
import { IceRinkTypeLazy } from './IceRinkType';
import { pathIceRinkType } from './IceRinkType/path.IceRinkType';
import { LawnLazy } from './Lawn';
import { pathLawn } from './Lawn/path.Lawn';
import { LittleFormLazy } from './LittleForm';
import { pathLittleForm } from './LittleForm/path.LittleForm';
import { OtherOksLazy } from './OtherOks';
import { pathOtherOks } from './OtherOks/path.OtherOks';
import { OtherTechPlaceLazy } from './OtherTechPlace';
import { pathOtherTechPlace } from './OtherTechPlace/path.OtherTechPlace';
import { PlanarStructureLazy } from './PlanarStructure';
import { pathPlanarStructure } from './PlanarStructure/path.PlanarStructure';
import { PpiLazy } from './Ppi';
import { pathPpi } from './Ppi/path.Ppi';
import { RedBookPlantLazy } from './RedBookPlant';
import { pathRedBookPlant } from './RedBookPlant/path.RedBookPlant';
import { ReliefLazy } from './Relief';
import { pathRelief } from './Relief/path.Relief';
import { StoragePlaceLazy } from './StoragePlace';
import { pathStoragePlace } from './StoragePlace/path.StoragePlace';
import { TechPlaceLazy } from './TechPlace';
import { pathTechPlace } from './TechPlace/path.TechPlace';
import { TreesShrubsLazy } from './TreesShrubs';
import { pathTreesShrubs } from './TreesShrubs/path.TreesShrubs';
import { VerticalLandscapingLazy } from './VerticalLandscaping';
import { pathVerticalLandscaping } from './VerticalLandscaping/path.VerticalLandscaping';
import { WaterBuildingLazy } from './WaterBuilding';
import { pathWaterBuilding } from './WaterBuilding/path.WaterBuilding';
import { YardLazy } from './Yard';
import { pathYard } from './Yard/path.Yard';

/**
 * Роутер Дворовые територии.
 *
 * @returns {JSX.Element}
 */
const YardRoute = () => {
  useEffect(() => {
    AbutmentLazy.preload();
    BuildingLazy.preload();
    ContainerOdhLazy.preload();
    DtsLazy.preload();
    EngineerBuildingLazy.preload();
    FlowersGardenLazy.preload();
    FunctionalityLazy.preload();
    IceRinkTypeLazy.preload();
    LawnLazy.preload();
    LittleFormLazy.preload();
    OtherOksLazy.preload();
    OtherTechPlaceLazy.preload();
    PlanarStructureLazy.preload();
    PpiLazy.preload();
    RedBookPlantLazy.preload();
    ReliefLazy.preload();
    StoragePlaceLazy.preload();
    TechPlaceLazy.preload();
    TreesShrubsLazy.preload();
    VerticalLandscapingLazy.preload();
    WaterBuildingLazy.preload();
    YardLazy.preload();
  });
  const { id: parentTypeId } = useGetOghType(pathYard.code);

  return (
    <Routes>
      <Route element={<YardLazy />} path={'/'} exact={true} />
      <Route
        element={<PlanarStructureLazy parentTypeId={parentTypeId} />}
        path={`${pathPlanarStructure.path}`}
      />
      <Route
        element={<OtherTechPlaceLazy parentTypeId={parentTypeId} />}
        path={`${pathOtherTechPlace.path}`}
      />
      <Route
        element={<BuildingLazy parentTypeId={parentTypeId} />}
        path={`${pathBuilding.path}`}
      />
      <Route
        element={<ContainerOdhLazy parentTypeId={parentTypeId} />}
        path={`${pathContainer.path}`}
      />
      <Route
        element={<StoragePlaceLazy parentTypeId={parentTypeId} />}
        path={`${pathStoragePlace.path}`}
      />
      <Route
        element={<TechPlaceLazy parentTypeId={parentTypeId} />}
        path={`${pathTechPlace.path}`}
      />

      <Route
        element={<DtsLazy parentTypeId={parentTypeId} />}
        path={`${pathDts.path}`}
      />
      <Route
        element={<PpiLazy parentTypeId={parentTypeId} />}
        path={`${pathPpi.path}`}
      />
      <Route
        element={<ReliefLazy parentTypeId={parentTypeId} />}
        path={`${pathRelief.path}`}
      />
      <Route
        element={<FunctionalityLazy parentTypeId={parentTypeId} />}
        path={`${pathFunctionality.path}`}
      />
      <Route
        element={<LittleFormLazy parentTypeId={parentTypeId} />}
        path={`${pathLittleForm.path}`}
      />
      <Route
        element={<LawnLazy parentTypeId={parentTypeId} />}
        path={`${pathLawn.path}`}
      />
      <Route
        element={<FlowersGardenLazy parentTypeId={parentTypeId} />}
        path={`${pathFlowersGarden.path}`}
      />
      <Route
        element={<TreesShrubsLazy parentTypeId={parentTypeId} />}
        path={`${pathTreesShrubs.path}`}
      />
      <Route
        element={<OtherOksLazy parentTypeId={parentTypeId} />}
        path={`${pathOtherOks.path}`}
      />
      <Route
        element={<WaterBuildingLazy parentTypeId={parentTypeId} />}
        path={`${pathWaterBuilding.path}`}
      />
      <Route
        element={<RedBookPlantLazy parentTypeId={parentTypeId} />}
        path={`${pathRedBookPlant.path}`}
      />
      <Route
        element={<VerticalLandscapingLazy parentTypeId={parentTypeId} />}
        path={pathVerticalLandscaping.path}
      />
      <Route
        element={<EngineerBuildingLazy parentTypeId={parentTypeId} />}
        path={pathEngineerBuilding.path}
      />

      <Route
        element={<AbutmentLazy parentTypeId={parentTypeId} />}
        path={pathAbutment.path}
      />

      <Route
        element={<IceRinkTypeLazy parentTypeId={parentTypeId} />}
        path={pathIceRinkType.path}
      />
    </Routes>
  );
};

export default YardRoute;
