import {
  DESCRIPTION_NAME,
  DescriptionField,
} from 'app/components/card/ogh/components/fields/textFields/Description.Field/Description.Field';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

vi.mock('app/pages/cardsOgh/hooks/useMode');

describe('🐛 DescriptionFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    // 🔥 Act

    const wrapper = shallow(<DescriptionField />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<TextFieldRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    // 🔥 Act

    const wrapper = shallow(<DescriptionField />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      label: 'Примечание',
      maxLength: 2,
      name: DESCRIPTION_NAME,
      tooltip: DESCRIPTION_NAME,
    });
  });
});
