/**
 * Функция необходимости проверки геометрии.
 *
 * @param {object} geometry - Параметр получаем из функции getGeometry(card.recordId) см. WithMapContext.
 * @param {number} typeIdParent - Парметр получаем из хука const {typeId} = useGetParentCard().
 * @param {Array} exceptionsArr - Массив исключений геометрии [SPA, TANK] app/constants/oghTypes.
 * @returns {boolean}
 */
export const checkGeometry = (
  geometry,
  typeIdParent = 0,
  exceptionsArr = [],
) => {
  if (exceptionsArr.includes(typeIdParent)) {
    return false;
  }
  return !Object.keys(geometry).length;
};
