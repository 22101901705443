import { Vin, VIN_NAME } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'redux-form';

export { VIN_NAME };

/**
 * Функция VinForm.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Объект, содержащий данные поля.
 * @returns {JSX.Element}
 */
export const VinForm = ({ input, ...props }) => {
  return <Vin {...input} {...props} />;
};

/**
 * Компонент VinRF.
 *
 * @param {object} props - Свойства компонента.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @param {object} [props.otherProps] - Дополнительные пропсы.
 * @returns {JSX.Element}
 */
export const VinRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      name={VIN_NAME}
      component={VinForm}
    />
  );
};
