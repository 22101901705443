import { useContext } from 'react';

import { MapglEditorContext } from './MapglEditorContextProvider';

/**
 * A custom hook that provides access to the MapglEditorContext.
 *
 * @returns The MapglEditorContext object.
 */
export const useMapglEditorContext = () => {
  const context = useContext(MapglEditorContext);

  if (context === undefined) {
    throw new Error(
      'useMapglServiceContext must be used within a MapglServiceContext',
    );
  }

  return context;
};
