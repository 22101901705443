import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useGetTreePanelData } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/hooks/useGetTreePanelData';
import {
  RANDOM_ID,
  RANDOM_NUMBER,
  RANDOM_WORD,
} from 'core/forTesting/constants';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useNavigateRouter } from 'core/utils/router';
import { shallow } from 'enzyme';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryParams } from 'use-query-params';
import { vi } from 'vitest';

import { CreateHOC } from './CreateHOC';

vi.mock('./card/IndexCard', () => ({

  /**
   * Мок карточки.
   *
   * @param {object} props - Пропсы.
   * @returns {React.ReactElement}
   */
  OghCard: (props) => <div {...props}>OghCard</div>,
}));

vi.mock('react-router-dom');
vi.mock('core/utils/router');
vi.mock('use-query-params');
vi.mock('core/utils/hooks/useGetParamsUrl');
vi.mock('app/pages/cardsOgh/components/Map/useActionsMap');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock(
  'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/hooks/useGetTreePanelData',
);
vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    useContext: vi.fn(),
    useEffect: vi.fn((fn) => fn()),
  };
});

vi.useFakeTimers();

/**
 * Дефолтные моки.
 *
 */
const defaultMock = () => {
  useGetParamsUrl.mockReturnValue({});
  useLocation.mockReturnValue({});
  useNavigateRouter.mockReturnValue({});
  useContext.mockReturnValue({});
  useGetParentCard.mockReturnValue({});
  useGetTreePanelData.mockReturnValue({});
  useQueryParams.mockReturnValue([]);
};

describe('🐛 CreateHocSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();
    const props = {
      createCard: vi.fn(),
      fetchPreviewCard: vi.fn(),
    };

    // 🔥 Act
    const wrapper = shallow(<CreateHOC {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<OghCard />');
  });

  it('🧪 test usEffect case 1', async () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();

    useQueryParams.mockReturnValue([{ foo: 123 }]);
    useGetParamsUrl.mockReturnValue({
      startDate: RANDOM_WORD,
      typeId: RANDOM_ID,
    });

    const createCardSpy = vi.fn();
    const fetchPreviewCardSpy = vi.fn();
    const props = {
      createCard: createCardSpy,
      fetchPreviewCard: fetchPreviewCardSpy,
    };

    // 🔥 Act
    shallow(<CreateHOC {...props} />);
    await vi.runOnlyPendingTimersAsync();
    // ❓ Assert
    expect(createCardSpy).toHaveBeenCalledWith(RANDOM_ID, RANDOM_WORD, {
      foo: 123,
    });
    expect(fetchPreviewCardSpy).toHaveBeenCalledWith(RANDOM_ID, RANDOM_WORD);
  });

  it('🧪 test usEffect case 2', async () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();

    useQueryParams.mockReturnValue([{ foo: 123 }]);
    useGetParamsUrl.mockReturnValue({
      actions: { haveParent: true },
      startDate: RANDOM_WORD,
      typeId: RANDOM_ID,
    });
    useGetParentCard.mockReturnValue({ typeId: RANDOM_NUMBER });

    const createCardSpy = vi.fn();
    const fetchPreviewCardSpy = vi.fn();
    const props = {
      createCard: createCardSpy,
      fetchPreviewCard: fetchPreviewCardSpy,
    };

    // 🔥 Act
    shallow(<CreateHOC {...props} />);
    await vi.runOnlyPendingTimersAsync();
    // ❓ Assert
    expect(createCardSpy).toHaveBeenCalledWith(RANDOM_ID, RANDOM_WORD, {
      foo: 123,
      parentTypeId: RANDOM_NUMBER,
    });
    expect(fetchPreviewCardSpy).toHaveBeenCalledWith(RANDOM_ID, RANDOM_WORD);
  });
});
