import { MapService } from './utils/MapService';

/**
 * Finds editable geometries based on the provided map service and editing status.
 *
 * @param {MapService | null} mapService - The map service to retrieve geometries from.
 * @param {boolean} isEditing - A flag indicating if editing is enabled.
 * @returns A function that returns an object containing editable geometries.
 */
export const findEditableGeometries =
  (mapService: MapService | null, isEditing: boolean) => () => {
    if (!isEditing || !mapService) return null;
    const { parent, children } = mapService.layers;
    const point = parent.point
      .filter((point) => point.userData.oghObjectId === mapService.selectedId)
      .concat(
        children.point.filter(
          (point) => point.userData.oghObjectId === mapService.selectedId,
        ),
      );

    const polygon = parent.polygon
      .filter(
        (polygon) => polygon.userData.oghObjectId === mapService.selectedId,
      )
      .concat(
        children.polygon.filter(
          (polygon) => polygon.userData.oghObjectId === mapService.selectedId,
        ),
      );

    const polyline = parent.polyline
      .filter(
        (polyline) => polyline.userData.oghObjectId === mapService.selectedId,
      )
      .concat(
        children.polyline.filter(
          (polyline) => polyline.userData.oghObjectId === mapService.selectedId,
        ),
      );

    return {
      point,
      polygon,
      polyline,
    };
  };
