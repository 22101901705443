import { KeyboardDatePicker } from 'core/uiKit/inputs/datePickers/KeyboardDatePicker';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Обертка для компонента KeyboardDatePicker.
 *
 * @param {object} props - Пропсы компонента.
 * @param {string} [props.name] - Имя поля.
 * @param {boolean} [props.disabled] - Флаг для отключения компонента.
 * @param {string} [props.label] - Подпись, отображаемая над полем даты.
 * @param {object} [props.otherProps] - Дополнительные пропсы, передаваемые компоненту `KeyboardDatePicker`.
 *
 * @returns {React.Element}
 */
export const KeyboardDatePickerFF = ({
  name = 'KeyboardDateTimePickerFF',
  disabled = false,
  label = 'label for KeyboardDateTimePickerFF',
  ...otherProps
}) => {
  return (
    <Field name={name}>
      {(props) => {
        const { meta } = props;
        return (
          <KeyboardDatePicker
            {...{ disabled, label, name }}
            error={meta.error || meta.submitError}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
