import { OGH_STATUSES_NAME, OghStatuses } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент OghStatuses.
 *
 * @param {object} props - Пропсы, передаваемые в компонент.
 * @param {boolean} [props.disabled] - Флаг, указывающий, заблокирован ли компонент для редактирования.
 * @param {object} [props.otherProps] - Остальные пропсы, которые могут быть переданы в компонент.
 * @returns {JSX.Element}
 */
export const OghStatusesFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={OGH_STATUSES_NAME}>
      {(props) => {
        return (
          <OghStatuses
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { OGH_STATUSES_NAME };
