import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  ICE_RINK_PLANAR_STRUCTURE_NAME,
  IceRinkPlanarStructure,
} from 'core/uiKit/preparedInputs/checkboxes/IceRinkPlanarStructure';
import React from 'react';
import { SelectRFProps } from 'types/inputs';

export { ICE_RINK_PLANAR_STRUCTURE_NAME };

/**
 * Checkbox RF __Каток открытый с естественным льдом на существующей спортивной площадке спортивного типа__.
 *
 * @param props - Пропсы.
 * @param props.disabled - Флаг возможности редактирования.
 * @param props.label - Лейбл если есть, по умолчанию "Не учитывать".
 * @returns
 */
export const IceRinkPlanarStructureRF = (props: SelectRFProps) => {
  return (
    <FieldRF
      component={IceRinkPlanarStructure}
      {...props}
      name={ICE_RINK_PLANAR_STRUCTURE_NAME}
    />
  );
};
