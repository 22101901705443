import { KeyboardDatePicker } from 'core/uiKit/inputs';
import React from 'react';
import { Field } from 'redux-form';

/**
 * KeyboardDatePicker.
 *
 * @param {object} params - Parameters.
 * @param {object} params.input - Пропсы, передаваемые от Redux Form (например, value, onChange).
 * @param {boolean} params.disabled - Флаг для отключения компонента.
 * @param {string} params.label - Лейбл для компонента.
 * @param {string} params.name - Имя поля для Redux Form.
 * @param {object} params.props - Дополнительные пропсы, передаваемые в `KeyboardDatePicker`.
 * @returns {JSX.Element}
 */
const KeyboardDatePickerWithReduxForm = ({
  input,
  disabled = false,
  label = 'label for KeyboardDatePickerRF',
  name = 'KeyboardDatePickerRF',
  ...props
}) => {
  return (
    <KeyboardDatePicker
      error={props?.meta?.error}
      {...{ disabled, label, name }}
      {...input}
      {...props}
    />
  );
};

/**
 * KeyboardDatePicker.
 *
 * @param {object} params - Parameters.
 * @param {boolean} params.disabled - Флаг для отключения компонента.
 * @param {string} params.name - Имя поля для Redux Form.
 * @param {string} params.label - Лейбл для компонента.
 * @param {object} params.otherProps - Дополнительные пропсы, передаваемые в `Field` компонент от Redux Form.
 * @returns {JSX.Element}
 */
export const KeyboardDatePickerRF = ({
  disabled = false,
  name = 'KeyboardDatePickerRF',
  label = 'label for KeyboardDatePickerRF',
  ...otherProps
}) => {
  return (
    <Field
      name={name}
      component={KeyboardDatePickerWithReduxForm}
      {...{ disabled, label, name }}
      {...otherProps}
    />
  );
};
