import { shallow } from 'enzyme';
import { vi } from 'vitest';

import {
  MAF_TYPE_LEVEL_1_ID_NAME,
  MafTypeLevel1IdField,
} from './MafTypeLevel1.Field';
import { useMafTypeLevel1Options } from './useMafTypeLevel1Options';

vi.mock('./useMafTypeLevel1Options');
vi.mock('app/actions/odsObjectActions/useChangeFieldValueRF');

describe('🐛 MafTypeLevel1Spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useMafTypeLevel1Options.mockReturnValue({
      options: [],
    });

    // 🔥 Act
    const wrapper = shallow(<MafTypeLevel1IdField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<SelectRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useMafTypeLevel1Options.mockReturnValue([]);

    // 🔥 Act
    const wrapper = shallow(<MafTypeLevel1IdField />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      label: 'Вид МАФ',
      name: MAF_TYPE_LEVEL_1_ID_NAME,
      options: [],
      withAll: false,
    });
  });
});
