import {
  NET_ELEMENT_TYPE_ID_NAME,
  NetElementTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/NetElementTypeId';
import React from 'react';
import { Field } from 'redux-form';

/**
 *
 * Компонент NetElementTypeIdRFWithReduxForm.
 *
 * @param {object} params - Parameters.
 * @param {object} params.input - Input.
 * @param {object} params.props - Properties.
 * @returns {JSX.Element}
 */
export const NetElementTypeIdRFWithReduxForm = ({ input, ...props }) => {
  return <NetElementTypeId {...input} {...props} />;
};

/**
 *
 * Компонент NetElementTypeIdRF.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.disabled - Disabled.
 * @param {object} props.otherProps - Other properties.
 * @returns {JSX.Element}
 */
export const NetElementTypeIdRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      name={NET_ELEMENT_TYPE_ID_NAME}
      component={NetElementTypeIdRFWithReduxForm}
    />
  );
};

export { NET_ELEMENT_TYPE_ID_NAME };
