import { ID_RFID } from 'core/uiKit/preparedInputs/textField/IdRfid';
import React from 'react';
import { Field } from 'redux-form';

import { IdRfidWithReduxForm } from './IdRfidWithReduxForm';

/**
 * IdRfidRF.
 *
 * @param {object} props - Properties.
 * @param {boolean} [props.disabled] - Disabled.
 * @param {object} [props.otherProps] - Other properties.
 * @returns {JSX.Element}
 */
export const IdRfidRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      component={IdRfidWithReduxForm}
      name={ID_RFID}
    />
  );
};
