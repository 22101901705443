import { getFieldError } from 'app/utils/validate/getFieldError';
import { useEditOnchangeEffect } from 'core/uiKit/inputs/selects/Select/useEditOnchangeEffect';

import { useGetFilteredOptions } from './useGetFilteredOptions';

/**
 * Компонент селекта.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {boolean} [props._multiple] - Хопа.
 * @param {*} [props.optionKey] - Ключ.
 * @param {*} [props.optionName] - Жопа.
 * @param {Array} [props.options] - Жопа.
 * @param {string} [props.value] - Значение.
 * @param {*} [props.meta] - Жопа.
 * @param {Function} [props.onChange] - Функция изменения значения.
 * @param {Function} [props.withChange] - Функция изменения других полей.
 * @param {boolean} [props.disabled] - Флаг редактирования.
 * @param {Function} [props.filter] - Функция фильтрации.
 * @returns {object}
 */
export const useSetupSelect = ({
  _multiple = false,
  disabled,
  errorText,
  filter,
  meta = {},
  onChange,
  optionKey,
  optionName,
  options,
  value,
  withChange,
}) => {
  const error = getFieldError(meta);

  /**
   * Функция изменения инпута с сайд эфектом withChange.
   *
   * @param {Event} event - Объект Event.
   * @returns {void} - Nothing.
   * @example
   * <Component onChange={handleChange}/>
   */
  const handleChange = (event) => {
    const { value } = event.target;
    if (typeof withChange === 'function') {
      withChange(value);
    }

    onChange(value);
  };

  /**
   * Фильтруем опшины по архивности.
   *
   * @param {Array} options - Опшины.
   * @returns {Array} - Опшины.
   */
  const filterNonArchive = (options) => {
    const optionsWithoutArchive = (options || []).filter(
      (item) => !item.is_archive,
    );
    if (typeof filter !== 'function') {
      return optionsWithoutArchive;
    }
    return filter(optionsWithoutArchive);
  };

  // сбрасываем неправильное значение при переходе в редактирование
  // делаем так потому что надо показывать валуе даже если нет в этого в фильтре но только при просмотре .
  useEditOnchangeEffect({
    editMode: !disabled,
    filter: filterNonArchive,
    handleChange,
    optionKey,
    options,
    value,
    withChange,
  });

  const getFilteredOptions = useGetFilteredOptions({
    disabled,
    filter: filterNonArchive,
    optionKey,
    optionName,
    options,
    value,
  });

  // Проверяем, что value есть среди доступных опций
  const isValidValue = options?.some((option) => {
    if (_multiple) {
      return Array.isArray(value) && value.includes(option[optionKey]);
    } else {
      return option[optionKey] === value;
    }
  });

  // Если значение невалидно, устанавливаем пустое значение
  const customValue = isValidValue ? value : _multiple ? [] : '';

  const isError = !!error || !!errorText;

  return { customValue, error, getFilteredOptions, handleChange, isError };
};
