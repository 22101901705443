import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { validateNumeric } from 'app/components/card/common/validation';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import MarginCard, { onSubmit, validate } from './margin';

vi.mock('app/components/card/common/validation');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock('./submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('MarginCard', () => {
  it('default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'PpiCard',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,

      mode: {},
    };
    useGetParentCard.mockReturnValue({ typeId: 1 });

    // 🔥 Act
    const wrapper = shallow(<MarginCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });
});

describe('🐛 onSubmit', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 31 } };
    const onSubmitProps = onSubmit.bind({ props });

    // 🔥 Act
    onSubmitProps();

    // ❓ Assert
    expect(1).toBe(1);
  });

  it('🧪 validate', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    validate({});
    // 🔥 Act

    // ❓ Assert
    expect(validateNumeric).toHaveBeenCalledWith({}, [
      { name: 'bord_begin', type: 'decimal', zero: true },
      { name: 'bord_end', type: 'decimal', zero: true },
      {
        name: 'area',
        positive: true,
        type: 'decimal',
        zero: false,
      },
      {
        name: 'distance',
        positive: true,
        type: 'decimal',
        zero: false,
      },
      {
        name: 'width_begin',
        positive: true,
        type: 'decimal',
        zero: false,
      },
      {
        name: 'width_end',
        positive: true,
        type: 'decimal',
        zero: false,
      },
      {
        name: 'auto_clean_area',
        positive: true,
        type: 'decimal',
        zero: false,
      },
      {
        name: 'manual_clean_area',
        positive: true,
        type: 'decimal',
        zero: false,
      },
      {
        name: 'no_clean_area',
        positive: true,
        type: 'decimal',
        zero: true,
      },
      {
        name: 'snow_area',
        positive: true,
        type: 'decimal',
        zero: false,
      },
      {
        name: 'rotor_area',
        positive: true,
        type: 'decimal',
        zero: false,
      },
    ]);
  });
});
