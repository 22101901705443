import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import {
  CREATE_CARD,
  CREATE_FROM_PARENT,
  UPDATE_CARD,
  UPDATE_FROM_PARENT,
  useTypeActionCard,
} from 'app/pages/cardsOgh/hooks/useTypeActionCard';
import { log } from 'core/utils/log';
import { switchServisMap } from 'core/utils/map/switchServisMap';

import { usePreloaderCard } from '../../components/PreloadedCard/usePreloaderCard';
import { useCreateForm } from '../useCreateForm';
import { useUpdateForm } from '../useUpdateForm';
import { useGetCheckFn } from './useGetCheckFn';

/**
 * Функция проверки перед сохранением с проверкой пересечений.
 *
 * @param root0 - Properties.
 * @param root0.showAlert - Показывает предупреждение.
 * @returns Фцнкцию сохранения.
 */
export const useGetSavingFn = ({
  showAlert,
}: {
  showAlert: (message: string) => void;
}) => {
  const card = useGetCurrentCard();

  const parent = useGetParentCard();

  const { updateForm } = useUpdateForm({ showAlert });
  const { fetchCreateForm } = useCreateForm({ showAlert });
  const typeAction = useTypeActionCard();

  const { getGeometry, getGeometryToJson } = useMapContext();

  const getGeometryData = switchServisMap({
    // @ts-ignore
    dtw: getGeometryToJson,
    egip: getGeometry,
  });
  const checkFn = useGetCheckFn();

  const { startPreload } = usePreloaderCard();
  return async ({
    values,
    attribute,
    otherParams = {},
  }: {
    values: Record<string, unknown>;
    attribute: Record<string, unknown>;
    otherParams?: Record<string, unknown>;
  }) => {
    startPreload();

    const geometry = getGeometryData(card.recordId);

    await checkFn({
      geometry,
      values,
    });

    const prepareData = {
      ...geometry,
      attribute,
      childObjectTypeId: card.typeId,
      endDate: values.endDate,
      id: card.recordId,
      record_id: card.recordId,
      root_id: card.rootId,
      startDate: values.startDate,
      tree_actions: {},
      tree_count_actions: 0,
      type: card.typeId,
      ...otherParams,
    };

    switch (typeAction) {
      case CREATE_FROM_PARENT: {
        return fetchCreateForm({
          ...prepareData,
          is_child: true,
          parent_id: parent.recordId,
          path_object_type_id: card.typeId,
          root_object_id: parent.recordId,
          root_object_type_id: parent.typeId,
        });
      }
      case UPDATE_FROM_PARENT: {
        return updateForm({
          ...prepareData,
          is_child: true,
          old_child_object_id: card.recordId,
          parent_id: parent.recordId,
          path_object_type_id: card.typeId,
          root_object_id: parent.recordId,
          root_object_type_id: parent.typeId,
        });
      }
      case CREATE_CARD: {
        return fetchCreateForm({
          ...prepareData,
          is_child: false,
          parent_id: card.root_id,
          root_object_id: card.rootId,
          root_object_type_id: card.typeId,
        });
      }
      case UPDATE_CARD: {
        return updateForm({
          ...prepareData,
          is_child: false,
          old_child_object_id: card.recordId,
          root_object_id: card.rootId,
          root_object_type_id: card.typeId,
        });
      }
      default:
        log._banana('Тип действия не определен');
    }
  };
};
