import { LAWN_TYPE_NAME, LawnType } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент LawnType.
 *
 * @param {object} props - Пропсы компонента.
 * @param {boolean} [props.disabled] - Флаг для отключения поля (если `true`, поле становится недоступным для редактирования).
 * @param {object} [props.otherProps] - Дополнительные пропсы, передаваемые в `LawnType`.
 * @returns {JSX.Element}
 */
export const LawnTypeFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={LAWN_TYPE_NAME}>
      {(props) => {
        return (
          <LawnType
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { LAWN_TYPE_NAME };
