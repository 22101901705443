import { ToggleButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ToggleButton';
import React from 'react';

import { useSetupCheckGeometryButton } from './useSetup.CheckGeometry.Button';

const content = {
  off: {
    className: 'geom-button check-geometry-off material-icons',
    title: 'Показать пересечения со смежными ОГХ',
  },
  on: {
    className: 'geom-button check-geometry-on material-icons',
    title: 'Скрыть пересечения со смежными ОГХ',
  },
};

/**
 * Кнопка проверки пересечения геометрии со смежными ОГХ с алертом.
 *
 * @param {object} props - Пропсы.
 * @param {Function} [props.showAlert] - Функция отображения сообщения.
 * @returns {JSX.Element}
 */
export const CheckGeometryButtonWithAlertDialog = ({ showAlert }) => {
  const { checkGeometryButton, hasIntersections, isMapLoaded, isLoading } =
    useSetupCheckGeometryButton({ showAlert });

  return (
    <ToggleButton
      disabled={!isMapLoaded}
      active={hasIntersections}
      content={content}
      onClick={checkGeometryButton}
      isLoading={isLoading}
    />
  );
};
