import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { fetchOghTypes } from 'core/uiKit/preparedInputs/api';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

type IMafTypeLevel1Options = {
  id: number;
  name: string;
  quantity_name: string;
}[];

/**
 * Получения опшинов поля Вид МАФ.
 *
 * @returns
 */
export const useMafTypeLevel1Options = (): IMafTypeLevel1Options => {
  const { typeId: parentTypeId } = useGetParentCard();

  const { data: options = [] } = useQueryAdaptor(
    ['optionsMafTypeLevel1AutoComplete', { parentTypeId, typeId: 37 }],
    fetchOghTypes,
    {
      enabled: Boolean(parentTypeId),
    },
  ) as { data: IMafTypeLevel1Options | undefined };

  return options;
};
