import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';
import React from 'react';
import { Field } from 'redux-form';

import { QuantityWithReduxForm } from './QuantityWithReduxForm';

/**
 * Компонент QuantityRf.
 *
 * @param {object} props - Пропсы компонента.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @returns {JSX.Element} - Возвращает компонент Quantity обернутый в redux form.
 * @example <QuantityRf {...props}/>
 */
export const QuantityRf = ({ disabled = false, ...props }) => {
  return (
    <Field
      disabled={disabled}
      {...props}
      component={QuantityWithReduxForm}
      name={QUANTITY_NAME}
    />
  );
};
