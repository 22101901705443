import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { INTERSECTIONS } from 'app/constants/layers';
import { switchServisMap } from 'core/utils/map/switchServisMap';

/**
 * Хук получения данных геометрии.
 *
 * @returns Данные геометрии.
 */
export const useDrawDrawIntersectionsGeometry = () => {
  const currentCard = useGetCurrentCard();
  const {
    clearLayer = () => {},
    drawIntersections = () => {},
    drawIntersectionsGeometry = () => {},
    getGeometryToJson = () => {},
    getGeometry = () => {},
  } = useMapContext();

  return switchServisMap({
    //

    /**
     *
     * @param geometry
     */
    dtw: {
      //

      /**
       * Наричовать гометрию.
       *
       * @param {object} geometry - Геометрия.
       */
      drawIntersections: (
        geometry: Parameters<typeof drawIntersections>[0],
      ) => {
        if (geometry !== undefined) {
          drawIntersections(geometry);
        }
      },
      geometry: getGeometryToJson(currentCard.record_id),
    },
    egip: {
      //

      /**
       * Наричовать гометрию.
       *
       * @param {object} geometry - Геометрия.
       */
      drawIntersections: (
        geometry: Parameters<typeof drawIntersectionsGeometry>[0],
      ) => {
        clearLayer(INTERSECTIONS);
        if (geometry !== undefined) {
          drawIntersectionsGeometry(geometry);
        }
      },

      geometry: getGeometry(currentCard.record_id),
    },
  });
};
