export const columns = [
  {
    Header: 'Номер участка',
    accessor: 'section_num',
    rowSpan: 2,
  },
  {
    Header: 'Площадь газона, кв. м.',
    accessor: 'total_lawn_area',
    rowSpan: 2,
  },
  {
    Header: 'Тип газона, кв. м',
    accessor: 'lawn_type',
    columns: [
      {
        Header: 'Обыкновенный, кв.м.',
        accessor: 'lawn_type_plain_area',
      },
      {
        Header: 'Луговой / разнотравный, кв.м.',
        accessor: 'lawn_type_meadow_area',
      },
      {
        Header: 'Партерный, кв.м.',
        accessor: 'lawn_type_parterre_area',
      },
      {
        Header: 'На откосе, кв.м.',
        accessor: 'lawn_type_slope_area',
      },
      {
        Header: 'Иного типа, кв.м.',
        accessor: 'lawn_type_other_area',
      },
    ],
  },
  {
    Header: 'Состояние газона',
    accessor: 'state_gardening',
    columns: [
      {
        Header: 'Хорошее',
        accessor: 'state_gardening_1',
      },
      {
        Header: 'Удовлетворительное',
        accessor: 'state_gardening_2',
      },
      {
        Header: 'Неудовлетворительное',
        accessor: 'state_gardening_3',
      },
    ],
  },
];
