import './text-field.scss';

import TextFieldMui from '@mui/material/TextField';
import { getFieldError } from 'app/utils/validate/getFieldError';
import { Icon, Tooltip } from 'core/uiKit/material-ui';
import React, { useEffect, useState } from 'react';

import { TextMask } from './TextMask';

/**
 * Текстовое поле. Маска используется от react-text-mask.
 * ```js
 * import { TextField } from 'core/uiKit/inputs';.
 * ```.
 *
 * @param {object} props - Props.
 * @param {boolean} [props.autoFocus] - Хопа.
 * @param {boolean} [props.autoComplete] - Хопа.
 * @param {string} [props.color] - Хопа.
 * @param {boolean} [props.clear] - Хопа.
 * @param {boolean} [props.dark] - Хопа.
 * @param {boolean} [props.disabled] - Отвечает за не активный элемент.
 * @param {object} [props.style] - Хопа.
 * @param {string} [props.defaultValue] - Хопа.
 * @param {boolean} [props.error] - Хопа.
 * @param {boolean} [props.multiline] - Одна строка или много.
 * @param {string} [props.errorText] - Хопа.
 * @param {*} [props.helperText] - Хопа.
 * @param {*} [props.helpTitle] - Хопа.
 * @param {*} [props.inputLabelProps] - Хопа.
 * @param {*} [props.label] - Хопа.
 * @param {*} [props.mask] - Хопа.
 * @param {*} [props.maxLength] - Хопа.
 * @param {*} [props.meta] - Хопа.
 * @param {*} [props.onChange] - Хопа.
 * @param {*} [props.tooltip] - Хопа.
 * @param {'standart'|'outlined'} [props.variant] - Оформление инпута.
 * @param {*} [props.value] - Хопа.
 * @param {string} [props.name] - Имя инпута.
 * @param {*} [props.withChange] - Хопа.
 * @param {*} [props.formatChars] - A.
 * @param {boolean} [props.required] - Флаг обязательности поля.
 * @param {string} [props.maxDateMessage] - Сообщение о максимальной дате.
 * @param {string} [props.minDateMessage] - Сообщение о минимальной дате.
 * @returns {JSX.Element} - JSX.
 * @example -------
 */
export const TextField = ({
  autoFocus = false,
  color = 'primary',
  dark = false,
  disabled = false,
  helperText = '',
  multiline = false,
  required = false,
  clear,
  defaultValue,
  error,
  errorText,
  formatChars,
  helpTitle,
  inputLabelProps = {},
  label,
  mask,
  maxLength = 255,
  meta = {},
  onChange = (_) => _,
  tooltip = '',
  value = '',
  variant = 'standard',
  withChange,
  ...props
}) => {
  const [oldValue, setOldValue] = useState('');
  const [textLength, setTextLength] = useState(null);
  useEffect(() => {
    if (clear) {
      setOldValue(value);
      onChange('');
    }
    if (clear + [] === 'false') {
      onChange(oldValue);
    }
    // eslint-disable-next-line
  }, [clear]);

  const errorT = getFieldError(meta);
  let isError = Boolean(error || errorT || errorText);
  let text = isError ? errorT || errorText || helperText : helperText;

  /**
   * Функция обработчик изменения.
   *
   * @param {{target: *}} target - Таргет.
   * @returns {void} - Nothing.
   * @example -------
   */
  const handlerChange = ({ target }) => {
    const { value = '' } = target;
    if (value.length >= maxLength) {
      setTextLength(`Можно ввести не более ${maxLength} символов`);
      setTimeout(setTextLength, 2000);
    }
    if (typeof withChange === 'function') {
      withChange(value);
    }
    onChange(value.substr(0, maxLength));
  };
  const isMask = !!mask;

  const inputProps = isMask
    ? {
        InputProps: {
          inputComponent: TextMask,
        },
        inputProps: {
          'data-testid': props.name,
          formatChars,
          mask,
        },
      }
    : {
        inputProps: {
          'data-testid': props.name,
        },
      };

  const textFieldProps = {
    ...(color && { color }),
    ...(dark && { dark: true }),
    ...(dark === false && { dark: 'false' }),
    ...(disabled && { disabled: true }),
    ...(multiline && { multiline: true }),
    ...(required && { required: true }),
  };

  return (
    <Tooltip title={tooltip}>
      <TextFieldMui
        sx={{ width: '100%' }}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        {...inputProps}
        {...textFieldProps}
        {...props}
        variant={variant}
        InputLabelProps={{
          ...inputLabelProps,
          'data-testid': 'label__' + props.name,
          sx: {
            zIndex: 1,
          },
        }}
        label={
          <>
            {label}
            {helpTitle ? (
              <Tooltip title={helpTitle}>
                <Icon className={'ml-1'} style={{ fontSize: 14 }}>
                  help_outline
                </Icon>
              </Tooltip>
            ) : null}
          </>
        }
        error={isError}
        helperText={textLength || text || <span>&nbsp;</span>}
        value={value}
        onChange={handlerChange}
      />
    </Tooltip>
  );
};
