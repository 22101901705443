import { Select } from 'core/uiKit/inputs/selects/Select';
import { log } from 'core/utils/log';
import React from 'react';

import { handler } from './handler';

/**
 * Компонент МультиСелекта.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.className] - Класс.
 * @param {boolean} [props.dark] - Флаг переключения темя.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {string} [props.helperText] - Текс помощи.
 * @param {string} [props.label] - Лейбл.
 * @param {boolean} [props.multiple] - Хопа.
 * @param {*} [props.optionKey] - Ключ.
 * @param {*} [props.optionName] - Жопа.
 * @param {Array} [props.options] - Жопа.
 * @param {string} [props.value] - Значение.
 * @param {*} [props.meta] - Жопа.
 * @param {boolean} [props.required] - Флаг обязательности поля.
 * @param {Function} [props.onChange] - Функция изменения значения.
 * @param {Function} [props.withChange] - Функция изменения других полей.
 * @param {boolean} [props.disabled] - Флаг редактирования.
 * @param {*} [props.native] - Жопа.
 * @param {*} [props.withAll] - Жопа.
 * @param {Function} [props.filter] - Функция фильтрации.
 * @param {string} [props.name] - Имя.
 * @param {string} [props.placeholder] - Плейсхолдер.
 * @returns {React.ReactComponentElement} - Жопа.
 */
export const MultiSelect = ({
  dark = false,
  disabled = false,
  errorText = '',
  helperText = '',
  label = 'select label',
  name = 'select',
  onChange = (_) => _,
  optionKey = 'id',
  optionName = 'name',
  options = [],
  placeholder = '',
  required = false,
  value = [],
  ...props
}) => {

  /**
   * FN.
   *
   * @param {Array} value - Value.
   * @returns {void}
   */
  const handleChange = (value = []) => {
    onChange(handler(value));
  };

  /**
   * Функция проверки на массив.
   *
   * @returns {Array}
   */
  const checkArray = () => {
    if (Array.isArray(value)) {
      return value.filter(Boolean);
    }
    log.warn('value is not Array ', value);
    return [];
  };

  return (
    <Select
      MenuProps={
        {
          // anchorOrigin: {
          //   horizontal: 'left',
          //   vertical: 'bottom',
          // },
          // getContentAnchorEl: null,
        }
      }
      {...{
        dark,
        disabled,
        errorText,
        helperText,
        label,
        name,
        optionKey,
        optionName,
        options,
        placeholder,
        required,
        ...props,
      }}
      _multiple={true}
      value={checkArray()}
      onChange={handleChange}
    />
  );
};
