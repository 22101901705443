import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { useFormValuesCardRF } from 'core/form/reduxForm/hooks/useFormValuesCardRF';
import { MAF_TYPE_LEVEL_3_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel3';
import { useEffect, useState } from 'react';

interface MafTypeList {
  mafTypeLevel1List: number[];
  mafTypeLevel2List: number[];
}

interface DictItem {
  mafTypeList: MafTypeList;
}

/**
 * Setup MafTypeLevel3Field.
 *
 * @returns
 */
export const useSetupMafTypeLevel3Field = () => {
  const [haveOptions, setHaveOptions] = useState(false);
  const { maf_type_level1_id, maf_type_level2_id } = useFormValuesCardRF<{
    maf_type_level1_id: number;
    maf_type_level2_id: number;
  }>();
  const { editMode } = useMode();
  const { onChange } = useFieldRF<string>(MAF_TYPE_LEVEL_3_ID_NAME);

  useEffect(() => {
    if (editMode && (maf_type_level1_id || maf_type_level2_id)) {
      onChange('');
    }
    // eslint-disable-next-line
  }, [maf_type_level1_id, maf_type_level2_id]);

  /**
   * Функция фильтрации.
   *
   * @param dict - Справочник.
   * @returns Отфильтрованный справочник.
   */
  const filter = (dict: DictItem[] | undefined) => {
    const options = (dict || []).filter((item) => {
      return (
        (item?.mafTypeList?.mafTypeLevel1List || []).includes(
          maf_type_level1_id,
        ) ||
        (item?.mafTypeList?.mafTypeLevel2List || []).includes(
          maf_type_level2_id,
        )
      );
    });
    setHaveOptions(Boolean(options.length));
    return options;
  };

  const disabled = !(editMode && haveOptions);

  return {
    disabled,
    filter,
  };
};
