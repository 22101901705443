import { useMapContext } from 'app/components/map/useMapContext';
import {
  RANDOM_ID,
  RANDOM_NUMBER,
  RANDOM_WORD,
} from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { OghCard } from './index';
import { useCheckingAccessSaveWithoutGeometry } from './useCheckingAccessSaveWithoutGeometry';
import { useFetchActionWithGeometryDTW } from './useFetchActionWithGeometryDTW';
import { useNavigateWithLoading } from './useNavigateWithLoading';

vi.mock('./useCheckingAccessSaveWithoutGeometry');
vi.mock('./useFetchActionWithGeometryDTW');
vi.mock('./useNavigateWithLoading');
vi.mock('app/components/map/useMapContext');

/**
 * Дефолтные моки.
 *
 */
const defaultMock = () => {
  useCheckingAccessSaveWithoutGeometry.mockReturnValue({});
  useFetchActionWithGeometryDTW.mockReturnValue({});
  useNavigateWithLoading.mockReturnValue({});
  useMapContext.mockReturnValue({});
};

describe('🐛 IndexSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    defaultMock();
    // ☣️ Arrange (всякие моки)

    const props = {
      foo: RANDOM_WORD,
    };

    // 🔥 Act
    const wrapper = shallow(<OghCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<Card />');
  });

  it('🧪 useFetchActionWithGeometryDTW called params', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();

    const props = {
      card: {
        type_id: RANDOM_NUMBER,
      },
      foo: RANDOM_WORD,
    };

    // 🔥 Act
    shallow(<OghCard {...props} />);

    // ❓ Assert
    expect(useFetchActionWithGeometryDTW).toHaveBeenCalledWith({
      card: {
        type_id: RANDOM_NUMBER,
      },
      foo: RANDOM_WORD,
    });
  });

  it('🧪 useCheckingAccessSaveWithoutGeometry called params typeId parentInfo', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();

    const props = {
      card: {
        type_id: RANDOM_NUMBER,
      },
      foo: RANDOM_WORD,
      parentInfo: {
        typeId: RANDOM_ID,
      },
    };

    // 🔥 Act
    shallow(<OghCard {...props} />);

    // ❓ Assert
    expect(useCheckingAccessSaveWithoutGeometry).toHaveBeenCalledWith(
      RANDOM_NUMBER,
      RANDOM_ID,
    );
  });

  it('🧪 useCheckingAccessSaveWithoutGeometry called params type_id card parent_info', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();

    const props = {
      card: {
        parent_info: {
          type_id: RANDOM_ID,
        },
        type_id: RANDOM_NUMBER,
      },
      foo: RANDOM_WORD,
    };

    // 🔥 Act
    shallow(<OghCard {...props} />);

    // ❓ Assert
    expect(useCheckingAccessSaveWithoutGeometry).toHaveBeenCalledWith(
      RANDOM_NUMBER,
      RANDOM_ID,
    );
  });

  it('🧪 props', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    defaultMock();
    const allowedWithoutGeometry = vi.fn();
    useCheckingAccessSaveWithoutGeometry.mockReturnValue(
      allowedWithoutGeometry,
    );

    const navigateWithLoading = vi.fn();
    useNavigateWithLoading.mockReturnValue({ navigateWithLoading });

    const fetchActionWithGeometryDTW = vi.fn();
    useFetchActionWithGeometryDTW.mockReturnValue({
      fetchActionWithGeometryDTW,
    });

    const props = {
      foo: RANDOM_WORD,
    };

    // 🔥 Act
    const wrapper = shallow(<OghCard {...props} />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      allowedWithoutGeometry,
      fetchActionWithGeometryDTW,
      foo: RANDOM_WORD,
      mapContext: {},
      navigateWithLoading,
    });
  });
});
