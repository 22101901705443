import {
  BUILDINGS_TYPE_SPEC_NAME,
  BuildingsTypeSpecFF,
} from 'core/form/finalForm/fields';
import React from 'react';
import { StringParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * Компонент BuildingsTypeSpecFF.
 *
 * @param {object} props - Пропсы компонента.
 * @param {number} props.typeId - Идентификатор типа объекта для фильтрации.
 * @param {object} [props.otherProps] - Дополнительные пропсы, передаваемые в `BuildingsTypeSpecFF`.
 * @returns {JSX.Element}
 */
export const BuildingsTypeSpecFilter = ({ typeId, ...props }) => {

  /**
   * Фильтрация по типу объекта.
   *
   * @param {Array} buildingsTypeSpec - Список типов зданий.
   * @returns {Array} Отфильтрованный список типов зданий.
   */
  const filter = (buildingsTypeSpec) =>
    buildingsTypeSpec.filter((item) => item.ogh_object_type_id === typeId);
  return (
    <BuildingsTypeSpecFF {...props} className={filterInput} filter={filter} />
  );
};

export const buildingsTypeSpecParams = {
  [BUILDINGS_TYPE_SPEC_NAME]: StringParam,
};
