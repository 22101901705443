import { objectMatching } from 'app/actions/adminActions';
import { clearCard } from 'app/actions/clearCardStore';
import { hideDialog, showAlert, showNotification } from 'app/actions/dialog';
import {
  fetchCheckGeomContains,
  fetchCheckGeometryIntersections,
  fetchObjectsGeometry,
} from 'app/actions/geometryActions';
import {
  afterReceiving,
  cancelEditCard,
  cancelLeaveCurrentElement,
  cancelMode,
  createCard,
  fetchPreviewCard,
  rollbackTreeChanges,
  sendCreateCard,
  sendDeleteCard,
  sendUpdateCard,
  viewCard,
} from 'app/actions/odsObjectActions';
import { fetchOgh } from 'app/actions/odsObjectActions/fetchOgh';
import {
  fetchFewTree,
  fetchOghTree,
  fetchPreviewTreeSelectedElement,
  fetchTreeSelectedElement,
  treeDataRecalculate,
  treeSelectRoot,
} from 'app/actions/odsObjectTreeActions';
import { toggleCompareReportVisibility } from 'app/actions/odsObjectVersionsActions';
import withMap from 'app/components/map/withMap';
import getTreeJournal from 'app/selectors/card/getTreeJournal';
import hasGeometry from 'app/selectors/card/hasGeometry';
import getFormValues from 'app/selectors/form/getFormValues';
import { flow, get } from 'lodash';
import { connect } from 'react-redux';

import { CreateHOC } from './CreateHOC';

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Стейт.
 * @returns {object}
 */
function mapStateToProps(state = {}) {
  return {
    action: state?.matching?.afterAction,
    actionsTree: state.tree?.actionsTree || {},
    addChildInfo: state?.tree?.addChildInfo,
    card: state?.card?.object,
    currentFormValues: getFormValues(state),
    goToRegistry: state?.matching?.goToRegistry,
    hasGeometry: hasGeometry(state),
    matching: state?.matching?.data,
    matchingRegistryColumns: state?.tableColumns?.objectMatchingRegistry,
    mode: state?.card?.mode,
    parentTypeId: get(state, 'registry.data.meta.parentTypeId'),
    selected: state?.tree?.object,
    treeData: get(state, 'card.mode.editMode')
      ? state.tree?.dataForModify || []
      : state.tree?.data || [],
    treeJournal: getTreeJournal(state),
    treeOghModal: state?.modals?.treeEdit,
    treeParams: state?.card?.current,
    versionsModal: state?.modals?.versions,
  };
}

const mapDispatchToProps = {
  afterReceiving,
  cancelEditCard,
  cancelLeaveCurrentElement,
  cancelMode,
  clearCard,
  createCard,
  fetchCheckGeomContains,
  fetchCheckGeometryIntersections,
  fetchFewTree,
  fetchObjectsGeometry,
  fetchPreviewCard,
  fetchPreviewTreeSelectedElement,
  fetchRootCard: fetchOgh,
  fetchTree: fetchOghTree,
  fetchTreeSelectedElement,
  hideDialog,
  objectMatching,
  rollbackTreeChanges,
  sendCreateCard: sendCreateCard('ogh'),
  sendDeleteCard: sendDeleteCard('ogh'),
  sendUpdateCard: sendUpdateCard('ogh'),
  showAlert,
  showNotification,
  toggleCompareReportVisibility,
  treeDataRecalculate,
  treeSelectRoot,
  viewCard,
};

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  withMap,
)(CreateHOC);
