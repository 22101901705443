import { MafTypeLevel2RF } from 'core/form/reduxForm/fields/prepared/selects/simple/MafTypeLevel2.RF';
import React from 'react';

import { useSetupMafTypeLevel2IdField } from './useSetup.MafTypeLevel2Id.Field';

/**
 * Select RF  уточнение.
 *
 * @returns {React.ReactElement}
 */
export const MafTypeLevel2IdField = () => {
  const { disabled, filter } = useSetupMafTypeLevel2IdField();

  return (
    <MafTypeLevel2RF
      filter={filter}
      label={'Уточнение'}
      disabled={disabled}
      withAll={false}
    />
  );
};
