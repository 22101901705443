import { Customer, CUSTOMER_NAME } from 'core/uiKit/preparedInputs';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент CustomerFF.
 *
 * @param {object} props - Свойства компонента.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @param {object} [props.otherProps] - Дополнительные пропсы.
 *
 * @returns {React.Element}
 */
export const CustomerFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={CUSTOMER_NAME}>
      {(props) => {
        return (
          <Customer
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

/**
 * Function to define customer ID parameters.
 *
 * @returns {void}
 */
export const customerIdParams = () => {
  addPreparation(CUSTOMER_NAME, (value) => value.id);
};

export { CUSTOMER_NAME };
