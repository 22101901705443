import { showAlert } from 'app/actions/dialog';
import CardAbstract from 'app/components/card/common/CardAbstract';
import Matching from 'app/components/card/common/table/Matching';
import { objectTabs } from 'app/components/card/common/tabs';
import { LandscapingTab } from 'app/components/card/ogh/ImprovementTerritoryCard/components/Landscaping.Tab';
import { PassportizationTab } from 'app/components/card/ogh/ImprovementTerritoryCard/components/Passportization.Tab';
import { types } from 'app/constants/versionTypes';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import applyHocs from 'core/utils/applyHocs';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import printOghCardHeader from '../header/oghHeader';
import VersionsTemplate from '../versions/VersionsTemplate';

const Versions = VersionsTemplate(types);

const approvingTabStyles = {
  margin: '24px',
};

/**
 * Класс ImprovementTerritoryCard.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.propsTabElementRenders - Элемент таба.
 * @param {object} props.mapStateToPropsReducers - Стейт redux.
 * @param {object} props.mapDispatchToPropsReducers - Dispatch redux.
 * @returns {JSX.Element}
 */
function ImprovementTerritoryCardHOC({
  propsTabElementRenders,
  mapStateToPropsReducers,
  mapDispatchToPropsReducers,
}) {

  /**
   *
   */
  class ImprovementTerritoryCard extends React.Component {

    /**
     * Метод жизненного цикла rebder.
     *
     * @returns {JSX.Element}
     */
    render() {
      const { card, mode, selected, matching } = this.props;
      return (
        <CardContainer>
          <CardAbstract>
            <div id="editor-card">
              {printOghCardHeader(card, mode)}
              {objectTabs(card.type_id)}

              <div id="props">
                {propsTabElementRenders.map((render, index) => {
                  if (typeof render === 'function') {
                    return <div key={index}>{render(this.props)}</div>;
                  }
                  return <div key={index}>{render}</div>;
                })}
              </div>

              <div className="d-none" id="approving">
                <div style={approvingTabStyles}>
                  <Matching
                    card={card}
                    data={matching}
                    mode={mode}
                    recordId={card.record_id}
                    selected={selected}
                  />
                </div>
              </div>
            </div>
            <div className="d-none" id="versions">
              <Versions
                card={card}
                disabled={(mode && mode.createMode) || false}
                key={`versions-${card.root_id}`}
              />
            </div>
            <div className={'d-none'} id={'passportization'}>
              <PassportizationTab />
            </div>
            <LandscapingTab />
          </CardAbstract>
        </CardContainer>
      );
    }
  }

  /**
   * Функция reducedMapStateToProps.
   *
   * @param {object} state - Состояние redux.
   * @param {object} props - Пропсы.
   * @returns {void}
   */
  function reducedMapStateToProps(state, props) {
    return mapStateToPropsReducers.reduce(
      (result, reducer) => reducer(result, state, props),
      {},
    );
  }

  const reducedMapDispatchToProps = mapDispatchToPropsReducers.reduce(
    (result, reducer) => reducer(result),
    {
      showAlert,
    },
  );

  return applyHocs(ImprovementTerritoryCard, [
    connect(reducedMapStateToProps, reducedMapDispatchToProps),
    reduxForm({
      form: 'editorCard',
    }),
  ]);
}

export default ImprovementTerritoryCardHOC;
