import './checkbox.scss';

import { getFieldError } from 'app/utils/validate';
import {
  CheckboxMUI,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  ThemeProvider,
} from 'core/uiKit/material-ui';
import React from 'react';

import { useCreateCheckboxTheme } from './useCreateCheckboxTheme';

/**
 * Чекбокс.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.name - Имя поля.
 * @param {string} props.color - Цвет.
 * @param {string} props.label - Лейбел.
 * @param {boolean} props.dark - Переключатель темной темы.
 * @param {string} props.className - Класс.
 * @param {*} props.labelPlacement - Any.
 * @param {boolean} props.disabled - Флаг активности редактирования.
 * @param {string} props.errorText - Тест ошибки.
 * @param {string} props.helperText - Вспомогательный текст.
 * @param {boolean} props.required - Флаг обязательности поля.
 * @param {object} props.meta - Мета данные.
 * @param {boolean} props.value - Значения поля.
 * @param {boolean} props.checked - Выбран или нет чекбокс.
 * @param {Function} props.withChange - Функция изменения.
 * @param {boolean} props.justifyContent - Выравнивание Flex.
 * @returns {JSX.Element}
 */
export const Checkbox = ({
  label = '',
  color = 'primary',
  disabled = false,
  name = 'Checkbox',
  dark = false,
  className = '',
  labelPlacement = 'end',
  errorText,
  helperText,
  required = false,
  meta = {},
  value = false,
  checked = false,
  justifyContent = 'normal',
  // eslint-disable-next-line
  withChange = (_) => _,
  ...props
}) => {
  const theme = useCreateCheckboxTheme({ dark });
  const error = getFieldError(meta);
  const isError = !!error || !!errorText;
  const isChecked = !!(value || checked);

  return (
    <ThemeProvider theme={theme}>
      <FormControl {...{ className, disabled, error: isError, required }}>
        <FormControlLabel
          style={{ justifyContent }}
          control={<CheckboxMUI {...props} checked={isChecked} />}
          {...{ color, labelPlacement, name }}
          label={<FormLabel className={'checkbox-label'}>{label}</FormLabel>}
        />
        <FormHelperText>
          {error || errorText || helperText} &nbsp;
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
};
