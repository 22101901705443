import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { DISTANCE_NAME, DistanceField } from './Distance.Field';

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/utils/card/isMandatory');

describe('🐛 DistanceFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useGetCurrentCard.mockReturnValue({ typeId: RANDOM_NUMBER });
    isMandatory.mockReturnValue(true);
    // 🔥 Act

    const wrapper = shallow(<DistanceField />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      decimalScale: 1,
      disabled: false,
      label: 'Длина, п.м',
      name: DISTANCE_NAME,
      required: true,
    });
  });

  it('🧪 type field', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useGetCurrentCard.mockReturnValue({ typeId: RANDOM_NUMBER });
    isMandatory.mockReturnValue(true);
    // 🔥 Act

    const wrapper = shallow(<DistanceField />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<NumberFieldRF />');
  });

  it('🧪 isMandatory called', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useGetCurrentCard.mockReturnValue({ typeId: RANDOM_NUMBER });
    isMandatory.mockReturnValue(true);
    // 🔥 Act

    shallow(<DistanceField />);
    // ❓ Assert
    expect(isMandatory).toHaveBeenCalledWith(
      RANDOM_NUMBER,
      DISTANCE_NAME,
      true,
    );
  });
});
