import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import PropTypes from 'prop-types';
import React from 'react';

import { MafTypeLevel1IdField } from './fields/MafTypeLevel1Id.Field';
import { MafTypeLevel2IdField } from './fields/MafTypeLevel2Id.Field';
import { MafTypeLevel3Field } from './fields/MafTypeLevel3.Field';
import { MaQuantityCharacteristicsField } from './fields/MaQuantityCharacteristics.Field';

/**
 * MafType.
 *
 * @param {object} props - Property.
 * @returns {JSX}
 */
const MafType = (props) => {
  const { editable, isEditCurrentObjectIsExternalSystem, required } = props;

  return (
    <FieldsSplitColumns>
      <MafTypeLevel1IdField
        disabled={!(editable && isEditCurrentObjectIsExternalSystem)}
        required={required}
      />
      <MafTypeLevel2IdField />
      <MafTypeLevel3Field />
      <MaQuantityCharacteristicsField />
    </FieldsSplitColumns>
  );
};

MafType.propTypes = {
  changeFieldValue: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  mafTypeLevel1: PropTypes.array,
  mafTypeLevel1Id: PropTypes.number,
  mafTypeLevel2: PropTypes.array,
  mafTypeLevel3: PropTypes.array,
  onChangeMafTypeLevel2Id: PropTypes.func.isRequired,
  required: PropTypes.bool,
  typeId: PropTypes.number,
};

export default MafType;
