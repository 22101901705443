import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { RANDOM_WORD } from 'core/forTesting/constants';
import { useNavigate } from 'react-router-dom';
import { vi } from 'vitest';

import { useNavigateWithLoading } from './useNavigateWithLoading';

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('react-router-dom');
vi.useFakeTimers();

describe('🐛 UseIsLoadingSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useNavigate.mockReturnValue(vi.fn());
    useMode.mockReturnValue({});

    // 🔥 Act
    const res = useNavigateWithLoading();

    // ❓ Assert
    expect(res).toStrictEqual({
      navigateWithLoading: expect.any(Function),
    });
  });

  it('🧪 navigateWithLoading', async () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const startLoadingSpy = vi.fn();
    const navigateSpy = vi.fn();
    useNavigate.mockReturnValue(navigateSpy);
    useMode.mockReturnValue({ startLoading: startLoadingSpy });

    // 🔥 Act
    const { navigateWithLoading } = useNavigateWithLoading();
    navigateWithLoading(RANDOM_WORD);
    await vi.runOnlyPendingTimersAsync();

    // ❓ Assert
    expect(startLoadingSpy).toHaveBeenCalledTimes(1);
    expect(navigateSpy).toHaveBeenCalledWith(RANDOM_WORD);
  });
});
