import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { useGetCardModeSelector } from 'app/selectors/useSelectors';
import { Button } from 'core/uiKit/components/buttons/Button';

/**
 * Кнопка "Утвердить (ЭП)".
 *
 * @param {object} props - Пропсы.
 * @param {number} props.objectId - Id версии ОГХ.
 * @returns {JSX}
 */
export const ApproveElectronicSignatureButton = ({ objectId, ...props }) => {
  const { data: { approve_eds: approveEds } = {} } =
    useGetVisibilityButtonsOgh(objectId);
  // TODO: переделать на useEditMode
  // @todo переделать наuseEditMode
  const { createMode } = useGetCardModeSelector();

  const isView = !createMode && approveEds;
  return isView ? (
    <Button
      {...props}
      color={'success'}
      variant={'contained'}
      startIcon={<FontAwesomeIcon icon={faFileSignature} />}
    >
      Утвердить (ЭП)
    </Button>
  ) : null;
};
