import {
  WATER_TYPE_ID_NAME,
  WaterTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/WaterTypeId';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Компонент WaterTypeIdFF.
 *
 * @param {object} props - Свойства компонента.
 * @param {boolean} [props.disabled] - Флаг, который отключает поле ввода (если `true`).
 * @param {object} [props.otherProps] - Дополнительные пропсы, которые будут переданы компоненту `CoatingGroupId`.
 *
 * @returns {JSX.Element}
 */
export const WaterTypeIdFF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field name={WATER_TYPE_ID_NAME}>
      {(props) => {
        return (
          <WaterTypeId
            disabled={disabled}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

export { WATER_TYPE_ID_NAME };
