import { BUILDINGS_TYPE_SPEC_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/BuildingsTypeSpec';
import React from 'react';
import { Field } from 'redux-form';

import { BuildingsTypeSpecWithReduxForm } from './BuildingsTypeSpecWithReduxForm';

/**
 * Компонент BuildingsTypeSpecRF.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.disabled -Disabled.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - Возвращает Field.
 * @example <BuildingsTypeSpecRF/>
 */
export const BuildingsTypeSpecRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      name={BUILDINGS_TYPE_SPEC_NAME}
      component={BuildingsTypeSpecWithReduxForm}
    />
  );
};
