import { useNavigateWithLoading } from 'app/components/card/ogh/index/card/IndexCard/useNavigateWithLoading';
import { useMapContext } from 'app/components/map/useMapContext';

import IndexCard from './IndexCard';
import { useCheckingAccessSaveWithoutGeometry } from './useCheckingAccessSaveWithoutGeometry';
import { useFetchActionWithGeometryDTW } from './useFetchActionWithGeometryDTW';

/**
 * Индексный file карточки.
 * Тут используем все хуки.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.card - Пропсы.
 * @param {object} props.parentInfo - Информация по родителю.
 * @returns {JSX.Element} - JSX.
 */
export const OghCard = (props) => {
  const { card, parentInfo } = props;

  const allowedWithoutGeometry = useCheckingAccessSaveWithoutGeometry(
    card?.type_id,
    card?.parent_info?.type_id || parentInfo?.typeId,
  );
  const { navigateWithLoading } = useNavigateWithLoading();
  const { fetchActionWithGeometryDTW } = useFetchActionWithGeometryDTW(props);
  const mapContext = useMapContext();

  return (
    <IndexCard
      {...props}
      {...{
        allowedWithoutGeometry,
        fetchActionWithGeometryDTW,
        mapContext,
        navigateWithLoading,
      }}
    />
  );
};
