import { CUSTOMER_ODH_NAME, CustomerOdh } from 'core/uiKit/preparedInputs';
import React from 'react';
import { Field } from 'redux-form';

/**
 * Компонент CustomerOdhWithReduxForm.
 *
 * @param {object} params - Пропсы.
 * @param {object} params.input - Значения из формы РФ.
 * @returns {JSX.Element}
 */
export const CustomerOdhWithReduxForm = ({ input, ...props }) => {
  return <CustomerOdh {...input} {...props} />;
};

/**
 * Компонент CustomerOdhRF.
 *
 * @param {object} params - Пропсы.
 * @param {boolean} [params.disabled] - Disabled.
 * @param {object} params.otherProps - Пропсы.
 * @returns {JSX.Element}
 */
export const CustomerOdhRF = ({ disabled = false, ...otherProps }) => {
  return (
    <Field
      disabled={disabled}
      {...otherProps}
      name={CUSTOMER_ODH_NAME}
      component={CustomerOdhWithReduxForm}
    />
  );
};

export { CUSTOMER_ODH_NAME };
