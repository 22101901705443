import { useCheckingItIsChildOfImprovementObject } from 'app/pages/registry/ogh/hooks/useCheckingItIsChildOfImprovementObject';
import { AddressFilter } from 'core/form/filterForm/components/autocompleteAsync/AddressFilter';
import { CustomerFilter } from 'core/form/filterForm/components/autocompleteAsync/CustomerFilter';
import { OwnerIdFilter } from 'core/form/filterForm/components/autocompleteAsync/OwnerIdFilter';
import { DateFromFilter } from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import { DateToFilter } from 'core/form/filterForm/components/dateFilters/DateToFilter';
import { OghStatusesFilter } from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import { BuildingsTypeSpecFilter } from 'core/form/filterForm/components/selects/BuildingsTypeSpec.Filter/BuildingsTypeSpecFilter';
import { GrbsIdFilter } from 'core/form/filterForm/components/selects/GrbsId.Filter';
import { OkrugFilter } from 'core/form/filterForm/components/selects/OkrugFilter';
import { IdOrNameFilter } from 'core/form/filterForm/components/text/IdOrName.Filter';
import { IdOrNameParentFilter } from 'core/form/filterForm/components/text/IdOrNameParent.Filter';
import { FilterFF } from 'core/form/filterForm/FilterFF';

import { useFilterQueryParamsOtherTechPlace } from './useFilterQueryParams.OtherTechPlace';

/**
 * Filter FF реестров Иные некапитальные объекты.
 *
 * @param {object} params - Parameters.
 * @param {number} params.parentTypeId - Id типа родителя реестра.
 * @param {number} params.typeId - Id типа огх.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const FilterOtherTechPlace = ({ typeId, parentTypeId }) => {
  const { setFilters, filters } = useFilterQueryParamsOtherTechPlace();
  const { isChildOfImprovementObject } =
    useCheckingItIsChildOfImprovementObject(parentTypeId);

  /**
   * Фукция submit фильтра формы FF.
   *
   * @param {object} values - Values.
   * @returns {void}
   */
  const onSubmit = (values) => {
    setFilters(values, 'push');
  };

  /**
   * Функия сброса формы FF.
   *
   * @param {object} form - Параметры формы.
   * @param {Function} form.reset - Функция сброса формы FF.
   * @returns {void}
   */
  const reset = ({ reset }) => {
    reset();
    setFilters({}, 'replace');
  };

  return (
    <FilterFF {...{ initialValues: filters, onSubmit, reset }}>
      <IdOrNameFilter label={'ID'} />
      <IdOrNameParentFilter />
      <OghStatusesFilter />
      <DateFromFilter />
      <DateToFilter />
      <AddressFilter />
      <BuildingsTypeSpecFilter typeId={typeId} />
      <GrbsIdFilter hidden={isChildOfImprovementObject} />
      <OkrugFilter />
      <CustomerFilter />
      <OwnerIdFilter />
    </FilterFF>
  );
};
