import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';

import { postCheckGeometryContains } from '../../api/postCheckGeometryContains';
import { postCheckIntersection } from '../../api/postCheckIntersections';

/**
 * Функция проверки перед сохранением с проверкой пересечений.
 *
 * @returns Фцнкцию сохранения.
 */
export const useGetCheckFn = () => {
  const card = useGetCurrentCard();
  const { id } = useGetObjectId();

  return async ({
    geometry,
    values,
  }: {
    geometry?: Record<string, unknown>;
    values: Record<string, unknown>;
  }) => {
    // @ts-ignore
    await postCheckGeometryContains(geometry, card, values, id);
    // @ts-ignore
    await postCheckIntersection(geometry, card, values, id);
  };
};
