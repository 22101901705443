import { useGetOghType } from 'core/utils/hooks/useGetOghType';

/**
 * Проверка доступа сохранения без геометрии. Для родителей и их дочек.
 *
 * @param {number} currentTypeId - Id типа текущей карточки.
 * @param {number} parentTypeId - Id типа родителя.
 * @returns {boolean}
 */
export const useCheckingAccessSaveWithoutGeometry = (
  currentTypeId,
  parentTypeId,
) => {
  const withoutGeometryArr = [useGetOghType('spa')].map(({ id }) => id);

  if (withoutGeometryArr.includes(currentTypeId)) {
    return true;
  }

  return withoutGeometryArr.includes(parentTypeId);
};
